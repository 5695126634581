import { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Route, Routes } from 'react-router-dom';
import { SystemSettings } from '../../pages/SystemSettings/SystemSettings';
import {
    authInitializedAtom,
    refreshTokenTimeoutAtom,
    initAuth,
    refreshAccessToken,
    ssoCallbackDataAtom,
    setSSOCallbackData,
    ssoSignIn,
    currentUserAtom,
} from '../../stores/auth';
import { getEnvironmentVariables, useIsHostedProd } from '../../stores/settings';
import { AuthGuard } from '../AuthGuard/AuthGuard';
import { AppLayout } from '../AppLayout/AppLayout';
import { FullScreenLoading } from '../FullScreenLoading/FullScreenLoading';
import { useQuiToasts } from '@tonicai/ui-quinine';
import { useDPRMonitor } from '../../hooks/useDPRMonitor';

// Pages
import { DatasetGuard } from '../../pages/Dataset/Dataset';
import { Datasets } from '../../pages/Datasets/Datasets';
import { Help } from '../../pages/Help/Help';
import { NewDataset } from '../../pages/NewDataset/NewDataset';
import { NotFound } from '../../pages/NotFound/NotFound';
import { SignIn } from '../../pages/SignIn/SignIn';
import { SignUp } from '../../pages/SignUp/SignUp';
import { SSOCallback } from '../../pages/SSOCallback/SSOCallback';
import { UserAPIKeys } from '../../pages/UserAPIKeys/UserAPIKeys';
import { Models } from '../../pages/Models/Models';
import { NewModel } from '../../pages/NewModel/NewModel';
import { EditModel } from '../../pages/EditModel/EditModel';
import { loadIntercomOnHosted } from '../../hosted_tracking.js';
import { ResetPassword } from '../../pages/ResetPassword/ResetPassword';
import { hubspot } from '../../instrumentation/hubspot';
import { Home } from '../../pages/Home/Home';
import { Playground } from '../../pages/Playground/Playground';

export function App() {
    const authInitialized = useAtomValue(authInitializedAtom);
    const refreshTokenTimeout = useAtomValue(refreshTokenTimeoutAtom);
    const ssoCallbackData = useAtomValue(ssoCallbackDataAtom);
    const user = useAtomValue(currentUserAtom);
    const addToast = useQuiToasts();
    const [initialized, setInitialized] = useState(false);
    const isHostedProd = useIsHostedProd();
    const initializedIntercomRef = useRef(false);

    // Init hubspot as soon as the app is mounted.
    useEffect(() => {
        hubspot.init(window.location.pathname);
    }, []);

    // Monitor when the user changes their browser zoom level
    // or moves this window to a different monitor with a different DPR.
    // We should not use this hook anymore than a total of _once_ per app.
    useDPRMonitor();

    useEffect(() => {
        if (initialized) return;

        Promise.all([initAuth(), loadIntercomOnHosted(), getEnvironmentVariables()])
            .catch(console.error)
            .finally(() => {
                setInitialized(true);
            });
    }, [initialized]);

    useEffect(() => {
        if (ssoCallbackData === null) return;

        if (ssoCallbackData.type === 'error') {
            addToast({
                title: `Error signing in: ${
                    ssoCallbackData.errorDescription?.length || 0 > 128
                        ? ssoCallbackData.errorDescription?.substring(0, 125) + '...'
                        : ssoCallbackData.errorDescription
                }`,
                variant: 'destructive',
            });
            console.error(`Error signing in: ${ssoCallbackData.errorDescription}`);
            return;
        }

        ssoSignIn(ssoCallbackData.token, ssoCallbackData.provider, ssoCallbackData.searchParams).finally(() => {
            setSSOCallbackData(null);
        });
    }, [ssoCallbackData, addToast]);

    useEffect(() => {
        if (refreshTokenTimeout === null) {
            return;
        }

        const timeout = window.setTimeout(() => {
            refreshAccessToken();
        }, refreshTokenTimeout);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [refreshTokenTimeout]);

    useEffect(() => {
        if (isHostedProd && 'Intercom' in window && typeof window['Intercom'] === 'function' && initializedIntercomRef.current === false) {
            initializedIntercomRef.current = true;
            window?.Intercom('boot', {
                app_id: 'l9jilb9x',
                email: user?.userName,
            });
        }
    }, [isHostedProd, user]);

    // Show a loading screen until we've checked if the user is authenticated &
    // completed fetching any initial data necessary for rendering the app (e.g. env
    // vars or any other data that's stored on the server).
    if (!authInitialized || !initialized) {
        return <FullScreenLoading />;
    }

    return (
        <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/passwordreset" element={<ResetPassword />} />
            <Route path="/sso/callback/:ssoProvider" element={<SSOCallback />} />
            <Route element={<AuthGuard />}>
                <Route element={<AppLayout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/models" element={<Models />} />
                    <Route path="/models/new" element={<NewModel />} />
                    <Route path="/models/:modelId" element={<EditModel />} />
                    <Route path="/datasets" element={<Datasets />} />
                    <Route path="/datasets/new" element={<NewDataset />} />
                    <Route path="/datasets/:datasetId" element={<DatasetGuard />} />
                    <Route path="/user-api-keys" element={<UserAPIKeys />} />
                    <Route path="/system-settings" element={<SystemSettings />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/playground" element={<Playground />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Route>
        </Routes>
    );
}

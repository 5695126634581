import { EditModelForm } from './EditModelForm';
import { useModelRouteGuard } from './useModelRouteGuard';
import { QuiSpinner } from '@tonicai/ui-quinine';
import { NotFound } from '../NotFound/NotFound';

export function EditModel() {
    const modelStatus = useModelRouteGuard();

    if (modelStatus === 'loading') {
        return <QuiSpinner />;
    }

    if (modelStatus === 'not-found') {
        return <NotFound />;
    }

    return <EditModelForm model={modelStatus.model} modelId={modelStatus.modelId} />;
}

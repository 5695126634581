import { QuiBadge, QuiBox, QuiButton, QuiText, QuiTextField, useQuiToasts } from '@tonicai/ui-quinine';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { FileAdder } from '../../components/FileAdder/FileAdder';
import { DatasetTemplatesButton } from '../../components/Templates/DatasetTemplatesButton';
import { currentUserAtom } from '../../stores/auth';
import { useIsAzureDocIntelligenceEnabled } from '../../stores/settings';
import { Dataset } from '../../types';
import { DeleteDatasetButton } from './DeleteDatasetButton';
import { EnabledModelsForm } from './EnabledModelsForm';

type DatasetInfoProps = Readonly<{
    dataset: Dataset;
}>;

export function DatasetInfo({ dataset }: DatasetInfoProps) {
    const user = useAtomValue(currentUserAtom);
    const addToast = useQuiToasts();
    const form = useForm();
    const azureDocIntelligenceEnabled = useIsAzureDocIntelligenceEnabled();

    const hasAtLeastOnePDF = useMemo(() => {
        return dataset.files.find((f) => f.fileType === 'Pdf') !== undefined;
    }, [dataset.files]);

    const [showEditName, setShowEditName] = useState(false);

    return (
        <QuiBox overflow="hidden" borderRadius="md" border="white-600">
            <QuiBox bg="white" borderBottom="white-600" padding="md" gap="md" flexDirection="column" display="flex">
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiBadge iconLeft="message-square" size="xl" variant="purple" />
                    <QuiText size="text-xl" weight="bold">
                        {dataset.name}
                    </QuiText>
                </QuiBox>
                <QuiBox display="flex" flexDirection="column" gap="sm">
                    <div>
                        <QuiBox text="text-xs" color="black-600">
                            {`Edited ${dayjs(dataset.lastUpdated).fromNow()} by ${user?.displayName ?? user?.userName}`}
                        </QuiBox>
                    </div>
                </QuiBox>
                {azureDocIntelligenceEnabled && hasAtLeastOnePDF && (
                    <QuiBox>
                        <DatasetTemplatesButton datasetId={dataset.id} />
                    </QuiBox>
                )}
                <QuiBox display="flex" flexDirection={'column'} gap="sm">
                    <QuiBox display="flex" gap="sm">
                        <QuiButton
                            onClick={() => {
                                setShowEditName(!showEditName);
                            }}
                            size="sm"
                        >
                            {!showEditName ? 'Edit Name' : 'Cancel Editing Name'}
                        </QuiButton>
                        <EnabledModelsForm name={'enabledModels'} datasetId={dataset.id} />
                        <DeleteDatasetButton dataset={dataset} datasetId={dataset.id} />
                    </QuiBox>
                </QuiBox>
            </QuiBox>

            {showEditName && (
                <QuiBox display="flex" flexDirection="column" gap="md" padding="md" bg="white" borderBottom="white-600">
                    <QuiTextField name="name" label="Dataset Name *" helperText="Use up to 128 characters. This field is required." />

                    <QuiButton
                        variant="brand-purple"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowEditName(false);
                            form.submit()?.catch((e) => {
                                if (axios.isAxiosError(e) && e.response?.status === 409) {
                                    addToast({ title: e.message, variant: 'destructive' });
                                }
                            });
                        }}
                        type="button"
                    >
                        Save
                    </QuiButton>
                </QuiBox>
            )}
            <QuiBox padding="md">
                <FileAdder mode="combobox" name="fileIds" datasetId={dataset.id} />
            </QuiBox>
        </QuiBox>
    );
}

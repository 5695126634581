import { QuiIconToken } from '@tonicai/ui-quinine';
import { PiiTypeGeneratorState, PiiTypeEnum } from '.';

export type FileType = 'Csv' | 'Raw' | 'Pdf' | 'DocX';

export type FileSource = 'Local' | 'Sharepoint';

export type PiiTextExample = {
    endIndex: number;
    startIndex: number;
    text: string;
};

export type LabelBlockList = {
    strings: string[];
    regexes: string[];
};

export enum ModelTransformation {
    TimestampShift = 'TimestampShift',
}

export enum StringDateFormat {
    YearMonthDayNoSeparator = 'yyyyMMdd',
    YearMonthDayHyphen = 'yyyy-MM-dd',
    DayMonthYearSlash = 'dd/MM/yyyy',
    DayMonthYearHyphen = 'dd-MM-yyyy',
    MonthDayYearSlash = 'MM/dd/yyyy',
    MonthDayShortYearSlash = 'MM/dd/yy',
    ShortMonthDayYearSlash = 'M/dd/yyyy',
    MonthDayYearHyphen = 'MM-dd-yyyy',
    MonthDayYearNoSeparator = 'MMddyyyy',
    DayThreeCharMonthYearSlash = 'dd/MMM/yyyy',
    DayThreeCharMonthYearHyphen = 'dd-MMM-yyyy',
    DayFullMonthyearSpace = 'dd MMMM yyyy',
    MonthYearHyphen = 'MM-yyyy',
}

export type HipaaAddressMetadata = {
    realisticSyntheticValues: boolean;
    replaceTruncatedZerosInZipCode: boolean;
};

export type TimestampShiftMetadata = {
    timestampShiftInDays?: number;
};

export type NameMetadata = {
    isConsistencyCaseSensitive: boolean;
};

export type DateTimeMetadata = {
    dateTimeTransformation?: ModelTransformation.TimestampShift;
    additionalDateFormats?: string[];
    scrambleUnrecognizedDates?: boolean;
    metadata?: TimestampShiftMetadata; //only option for now, could make it a union type down the line
};

export type DatasetGeneratorMetadata = {
    [PiiTypeEnum.DATE_TIME]: DateTimeMetadata;
    [PiiTypeEnum.LOCATION]: HipaaAddressMetadata;
    [PiiTypeEnum.PERSON]: NameMetadata;
};

export type DatasetFile = {
    fileId: string;
    fileName: string;
    datasetId: string;
    numColumns: number;
    piiTypes: string[];
    piiTypeCounts: Record<string, number>;
    piiTypeExamples: Record<string, PiiTextExample[]>;
    numRows?: number;
    uploadedTimestamp: string;
    wordCount?: number;
    redactedWordCount: number;
    processingStatus: string;
    processingError?: string;
    fileType: FileType;
    fileSource: FileSource;
    mostRecentCompletedJobId?: string;
};

export type Dataset = {
    name: string;
    id: string;
    files: DatasetFile[];
    generatorSetup: Record<string, PiiTypeGeneratorState>;
    datasetGeneratorMetadata: Partial<DatasetGeneratorMetadata>;
    labelBlockLists: Record<string, LabelBlockList>;
    enabledModels: string[];
    lastUpdated: Date;
    fileSource: FileSource;
};

export interface IMetadataProps {
    name: string;
    piiType: string;
}

export type DatasetFormState = {
    id: string;
    name: string;
    generatorSetup: Record<string, PiiTypeGeneratorState>;
    labelBlockLists: Record<string, LabelBlockList>;
    enabledModels: string[];
    datasetGeneratorMetadata: Partial<DatasetGeneratorMetadata>;
};

export type GeneratorSetupFormState = {
    generatorSetup: Record<PiiTypeEnum, PiiTypeGeneratorState>;
};

export type DeidentifyResponse = {
    start: number;
    end: number;
    label: string;
    text: string;
    score: number;
    ordinalPosition: number;
    exampleRedaction?: string;
};

export type DeidentifyPreview = {
    ordinalPosition: number;
    rowNum: number;
    deIdentifyResult: DeidentifyResponse[];
};

export type RedactedTextChunk = {
    id: string;
    text: string;
    label?: string;
    score?: number;
    exampleRedaction?: string;
};

export type PreviewColumnData = {
    id: string;
    ordinalPosition: number;
    chunks: RedactedTextChunk[];
};

export type PiiTypeInfo = {
    label: string;
    icon: QuiIconToken;
    description: string;
};

export const acceptedUploadFileTypes = [
    'text/csv',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.tsv',
    '.pdf',
].join(',');

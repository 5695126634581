import { useMemo } from 'react';
import { useDatasets } from '../stores/datasets';
import { nanoid } from 'nanoid';

export function useNewDatasetName() {
    const datasets = useDatasets();

    return useMemo(() => {
        const d = 'first_dataset';

        if (datasets.find((dataset) => dataset.name === d) === undefined) {
            return d;
        }

        for (let i = 0; i < 10; i++) {
            if (datasets.find((d) => d.name === `dataset_${i}`) === undefined) {
                return `dataset_${i}`;
            }
        }

        return `dataset_${nanoid()}`;
    }, [datasets]);
}

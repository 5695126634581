import { useDialog } from '../../hooks/useDialog';
import { useDataset } from '../../stores/datasets';
import { useForm } from 'react-final-form';
import { fetchModels, modelsAtom, modelsFetchStatusAtom, modelsLoadedAtom } from '../../stores/models';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import {
    isIconToken,
    QuiBadge,
    QuiButton,
    QuiSpinner,
    QuiText,
    QuiSwitch,
    QuiModalDialog,
    QuiModalContent,
    QuiModalFooter,
    QuiBox,
} from '@tonicai/ui-quinine';
import { QuiButtonVariantEnum } from '@tonicai/ui-quinine/dist/components/QuiButton/QuiButton';
import { ModelTrainingStatusBadge } from '../Models/ModelTrainingStatusBadge';

type EnabledModelsFormProps = Readonly<{
    datasetId: string;
    name: string;
}>;

export function EnabledModelsForm({ datasetId, name }: EnabledModelsFormProps) {
    const dataset = useDataset(datasetId);
    const form = useForm();
    const models = useAtomValue(modelsAtom);
    const modelsLoaded = useAtomValue(modelsLoadedAtom);
    const modelsFetchStatus = useAtomValue(modelsFetchStatusAtom);

    const { isOpen, closeDialog, toggleDialog } = useDialog();

    useEffect(() => {
        fetchModels();
    }, []);

    if (!dataset) return null;

    const handleChange = (modelId: string, isChecked: boolean) => {
        const enabledModelsWithoutModelId = (form.getState().values.enabledModels || []).filter((id: string) => id !== modelId);

        if (!isChecked) {
            form.change(name, enabledModelsWithoutModelId);
        } else {
            form.change(name, [...enabledModelsWithoutModelId, modelId]);
        }
    };

    return (
        <>
            <QuiButton size={'sm'} onClick={toggleDialog}>
                Custom Models
            </QuiButton>
            <QuiModalDialog isOpen={isOpen} onClose={closeDialog} title={`Select Custom Models To Enable For ${dataset.name}`}>
                <QuiModalContent>
                    <QuiBox display="flex" flexDirection="column" gap="xs">
                        {!modelsLoaded && <QuiSpinner />}
                        {modelsLoaded && modelsFetchStatus === 'error' && <div>Something went wrong!</div>}
                        {modelsLoaded &&
                            modelsFetchStatus === 'success' &&
                            models.map((m) => (
                                <QuiBox
                                    bg="white"
                                    borderRadius="md"
                                    border="white-600"
                                    padding={'sm'}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={'xs'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    key={m.id}
                                >
                                    <QuiBox display={'flex'} gap={'md'} alignItems={'center'}>
                                        <QuiBadge iconLeft={isIconToken(m.icon) ? m.icon : 'send'} size={'xs'} />
                                        <QuiText>{m.name}</QuiText>
                                        <ModelTrainingStatusBadge modelTrainingStatus={m.modelTrainingStatus} />
                                    </QuiBox>
                                    <QuiSwitch
                                        checked={form.getState().values.enabledModels && form.getState().values.enabledModels.includes(m.id)}
                                        onChange={(isChecked: boolean) => handleChange(m.id, isChecked)}
                                        disabled={m.modelTrainingStatus === 'NotYetQueued'}
                                    />
                                </QuiBox>
                            ))}
                    </QuiBox>
                </QuiModalContent>
                <QuiModalFooter>
                    <QuiBox display={'flex'} justifyContent={'end'} className={'qui-w-100'} gap={'sm'}>
                        <QuiButton type="button" variant={QuiButtonVariantEnum.Default} onClick={closeDialog}>
                            Cancel
                        </QuiButton>
                        <QuiButton
                            type="button"
                            variant="brand-purple"
                            onClick={() => {
                                form.submit()?.then(() => {
                                    closeDialog();
                                });
                            }}
                        >
                            Save
                        </QuiButton>
                    </QuiBox>
                </QuiModalFooter>
            </QuiModalDialog>
        </>
    );
}

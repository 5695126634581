import { ModelTrainingStatus } from '../../types';
import { QuiBadge, QuiBadgeVariant, QuiIconToken, QuiSpinner } from '@tonicai/ui-quinine';

type ModelTrainingStatusBadgeProps = {
    modelTrainingStatus?: ModelTrainingStatus;
};

export function ModelTrainingStatusBadge({ modelTrainingStatus }: ModelTrainingStatusBadgeProps) {
    if (!modelTrainingStatus) return null;

    let variant: QuiBadgeVariant = 'default';
    let icon: QuiIconToken | undefined = 'clock';
    let text = 'Queued';
    let hasSpinner = false;

    switch (modelTrainingStatus) {
        case 'Queued':
            variant = 'default';
            icon = 'clock';
            text = 'Queued';
            break;
        case 'InProgress':
            variant = 'brand-royal';
            icon = undefined;
            text = 'Training';
            hasSpinner = true;
            break;
        case 'Complete':
            variant = 'green';
            icon = 'check';
            text = 'Trained';
            break;
        case 'Error':
            variant = 'red';
            icon = 'alert-triangle';
            text = 'Error';
            break;
        case 'NotYetQueued':
            variant = 'default';
            icon = 'clock';
            text = 'Not Yet Queued';
    }

    return (
        <QuiBadge size="xs" iconLeft={icon} variant={variant}>
            {hasSpinner && <QuiSpinner size="xs" />}
            {text}
        </QuiBadge>
    );
}

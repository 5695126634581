import isPlainObject from 'is-plain-obj';
import { v4 as uuid } from 'uuid';
import { LoginAndRefreshTokenResponseModel, SsoProvider, UserEntityModel, UserPhotoModel } from '../types';
import { appURL, hasBooleanValueInObject, hasNumberValueInObject, hasOptionalStringValueInObject, hasStringValueInObject } from './index';

export function getSSOURL(
    provider: SsoProvider,
    clientId: string,
    canUseAsExternalFileSource: boolean,
    domain?: string,
    identityProviderId?: string,
    authorizationServerId?: string
) {
    // GitHub
    if (provider === SsoProvider.github) {
        const githubURL = new URL('https://github.com/login/oauth/authorize');
        githubURL.searchParams.set('redirect_uri', appURL('/sso/callback/github'));
        githubURL.searchParams.set('scope', 'user:email,read:user');
        githubURL.searchParams.set('client_id', clientId);
        return githubURL.toString();
    }

    // Google
    if (provider === SsoProvider.google) {
        const googleURL = new URL('https://accounts.google.com/o/oauth2/auth');
        googleURL.searchParams.set('response_type', 'code');
        googleURL.searchParams.set('redirect_uri', appURL('/sso/callback/google'));
        googleURL.searchParams.set('scope', 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');
        googleURL.searchParams.set('client_id', clientId);
        return googleURL.toString();
    }

    // Okta
    if (provider === SsoProvider.okta) {
        if (authorizationServerId) {
            authorizationServerId = authorizationServerId + '/';
        } else {
            authorizationServerId = '';
        }

        const oktaURL = new URL(`https://${domain}/oauth2/${authorizationServerId}v1/authorize`);
        oktaURL.searchParams.set('response_type', 'id_token');
        oktaURL.searchParams.set('response_mode', 'fragment');
        oktaURL.searchParams.set('redirect_uri', appURL('/sso/callback/okta'));
        oktaURL.searchParams.set('nonce', uuid());
        oktaURL.searchParams.set('state', uuid());
        oktaURL.searchParams.set('scope', 'openid email profile');
        oktaURL.searchParams.set('client_id', clientId);
        if (identityProviderId) {
            oktaURL.searchParams.set('idp', identityProviderId);
        }
        return oktaURL.toString();
    }

    // Azure AD
    if (provider === SsoProvider.azure) {
        const azureURL = new URL(`https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize`);
        azureURL.searchParams.set('client_id', clientId);
        azureURL.searchParams.set('response_type', 'code');
        azureURL.searchParams.set('response_mode', 'query');
        azureURL.searchParams.set('redirect_uri', appURL('/sso/callback/azure'));
        azureURL.searchParams.set(
            'scope',
            canUseAsExternalFileSource ? 'User.Read Sites.ReadWrite.All Files.ReadWrite.All Files.Read.All' : 'User.Read'
        );
        azureURL.searchParams.set('state', uuid());
        azureURL.searchParams.set('nonce', uuid());
        return azureURL.toString();
    }

    // Open ID Connect
    if (provider === SsoProvider.oidc) {
        const oidcUrl = new URL(appURL('/api/sso/redirect'));
        oidcUrl.searchParams.set('provider', 'oidc');
        oidcUrl.searchParams.set('callbackUrl', appURL('/sso/callback/oidc'));
        return oidcUrl.toString();
    }

    // everything else is not supported
    return null;
}

export function isSSOProvider(p: unknown): p is SsoProvider {
    return (
        typeof p === 'string' &&
        Object.entries(SsoProvider)
            .map(([v]) => v)
            .includes(p)
    );
}

export function isUserPhotoModel(v: unknown): v is UserPhotoModel {
    return (
        isPlainObject(v) &&
        hasStringValueInObject('name', v) &&
        hasOptionalStringValueInObject('url', v) &&
        hasOptionalStringValueInObject('fileType', v) &&
        hasBooleanValueInObject('isManualUpload', v)
    );
}

export function isUserEntityModel(v: unknown): v is UserEntityModel {
    return (
        isPlainObject(v) &&
        hasOptionalStringValueInObject('id', v) &&
        hasOptionalStringValueInObject('userName', v) &&
        hasOptionalStringValueInObject('firstName', v) &&
        hasOptionalStringValueInObject('lastName', v) &&
        hasOptionalStringValueInObject('lastActivityDate', v) &&
        ('photoUpload' in v ? isUserPhotoModel(v.photoUpload) : true)
    );
}

export function isLoginAndRefreshTokenResponseModel(v: unknown): v is LoginAndRefreshTokenResponseModel {
    return (
        isPlainObject(v) &&
        ('user' in v ? isUserEntityModel(v.user) : true) &&
        hasNumberValueInObject('expiresIn', v) &&
        hasStringValueInObject('refreshToken', v) &&
        hasNumberValueInObject('refreshTokenRefreshIntervalInSeconds', v) &&
        hasNumberValueInObject('inactivityTimeoutInSeconds', v) &&
        hasOptionalStringValueInObject('environmentName', v) &&
        hasOptionalStringValueInObject('jwt', v) &&
        hasOptionalStringValueInObject('amplitudeApiKey', v) &&
        hasOptionalStringValueInObject('amplitudeApiUrl', v) &&
        hasStringValueInObject('createdDate', v)
    );
}

import { QuiButton, QuiText, QuiTextField, QuiTextAreaField, QuiBox } from '@tonicai/ui-quinine';
import { EntityExamplesField } from './EntityExamplesField';
import { Field } from 'react-final-form';
import { requiredString } from '../../validation';
import { forwardRef } from 'react';

type EntityFieldsProps = {
    fieldName: string;
    removeField: () => void;
    numFields: number;
    generateExamples: () => void;
    generatingExamples: boolean;
    generatedExamples: string[];
};

export const EntityFields = forwardRef<HTMLInputElement, EntityFieldsProps>(
    ({ fieldName, removeField, numFields, generateExamples, generatingExamples, generatedExamples }, ref) => {
        return (
            <QuiBox border="white-600" borderRadius="md" padding="md" bg="white" display="flex" flexDirection="column" gap="md" key={fieldName}>
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <QuiText size="text-md" weight="medium">
                        Entity Definition
                    </QuiText>
                    <QuiButton disabled={numFields < 1} type="button" iconLeft="trash" variant="destructive" onClick={removeField} size="sm">
                        Delete Entity
                    </QuiButton>
                </QuiBox>

                <Field type="hidden" name={`${fieldName}.id`} component="input" />

                <QuiTextField validate={requiredString} ref={ref} label="Label" name={`${fieldName}.label`} />
                <QuiTextField label="Description" name={`${fieldName}.description`} />
                <EntityExamplesField label="Examples" name={`${fieldName}.examples`} />
                <QuiTextAreaField
                    label="Extra Instructions"
                    helperText="Add additional instructions to refine results."
                    name={`${fieldName}.extraInstruction`}
                />

                <QuiBox
                    padding="lg"
                    border="white-600"
                    borderRadius="md"
                    bg="white-200"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap="md"
                >
                    <QuiButton
                        size="sm"
                        iconLeft="search"
                        spinner={generatingExamples}
                        disabled={generatingExamples}
                        type="button"
                        onClick={generateExamples}
                    >
                        Preview Generated Examples
                    </QuiButton>
                    <QuiBox color="black-600" text="text-xs">
                        {(Array.isArray(generatedExamples) &&
                            generatedExamples.length &&
                            generatedExamples.map((e, i) => (
                                <span key={e}>
                                    {e}
                                    {i < generatedExamples.length - 1 ? ', ' : ''}
                                </span>
                            ))) || (
                            <div>
                                See what kind of examples the LLM will generate based on the examples you've provided. This will let you know if you
                                should adjust your examples or provide extra instructions.
                            </div>
                        )}
                    </QuiBox>
                </QuiBox>
            </QuiBox>
        );
    }
);

EntityFields.displayName = 'EntityFields';

import { matchesOtherField, QuiBox, QuiButton, QuiFlexV, QuiForm, QuiLink, QuiPasswordField, QuiTextField } from '@tonicai/ui-quinine';
import { SignUpFormValues } from '../../types';
import { signUp } from '../../stores/auth';
import { SubmitError } from './SubmitError';
import { requiredEmail, requiredPassword } from '../../validation';
import { useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { environmentVariablesAtom } from '../../stores/settings';
import { SolarEnvironmentVariable } from '../../utils/environment';

export function SignUpForm() {
    const navigate = useNavigate();
    const values = useAtomValue(environmentVariablesAtom);
    const isHostedProduction = values?.[SolarEnvironmentVariable.IsHostedProd] === true;

    return (
        <QuiForm<SignUpFormValues>
            onSubmit={(values: SignUpFormValues) => {
                return signUp(values).then((response) => {
                    if (response === undefined) {
                        navigate('/signin?accountCreated=true');
                    } else {
                        return response;
                    }
                });
            }}
        >
            <QuiFlexV gap="md">
                <QuiTextField label="Email Address *" validate={requiredEmail} name="email" />
                <QuiPasswordField validate={requiredPassword} hideShowPasswordToggle label="Password *" name="password" />
                <QuiPasswordField
                    name="confirmPassword"
                    label="Confirm Password *"
                    validate={matchesOtherField('password', 'Passwords must match.')}
                    hideShowPasswordToggle
                />
                <SubmitError />
                <QuiBox text="text-xs">
                    Signing up signifies that you have read and agree to our{' '}
                    <QuiLink
                        variant="currentcolor"
                        external
                        to={isHostedProduction ? 'http://tonic.ai/terms/free-trial' : 'http://tonic.ai/terms/03162023'}
                    >
                        Terms of Use
                    </QuiLink>{' '}
                    and <QuiLink variant="currentcolor" external to="https://www.tonic.ai/privacy-policy">{`Privacy Policy`}</QuiLink>, unless there
                    is a signed agreement between you and Tonic, which you agree will govern this use.
                </QuiBox>
                <QuiButton variant="brand-purple">Sign Up</QuiButton>
            </QuiFlexV>
        </QuiForm>
    );
}

import { useAtomValue } from 'jotai';
import { Auth } from '../../components/Auth/Auth';
import { SSOButtons } from '../../components/Auth/SSOButtons';
import { SignUpForm } from '../../components/Auth/SignUpForm';
import { QuiText } from '@tonicai/ui-quinine';
import { Link, Navigate } from 'react-router-dom';
import { isSsoRequiredAtom, signedInAtom } from '../../stores/auth';

export function SignUp() {
    const signedIn = useAtomValue(signedInAtom);
    const isSsoRequired = useAtomValue(isSsoRequiredAtom);

    if (signedIn) {
        return <Navigate to="/" />;
    }

    return (
        <Auth>
            <QuiText size="display-xs" weight="medium">
                Sign Up
            </QuiText>
            <SSOButtons prefix="Sign Up With" />
            {!isSsoRequired && <SignUpForm />}
            <QuiText className="qui-text-align-center" as="p">
                Already have an account? <Link to="/signin">Sign In</Link>
            </QuiText>
        </Auth>
    );
}

import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIsMounted } from '../../hooks/useIsMounted';
import { fetchModel, useModel } from '../../stores/models';
import { ModelFormState } from '../../types';

// This component ensures that a model with the route's modelId exists before
// rendering Component prop as an element, so that you _know_ the model exists
// before rendering any form associated with it. It also shows a loading state
// and a not-found state.
export function useModelRouteGuard() {
    const [fetchedModel, setFetchedModel] = useState(false);
    const isMounted = useIsMounted();
    const { modelId = null } = useParams();

    const model = useModel(modelId);

    const modelStatus = useMemo(() => {
        if (!fetchedModel) return 'loading';
        if (!modelId || !model) return 'not-found';

        const modelWithAtLeastOneEntity: ModelFormState = { ...model };
        if (!Array.isArray(model?.entities) || model.entities.length === 0) {
            modelWithAtLeastOneEntity.entities = [
                {
                    label: '',
                    description: '',
                    examples: [''],
                    extraInstruction: '',
                },
            ];
        }

        const modelWithAtLeastOneTemplateAndAtLeastOneEntity: ModelFormState = { ...modelWithAtLeastOneEntity };
        if (!Array.isArray(model?.templateExamples) || model.templateExamples.length === 0) {
            modelWithAtLeastOneTemplateAndAtLeastOneEntity.templateExamples = [''];
        }

        return { modelId, model: modelWithAtLeastOneTemplateAndAtLeastOneEntity };
    }, [model, fetchedModel, modelId]);

    useEffect(() => {
        if (!modelId) return;

        fetchModel(modelId).finally(() => {
            if (isMounted()) {
                setFetchedModel(true);
            }
        });
    }, [modelId, isMounted]);

    return modelStatus;
}

import { nanoid } from 'nanoid';
import isPlainObject from 'is-plain-obj';
import { FileStatus } from '../components/FileAdder/types';
import {
    DatasetFile,
    DeidentifyResponse,
    FetchStatus,
    ModelFormState,
    ModelModel,
    PiiTypeEnum,
    RedactedTextChunk,
    SignUpSignInError,
    PdfRedactionType,
    ManualPdfRedactionType,
} from '../types';

export function noop() {
    return;
}

export function getLoadingFetchStatus(fetchStatus: FetchStatus): FetchStatus {
    return fetchStatus === 'success' ? 'refreshing' : 'loading';
}

export function isValidPiiType(v: unknown): v is PiiTypeEnum {
    return typeof v === 'string' && Object.values(PiiTypeEnum).includes(v as PiiTypeEnum);
}

export function appURL(pathname: string) {
    const u = new URL(window.location.toString());
    if (u.port === '3002' && window.location.toString().includes('/api/')) {
        u.port = '9001';
    }
    u.hash = '';
    u.search = '';
    u.pathname = pathname;
    return u.toString();
}

export function parseDate(d: string | undefined | null): Date | null {
    if (d === undefined || d === null) return null;

    try {
        return new Date(d);
    } catch {
        return null;
    }
}

const dateTimeFormatter = new Intl.DateTimeFormat(navigator.language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export function formatDateTime(d: Date) {
    return dateTimeFormatter.format(d);
}

export function urlSearchParamsToObject(p: URLSearchParams) {
    const values: Record<string, string | null> = {};

    p.forEach((value, key) => {
        values[key] = value;
    });

    return values;
}

export function isSignUpSignInError(v: unknown): v is SignUpSignInError {
    if (!isPlainObject(v)) return false;

    if (!('login_failure' in v) || !Array.isArray(v['login_failure'])) return false;

    return v.login_failure.every((item) => typeof item === 'string');
}

export function canModelBeTrained(model: ModelModel | ModelFormState) {
    return !!model.entities?.filter((e) => e.examples?.length)?.length && !!model.templateExamples?.filter((t) => t?.trim().length)?.length;
}

//We support text files and PDFs as of now
export const UNSUPPORTED_FILE_EXTENSIONS = new Set([
    // Images
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'tif',
    'tiff',
    'ico',
    'webp',
    // Audio
    'mp3',
    'wav',
    'ogg',
    'flac',
    'aac',
    'm4a',
    // Video
    'mp4',
    'mkv',
    'flv',
    'avi',
    'mov',
    'wmv',
    'webm',
    // Archives and Disk Images
    'zip',
    'rar',
    'tar',
    'gz',
    'iso',
    '7z',
    'dmg',
    // 3D and Graphics
    'psd',
    'ai',
    'sketch',
    'blend',
    // Documents
    'doc',
    'ppt',
    'pptx',
    'xls',
    'xlsx',
    'odt',
    'ods',
    'odp',
    // Databases and Data Files
    'mdb',
    'db',
    'sqlite',
    'sqlite3',
    'dbf',
    'sav',
    // Executable and Binary
    'exe',
    'dll',
    'bin',
    'dmg',
    'apk',
    'app',
    'ipa',
    // Others
    'obj',
    'dll',
    'so',
    'o',
    'class',
    'jar',
]);

export function getRedactionTextChunks(text: string, deidentifyResponse: DeidentifyResponse[]): RedactedTextChunk[] {
    const textChunks: RedactedTextChunk[] = [];

    let currentChunkString = '';
    let inRedaction: boolean | null = false;
    let previousRedaction: DeidentifyResponse | undefined = undefined;
    for (let i = 0; i < text.length; i++) {
        const currentRedaction = deidentifyResponse.find((d) => {
            return i >= d.start && i < d.end;
        });

        const currentIndexInRedaction = currentRedaction !== undefined;

        if (currentIndexInRedaction !== inRedaction) {
            if (currentChunkString.length > 0) {
                textChunks.push({
                    id: nanoid(),
                    text: currentChunkString,
                    label: previousRedaction?.label,
                    score: previousRedaction?.score,
                    exampleRedaction: previousRedaction?.exampleRedaction,
                });
                currentChunkString = '';
            }
            previousRedaction = currentRedaction;
            inRedaction = currentIndexInRedaction;
        }

        currentChunkString += text.charAt(i);

        if (i === text.length - 1) {
            textChunks.push({
                id: nanoid(),
                text: currentChunkString,
                label: previousRedaction?.label,
                score: previousRedaction?.score,
                exampleRedaction: previousRedaction?.exampleRedaction,
            });
        }
    }

    return textChunks;
}

export const SENSITIVE_FIELD_FILLER = '🐐🐐🐐🐐🐐🐐🐐🐐';

export function getFileStatus(status: DatasetFile['processingStatus']): FileStatus {
    if (status === 'Queued') return FileStatus.Queued;
    if (status === 'Running') return FileStatus.Running;
    if (status === 'Failed') return FileStatus.Failed;
    if (status === 'Cancelled') return FileStatus.Cancelled;
    return FileStatus.Completed;
}

export function isManualPdfRedactionType(value: unknown): value is ManualPdfRedactionType {
    if (typeof value !== 'string') return false;

    return value === PdfRedactionType.None || value === PdfRedactionType.AddRedaction || value === PdfRedactionType.RemoveRedaction;
}

export function getFileNameFromUrl(url: string) {
    if (!url) return '';

    const urlObject = new URL(url);
    return urlObject.pathname.split('/').pop();
}

export function getRedactedFileUrl(url: string) {
    if (!url) return '';

    const urlObject = new URL(url);

    const path = urlObject.pathname;

    const pathParts = path.split('.');

    if (pathParts.length < 2) return '';

    const extension = pathParts.pop();

    return `${urlObject.protocol}//${urlObject.host}${pathParts[0]}_REDACTED.${extension}`;
}

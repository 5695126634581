import { Preview } from './Preview';
import { blockTextContentAtom, clearDeidResponse, blockTextOffsetAtom, deidResponseAtom, updateBlockTextContent } from './state';
import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { EditorView } from 'prosemirror-view';
import { createStore, useAtomValue } from 'jotai';
import { AllSelection, EditorState } from 'prosemirror-state';
import { keymap } from 'prosemirror-keymap';
import { deleteSelection, joinBackward, splitBlock } from 'prosemirror-commands';
import { SampleMenu } from './SampleMenu';
import { MarkSpec, Schema } from 'prosemirror-model';
import styles from './styles.module.scss';

const redactionMark: MarkSpec = {
    group: 'inline',
    inclusive: false,
    attrs: {
        'data-type': {
            default: 'redaction',
        },
        class: {
            default: 'redaction',
        },
    },
    parseDOM: [
        {
            tag: 'span',
            priority: 100,
            getAttrs(node) {
                if (typeof node === 'string') {
                    return false;
                }

                return {
                    'data-type': 'redaction',
                    class: 'redaction',
                };
            },
        },
    ],
    toDOM() {
        return [
            'span',
            {
                'data-type': 'redaction',
                class: 'redaction',
            },
            0,
        ];
    },
};

// Schema

const schema = new Schema({
    nodes: {
        doc: {
            content: 'block+',
        },
        paragraph: {
            group: 'block',
            content: 'text*',
            toDOM() {
                return ['p', 0];
            },
            parseDOM: [{ tag: 'p' }],
        },
        text: {},
    },
    marks: {
        redaction: redactionMark,
    },
});

export function Editor() {
    // Create memoized jotai store
    const storeOptions = useMemo(() => ({ store: createStore() }), []);

    // Atom values
    const deidResponse = useAtomValue(deidResponseAtom, storeOptions);
    const blockTextOffset = useAtomValue(blockTextOffsetAtom, storeOptions);
    const blockTextContent = useAtomValue(blockTextContentAtom, storeOptions);

    // Refs for interacting with dom outside of React lifecycle
    const containerRef = useRef<HTMLDivElement | null>(null);
    const editorViewRef = useRef<EditorView | null>(null);

    const editorStateRef = useRef(
        EditorState.create({
            schema,
            plugins: [
                keymap({
                    Enter: splitBlock,
                    'Shift-Enter': splitBlock,
                    Backspace: joinBackward,
                }),
                keymap({
                    Backspace: deleteSelection,
                }),
            ],
        })
    );

    useEffect(() => {
        if (!containerRef.current) return;

        editorViewRef.current = new EditorView(containerRef.current, {
            state: editorStateRef.current,
            dispatchTransaction(tr) {
                const editorView = editorViewRef.current;

                if (!editorView) return;

                editorView.updateState(editorView.state.apply(tr));

                const blockTextContent: string[] = [];
                editorView.state.doc.descendants((node) => {
                    if (node.type.name !== 'paragraph') return true;
                    blockTextContent.push(node.textContent);
                    return false;
                });
                updateBlockTextContent(storeOptions.store, blockTextContent);
            },
        });

        return () => {
            editorViewRef.current?.destroy();
        };
    }, [storeOptions]);

    useEffect(() => {
        if (!editorViewRef.current) return;

        const transaction = editorViewRef.current.state.tr;

        if (transaction.doc.content.size > 0) {
            transaction.removeMark(0, transaction.doc.content.size, schema.mark('redaction'));
        }

        transaction.doc.descendants((node, position, _parent, index) => {
            if (node.type.name === 'paragraph' && node.isTextblock) {
                const blockOffset = blockTextOffset[index] ?? 0;
                const textLength = node.textContent.length;

                const results = deidResponse?.deIdentifyResults ?? [];

                results.forEach(({ start, end, label }) => {
                    if (start >= blockOffset && end <= textLength + blockOffset) {
                        transaction.addMark(
                            start + position - blockOffset + 1,
                            end + position - blockOffset + 1,
                            schema.mark('redaction', {
                                'data-label': label,
                            })
                        );
                    }
                });
                return false;
            }
            return true;
        });

        return editorViewRef.current.dispatch(transaction);
    }, [deidResponse, blockTextOffset]);

    const clearEditor = useCallback(() => {
        if (!editorViewRef.current) return;

        const tr = editorViewRef.current.state.tr;
        tr?.setSelection(new AllSelection(editorViewRef.current.state.doc));
        tr?.deleteSelection();
        editorViewRef.current.dispatch(tr);

        editorViewRef.current?.focus();
    }, []);

    const setEditorContent = useCallback(
        (content: string) => {
            const editorView = editorViewRef.current;

            if (!editorView) return;

            const tr = editorViewRef.current?.state.tr;

            if (!tr) return;

            tr.setSelection(new AllSelection(editorView.state.doc));
            tr.deleteSelection();
            editorView.dispatch(tr);

            clearDeidResponse(storeOptions.store);
            editorView.pasteHTML(content);
        },
        [storeOptions]
    );

    return (
        <QuiBox display="flex" flexDirection="column" gap="md">
            <div>
                <SampleMenu setEditorContent={setEditorContent} />
            </div>
            <div className={styles.app}>
                <div className={styles.editorColumn}>
                    <div className={styles.editorBackdrop} />

                    {blockTextContent.join('').length === 0 ? (
                        <div className={styles.placeholder}>
                            <QuiBox text="mono-text-lg" color="text-disabled" padding="lg">
                                Start by typing here...
                            </QuiBox>
                        </div>
                    ) : (
                        <QuiButton size="xs" variant="minimal" iconLeft="x" onClick={clearEditor} className={styles.clearButton}>
                            Clear
                        </QuiButton>
                    )}

                    <div className={styles.editor} ref={containerRef} />
                </div>

                <Preview storeOptions={storeOptions} />
            </div>
        </QuiBox>
    );
}

import { useField } from 'react-final-form';
import { QuiField, QuiIconEnum, QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems } from '@tonicai/ui-quinine';
import styles from './IconField.module.scss';
import { FieldValidator } from 'final-form';

const ALL_ICONS = Object.values(QuiIconEnum);

type IconFieldProps = Readonly<{
    name: string;
    label: string;
    helperText: string;
    id: string;
    validate?: FieldValidator<QuiIconEnum>;
}>;

export function IconField({ id, name, label, helperText, validate }: IconFieldProps) {
    const field = useField<QuiIconEnum>(name, { initialValue: QuiIconEnum.Folder, validate });

    return (
        <QuiField id={id} label={label} helperText={helperText}>
            <QuiMenu>
                <QuiMenuButton iconLeft={field.input.value} iconRight="chevron-down" />
                <QuiMenuItems className={styles.iconMenuItems}>
                    {ALL_ICONS.map((icon) => (
                        <QuiMenuItem
                            key={icon}
                            onClick={() => {
                                field.input.onChange(icon);
                            }}
                            iconLeft={icon}
                        >
                            {icon}
                        </QuiMenuItem>
                    ))}
                </QuiMenuItems>
            </QuiMenu>
        </QuiField>
    );
}

import { CodeBlock } from '../../components/CodeBlock/CodeBlock';
import './GeneratorSetup.scss';
import { OnPremCallout } from '../../components/GoOnPrem/OnPremCallout';
import { useIsHostedProd } from '../../stores/settings';
import { QuiBox, QuiButtonExternalLink, QuiIconEnum } from '@tonicai/ui-quinine';
import { SDK_DOCUMENTATION_URL } from '../../constants';

const getCode = (name: string) => `from tonic_textual.api import TonicTextual

textual = TonicTextual("${window.location.protocol}//${window.location.host}")
ds = textual.get_dataset("${name}")
df = ds.fetch_all_df()`;

type DatasetMetaProps = Readonly<{
    datasetName: string;
}>;

export function DatasetMeta({ datasetName }: DatasetMetaProps) {
    const isHostedProd = useIsHostedProd();
    const code = getCode(datasetName);
    return (
        <QuiBox padding={'md'} display={'flex'} flexDirection="column" gap={'md'}>
            <QuiBox display={'flex'} flexDirection="column" gap={'2lg'}>
                <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                    <span className={'snippet-title'}>Install the tonic-textual package</span>
                    <span className={'snippet-description'}>Before you can use our Python SDK, you must install it.</span>
                    <CodeBlock title="Pip Install" code={`pip install tonic-textual`} language="python" />
                </QuiBox>
                <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                    <span className={'snippet-title'}>Python Snippet</span>
                    <span className={'snippet-description'}>Python snippet to grab data. Fetch your redacted data.</span>
                    <CodeBlock title="Python" code={code} language="python" />
                </QuiBox>
            </QuiBox>
            <QuiButtonExternalLink
                variant={'minimal'}
                href={SDK_DOCUMENTATION_URL}
                openInNewTab={true}
                iconRight={QuiIconEnum.ExternalLink}
                style={{ width: 'fit-content', padding: 0 }}
            >
                View SDK Documentation
            </QuiButtonExternalLink>
            {isHostedProd && <OnPremCallout />}
        </QuiBox>
    );
}

import { QuiBadge, QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useScale } from '../Templates/useScale';

type ZoomButtonsProps = Readonly<
    ReturnType<typeof useScale> & {
        className: string;
    }
>;

export function ZoomButtons({ className, canZoomIn, canZoomOut, zoomIn, zoomOut, stringScale }: ZoomButtonsProps) {
    return (
        <QuiBox
            className={`${className} qui-shadow-sm`}
            justifyContent="space-between"
            padding="xs"
            borderRadius="lg"
            border="black-600"
            bg="black"
            display="flex"
            gap="xs"
        >
            <QuiButton variant="black" disabled={!canZoomOut} size="xs" iconLeft="minus" type="button" onClick={zoomOut} />
            <QuiBadge size="xs" variant="black">
                {stringScale}
            </QuiBadge>
            <QuiButton variant="black" disabled={!canZoomIn} size="xs" iconLeft="plus" type="button" onClick={zoomIn} />
        </QuiBox>
    );
}

import { QuiButton, QuiModalContent, QuiModalDialog, QuiModalFooter, QuiModalHeader, QuiText, QuiTag, QuiBox } from '@tonicai/ui-quinine';
import { useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';

type DeleteDialogProps = Readonly<{
    isOpen: boolean;
    closeDialog: () => void;
    deleteMethod: () => Promise<void>;
    itemType: string;
    itemName: string;
    title: string;
}>;

export function DeleteDialog({ isOpen, closeDialog, deleteMethod, itemType, itemName, title }: DeleteDialogProps) {
    const isMounted = useIsMounted();

    const [isDeleting, setIsDeleting] = useState(false);

    const onDelete = () => {
        setIsDeleting(true);
        deleteMethod().then(() => {
            if (isMounted()) {
                setIsDeleting(false);
                closeDialog();
            }
        });
    };

    return (
        <QuiModalDialog
            disableDismissOnOutsideClick={isDeleting}
            disableDismissOnEscapeKeyDown={isDeleting}
            title={title}
            icon="trash"
            isOpen={isOpen}
            onClose={closeDialog}
        >
            <QuiModalHeader>
                <QuiBox display={'inline'}>
                    {`Are you sure you want to delete the ${itemType}`}
                    <QuiTag className="qui-mono-text-sm">{itemName}</QuiTag>
                    {'?'}
                </QuiBox>
            </QuiModalHeader>
            <QuiModalContent>
                <QuiText size="text-xs">
                    {`Once this ${itemType} is deleted, it `}
                    <strong>cannot</strong>
                    {' be recovered.'}
                </QuiText>
            </QuiModalContent>
            <QuiModalFooter>
                <QuiBox display="flex" gap="md" flexGrow="1" justifyContent="end">
                    <QuiButton disabled={isDeleting} type="button" onClick={closeDialog}>
                        Cancel
                    </QuiButton>
                    <QuiButton disabled={isDeleting} variant="destructive" type="button" onClick={onDelete}>
                        Delete
                    </QuiButton>
                </QuiBox>
            </QuiModalFooter>
        </QuiModalDialog>
    );
}

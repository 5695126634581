import { QuiBox, QuiButton, QuiTextAreaField } from '@tonicai/ui-quinine';
import { useFieldArray } from 'react-final-form-arrays';
import { forwardRef } from 'react';

type TemplateExampleFieldProps = {
    name: string;
    fieldArrayName: string;
    index: number;
};

export const TemplateExampleField = forwardRef<HTMLTextAreaElement, TemplateExampleFieldProps>(({ name, fieldArrayName, index }, ref) => {
    const fieldArray = useFieldArray<string>(fieldArrayName);

    return (
        <QuiBox display="flex" gap="sm">
            <QuiBox display="flex" alignItems="stretch" flexGrow="1">
                <QuiTextAreaField ref={ref} name={name} style={{ flexGrow: 1 }} />
            </QuiBox>
            <QuiButton
                type="button"
                size="sm"
                onClick={() => {
                    if (fieldArray.fields.length === 1) {
                        fieldArray.fields.update(index, '');
                    } else {
                        fieldArray.fields.remove(index);
                    }
                }}
                iconLeft="trash"
            />
        </QuiBox>
    );
});

TemplateExampleField.displayName = 'TemplateExampleField';

import { createStore, useAtomValue } from 'jotai';
import { blockTextContentAtom, loadingResponseAtom, previewDataAtom } from './state';
import { useMemo } from 'react';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import styles from './styles.module.scss';

enum PreviewDisplay {
    Empty,
    Loading,
    PreviewResponse,
}

type PreviewProps = Readonly<{
    storeOptions: { store: ReturnType<typeof createStore> };
}>;

export function Preview({ storeOptions }: PreviewProps) {
    const loading = useAtomValue(loadingResponseAtom, storeOptions);
    const previewData = useAtomValue(previewDataAtom, storeOptions);
    const blockTextContent = useAtomValue(blockTextContentAtom, storeOptions);

    const previewDisplay = useMemo<PreviewDisplay>(() => {
        const emptyBlockTextContent = blockTextContent.map((t) => t.trim()).join('').length === 0;

        if (emptyBlockTextContent) return PreviewDisplay.Empty;

        if (!previewData) return PreviewDisplay.Loading;

        return PreviewDisplay.PreviewResponse;
    }, [blockTextContent, previewData]);

    if (previewDisplay === PreviewDisplay.Empty) {
        return (
            <QuiBox
                text="mono-text-lg"
                bg="background-tertiary"
                border="border-default"
                borderRadius="md"
                color="text-disabled"
                padding="lg"
                gap="md"
                display="flex"
                flexDirection="column"
            >
                Results...
            </QuiBox>
        );
    }

    if (loading || previewDisplay === PreviewDisplay.Loading) {
        return (
            <QuiBox
                text="mono-text-lg"
                bg="background-tertiary"
                border="border-default"
                borderRadius="md"
                color="text-disabled"
                padding="lg"
                gap="md"
                display="flex"
                flexDirection="column"
            >
                <QuiSpinner size="sm" />
            </QuiBox>
        );
    }

    return (
        <QuiBox
            text="mono-text-lg"
            bg="background-tertiary"
            border="border-default"
            borderRadius="md"
            color="text-disabled"
            padding="lg"
            gap="md"
            display="flex"
            flexDirection="column"
        >
            {previewData?.map((p) => (
                <p key={p.id}>
                    {p.chunks.map((c) => {
                        if (c.label && c.score) {
                            // If we ever add synthesize support, something like this will be needed:
                            // const redactedLabel = c.exampleRedaction ? c.exampleRedaction : c.label;
                            const redactedLabel = c.label;
                            return (
                                <span key={c.id} className={styles.previewRedaction}>
                                    {redactedLabel}
                                </span>
                            );
                        } else {
                            return <span key={c.id}>{c.text}</span>;
                        }
                    })}
                </p>
            ))}
        </QuiBox>
    );
}

import { deleteModel, trainModel } from '../../stores/models';
import { useDialog } from '../../hooks/useDialog';
import { ModelTrainingStatus } from '../../types';
import {
    QuiButton,
    QuiModalContent,
    QuiModalDialog,
    QuiModalFooter,
    QuiModalHeader,
    QuiText,
    QuiMenu,
    QuiMenuButton,
    QuiMenuItems,
    QuiMenuItem,
    useQuiToasts,
    QuiBox,
} from '@tonicai/ui-quinine';
import { useIsMounted } from '../../hooks/useIsMounted';

type ModelMenuProps = { modelId: string; modelTrainingStatus?: ModelTrainingStatus; canBeTrained: boolean };
export function ModelMenu({ modelId, modelTrainingStatus, canBeTrained }: ModelMenuProps) {
    const { isOpen, closeDialog, openDialog } = useDialog();
    const isMounted = useIsMounted();
    const addToast = useQuiToasts();

    const hasStartedTraining = !!modelTrainingStatus && modelTrainingStatus !== 'NotYetQueued';

    return (
        <>
            <QuiMenu>
                <QuiMenuButton iconLeft="more-horizontal" />
                <QuiMenuItems>
                    <QuiMenuItem
                        disabled={!canBeTrained}
                        onClick={() => trainModel(modelId).then(() => addToast({ title: 'Model Training', variant: 'primary' }))}
                        iconLeft="zap"
                    >
                        {hasStartedTraining ? 'Retrain' : 'Train'}
                    </QuiMenuItem>
                    <QuiMenuItem onClick={openDialog} iconLeft="trash">
                        Delete
                    </QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>
            <QuiModalDialog title="Delete Model Confirmation" icon="trash" isOpen={isOpen} onClose={closeDialog}>
                <QuiModalHeader>Are you sure you want to delete this model?</QuiModalHeader>
                <QuiModalContent>
                    <QuiText size="text-xs">
                        Once this model is deleted, it <strong>cannot</strong> be recovered.
                    </QuiText>
                </QuiModalContent>
                <QuiModalFooter>
                    <QuiBox display="flex" gap="md" flexGrow="1" justifyContent="end">
                        <QuiButton type="button" onClick={closeDialog}>
                            Cancel
                        </QuiButton>
                        <QuiButton
                            variant="destructive"
                            type="button"
                            onClick={() => {
                                deleteModel(modelId).then(() => {
                                    if (isMounted()) {
                                        closeDialog();
                                    }
                                });
                            }}
                        >
                            Delete
                        </QuiButton>
                    </QuiBox>
                </QuiModalFooter>
            </QuiModalDialog>
        </>
    );
}

import { FormError } from '../../components/FormError/FormError';
import { useIsSharepointUploadEnabled } from '../../stores/settings';
import { requiredString } from '../../validation';
import { QuiSubmitButton, QuiTextField, QuiBox, QuiSelectField } from '@tonicai/ui-quinine';

export function CreateDataseFormContent() {
    const supportsSharepoint = useIsSharepointUploadEnabled();

    return (
        <QuiBox display="flex" gap="md" flexDirection="column">
            <QuiTextField placeholder="Enter the name for the dataset..." validate={requiredString} name="name" label="Dataset Name" />
            {supportsSharepoint && (
                <>
                    <QuiSelectField
                        label={'File Source'}
                        name={'fileSource'}
                        validate={requiredString}
                        options={[
                            { label: 'Local', value: 'Local' },
                            { label: 'Sharepoint', value: 'Sharepoint' },
                        ]}
                        menuPortalTarget={document.body}
                    />
                </>
            )}
            <FormError />
            <div>
                <QuiSubmitButton variant="brand-purple">Create Dataset</QuiSubmitButton>
            </div>
        </QuiBox>
    );
}

// This is a frontend-specific type definition to make life easier, there's no backend equivalent
export type CurrentUser = {
    displayName?: string;
    userName?: string;
    id?: string;
    photoURL: string | null;
    createdDate: null | Date;
    lastActivityDate: null | Date;
};

// Keep in sync with:
// backend/Solar.Api/Models/UserPhotoModel.cs
export type UserPhotoModel = {
    name: string;
    url?: string;
    fileType?: string;
    // content?: byte[] <-- unused on frontend
    isManualUpload: boolean;
};

// Keep in sync with:
// backend/Solar.Api/Models/UserEntityModel.cs
export type UserEntityModel = {
    id?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    lastActivityDate?: string;
    photoUpload?: UserPhotoModel;
};

// Keep in sync with:
// backend/Solar.Api/Models/LoginAndRefreshTokenResponseModel.cs
export type LoginAndRefreshTokenResponseModel = {
    user?: UserEntityModel;
    jwt?: string;
    expiresIn: number;
    refreshToken: string;
    refreshTokenRefreshIntervalInSeconds: number;
    inactivityTimeoutInSeconds: number;
    environmentName?: string;
    createdDate: string;
    amplitudeApiKey?: string;
    amplitudeApiUrl?: string;
};

// Keep in sync with:
// backend/Solar.Api/Enums/SsoProvider.cs
export enum SsoProvider {
    google = 'Google',
    github = 'GitHub',
    okta = 'Okta',
    azure = 'Azure',
    oidc = 'OIDC',
}

export type SsoSettingsResponse = {
    isRequired: boolean;
    settings: SsoSettingsFromApi[];
};

// Keep in sync with:
// backend/Solar.Api/Models/SsoSettings.cs
export type SsoSettingsFromApi = {
    enabled: boolean;
    provider: SsoProvider;
    clientId?: string;
    domain?: string;
    identityProviderId?: string;
    authorizationServerId?: string;
    canUseAsExternalFileSource: boolean;
};

export type EnabledSSOConfig = {
    provider: SsoProvider;
    clientId: string;
    domain?: string;
    identityProviderId?: string;
    authorizationServerId?: string;
    canUseAsExternalFileSource: boolean;
};

export type SSOSignInConfig = {
    token: string;
    provider: SsoProvider;
    originalRedirectUrl: string;
    parameters: Record<string, string | null>;
};

export type SignInFormValues = {
    userName: string;
    password: string;
};

export type SignUpFormValues = {
    email: string;
    password: string;
    confirmPassword: string;
};

export type SSOCallbackData = {
    token: string;
    provider: SsoProvider;
    searchParams: Record<string, string | null>;
    type: 'code';
};

export type SSOErrorData = {
    error: string | null;
    errorDescription: string | null;
    type: 'error';
};

export type SignUpSignInError = {
    login_failure: string[];
};

export type ResetPasswordRequestModel = {
    token: string;
    userName: string;
    newPassword: string;
};

/**
 * External Credentials
 */
export type ExternalCredential = {
    credentialType: ExternalCredentialType;
    name: string;
    value: string;
};

export enum ExternalCredentialType {
    OpenAIKey = 'OpenAIKey',
    GoogleServiceAccount = 'GoogleServiceAccount',
}

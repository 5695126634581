import { QuiBox, QuiButton, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { usePdfFileData } from '../../hooks/usePdfFileData';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import { Dataset, DatasetFile, PdfRedactionSource } from '../../types';
import styles from './PdfFilePreviewDialog.module.scss';
import { useScale } from '../Templates/useScale';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';
import { PdfPage } from '../PdfPage/PdfPage';
import { useTemplate } from '../../hooks/useTemplate';
import { useCallback, useMemo, useState } from 'react';
import { useFileBlob } from '../../hooks/useFileBlob';

type PdfFilePreviewDialogProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    file: DatasetFile;
    dataset: Dataset;
}>;

export function PdfFilePreviewDialog({ isOpen, onClose, file, dataset }: PdfFilePreviewDialogProps) {
    useBodyOverflowHidden(isOpen);

    const pdfFileData = usePdfFileData(file.datasetId, file.fileId);
    const { template } = useTemplate(file.datasetId, pdfFileData?.fileTemplateId);
    const [numPages, setNumPages] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(0);

    const hasNextPage = pageIndex < numPages - 1;
    const hasPreviousPage = pageIndex > 0;
    const nextPage = useCallback(() => {
        setPageIndex((prev) => prev + 1);
    }, []);
    const previousPage = useCallback(() => {
        setPageIndex((prev) => prev - 1);
    }, []);

    const { pdfBlob } = useFileBlob(file.datasetId, file.fileId);

    const redactions = useMemo(() => {
        return [...(pdfFileData?.fileSpecificRedactions ?? []), ...(pdfFileData?.automaticJobRedactions ?? []), ...(template?.redactions ?? [])];
    }, [pdfFileData, template?.redactions]);

    useBodyOverflowHidden(isOpen);

    const scale = useScale();

    const handleDocumentLoadSuccess = useCallback((doc: PDFDocumentProxy) => {
        setNumPages(doc.numPages);
    }, []);

    return (
        <QuiModalDialog
            title={
                <QuiBox display="flex" gap="lg" alignItems="center">
                    <QuiBox>{file.fileName}</QuiBox>
                    {(hasNextPage || hasPreviousPage) && (
                        <QuiBox
                            alignItems="center"
                            padding="xs"
                            border="white-600"
                            borderRadius="md"
                            display="flex"
                            justifyContent="center"
                            gap={'md'}
                        >
                            <QuiButton variant="minimal" size="sm" disabled={!hasPreviousPage} onClick={previousPage} iconLeft={'arrow-left'} />
                            <QuiBox display="inline-flex" text="mono-text-sm">{`Page ${pageIndex + 1} / ${numPages}`}</QuiBox>
                            <QuiButton variant="minimal" size="sm" disabled={!hasNextPage} onClick={nextPage} iconRight={'arrow-right'} />
                        </QuiBox>
                    )}
                </QuiBox>
            }
            icon="file"
            isOpen={isOpen}
            onClose={onClose}
            className={styles.dialog}
        >
            <QuiModalContent className={styles.content}>
                {pdfBlob && pdfFileData.loadedSuccessfully ? (
                    <>
                        <div className={styles.column}>
                            <div className={styles.columnInner}>
                                <PdfPage
                                    onDocumentLoadSuccess={handleDocumentLoadSuccess}
                                    pdfBlob={pdfBlob}
                                    pageIndex={pageIndex}
                                    isEditable={false}
                                    isPreview={true}
                                    redactions={redactions}
                                    pdfWords={pdfFileData?.pdfWords}
                                    newRedactionSource={PdfRedactionSource.AttachedToFile}
                                    generatorSetup={dataset?.generatorSetup}
                                    pdfScale={scale.scale}
                                />
                            </div>
                            <ZoomButtons {...scale} className={styles.zoomButtons} />
                        </div>
                        <div className={styles.column}>
                            <div className={styles.columnInner}>
                                <PdfPage
                                    pdfBlob={pdfBlob}
                                    pageIndex={pageIndex}
                                    isEditable={false}
                                    isPreview={false}
                                    redactions={redactions}
                                    pdfWords={pdfFileData?.pdfWords}
                                    newRedactionSource={PdfRedactionSource.AttachedToFile}
                                    generatorSetup={dataset?.generatorSetup}
                                    pdfScale={scale.scale}
                                />
                            </div>
                            <ZoomButtons {...scale} className={styles.zoomButtons} />
                        </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </QuiModalContent>
        </QuiModalDialog>
    );
}

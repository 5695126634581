import { PageContainer } from '../../components/PageContainer/PageContainer';
import { QuiText } from '@tonicai/ui-quinine';

export function Help() {
    return (
        <PageContainer>
            <QuiText size="display-sm" weight="medium" as="h1">
                Help
            </QuiText>
        </PageContainer>
    );
}

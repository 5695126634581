import { usageAtom } from '../../stores/datasets';
import { useAtomValue } from 'jotai';
import './MonthlyCredits.scss';
import { useIsHostedProd } from '../../stores/settings';
import { QuiBox } from '@tonicai/ui-quinine';

export function MonthlyCredits() {
    const isHostedProd = useIsHostedProd();
    const usage = useAtomValue(usageAtom);

    const getCountWithUnits = (count: number | null) => {
        if (count == null) return <span>&nbsp;</span>;
        if (count <= 9999) return count;
        if (count >= 10000 && count <= 999999) return (count / 1000).toFixed(1) + 'K';
        return (count / 1000000).toFixed(1) + 'M';
    };

    if (isHostedProd) {
        return (
            <QuiBox borderBottom="white-600" display="flex" flexDirection="column" padding="lg" bg="white" borderRadius="md">
                <QuiBox>🎁 Free Credits</QuiBox>
                <QuiBox text="display-xs" weight="medium">
                    {getCountWithUnits(usage)} of 100k
                </QuiBox>
                <QuiBox>Free Word Credits Used</QuiBox>
            </QuiBox>
        );
    }

    return (
        <QuiBox borderBottom="white-600" display="flex" flexDirection="column" padding="lg" bg="white" borderRadius="md">
            <QuiBox>🎉 Monthly usage</QuiBox>
            <QuiBox text="display-xs" weight="medium">
                {getCountWithUnits(usage)}
            </QuiBox>
            <QuiBox>Total Words Scanned</QuiBox>
        </QuiBox>
    );
}

import { Form, FormRenderProps } from 'react-final-form';
import { FormSection } from '../../components/FormSection/FormSection';
import { ModelFormState, ModelModel } from '../../types';
import { QuiButton, QuiButtonLink, QuiText, QuiTextField, QuiTextAreaField, useQuiToasts, QuiBox } from '@tonicai/ui-quinine';
import { canModelBeTrained } from '../../utils';
import { modelNameValidator, formatAndParseModelName, requiredString } from '../../validation';
import { useCallback } from 'react';
import { submitEditModelForm } from '../../stores/models';
import { FORM_ERROR, Mutator } from 'final-form';
import { IconField } from '../../components/IconField/IconField';
import { TemplateExamplesField } from './TemplateExamplesField';
import { EntitiesField } from './EntitiesField';
import mutators from 'final-form-arrays';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';

type EditModelFormState = ModelFormState & { generatedTemplateExamples?: string[]; generatingTemplateExamples?: boolean; trainOnSave?: boolean };

const modelFormStateMutators: Record<string, Mutator<EditModelFormState, Partial<ModelFormState>>> = {
    ...mutators,
    setFieldValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
    },
};

type EditModelFormProps = {
    modelId: string;
    model: EditModelFormState;
};

export function EditModelForm({ model, modelId }: EditModelFormProps) {
    const navigate = useNavigate();
    const addToast = useQuiToasts();

    const onSubmit = useCallback(
        async (values: EditModelFormState) => {
            submitEditModelForm(model, modelId, values, !!values?.trainOnSave)
                .then(() => {
                    addToast({
                        variant: 'positive',
                        title: `Saved model successfully${values?.trainOnSave ? '. Training job queued' : ''}`,
                    });
                })
                .catch((error) => {
                    if (isAxiosError(error) && error.response?.status === 409) {
                        addToast({
                            title: error.response?.data ?? 'Matching model entity with the same label already exists',
                            variant: 'destructive',
                        });
                    } else {
                        console.error(error);
                        const title = "Couldn't save model";
                        addToast({
                            title,
                            variant: 'destructive',
                        });
                        return { [FORM_ERROR]: title };
                    }
                })
                .finally(() => {
                    navigate('/models');
                });
        },
        [modelId, model, navigate, addToast]
    );

    const hasStartedTraining = !!model.modelTrainingStatus && model.modelTrainingStatus !== 'NotYetQueued';
    const saveAndTrainLabel = hasStartedTraining ? 'Save & Retrain' : 'Save & Train';

    return (
        <Form<EditModelFormState> keepDirtyOnReinitialize={false} mutators={modelFormStateMutators} initialValues={model} onSubmit={onSubmit}>
            {(formRenderProps: FormRenderProps<EditModelFormState>) => (
                <form onSubmit={formRenderProps.handleSubmit}>
                    <PageContainer>
                        <QuiBox display="flex" gap="xl" flexDirection="column">
                            <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                                <QuiBox>
                                    <QuiText size="display-xs" weight="medium">
                                        Edit Model
                                    </QuiText>
                                    <QuiBox color="black-600">
                                        <QuiText size="text-sm">
                                            You can identify and redact custom entities by adding some examples and templates to process.
                                        </QuiText>
                                    </QuiBox>
                                </QuiBox>
                                <QuiBox display="flex" justifyContent="end" gap="md">
                                    <QuiButtonLink to="/models" size="lg">
                                        Cancel
                                    </QuiButtonLink>
                                    <QuiButton
                                        disabled={formRenderProps.invalid}
                                        onClick={() => formRenderProps.form.change('trainOnSave', false)}
                                        variant="brand-purple"
                                        type="submit"
                                        size="lg"
                                    >
                                        Save
                                    </QuiButton>
                                    <QuiButton
                                        iconLeft={'zap'}
                                        disabled={formRenderProps.invalid || !canModelBeTrained(formRenderProps.values)}
                                        onClick={() => formRenderProps.form.change('trainOnSave', true)}
                                        variant="brand-purple"
                                        type="submit"
                                        size="lg"
                                    >
                                        {saveAndTrainLabel}
                                    </QuiButton>
                                </QuiBox>
                            </QuiBox>

                            <FormSection
                                title="Model Details"
                                description="Define the entity first and then you can add template examples that will allow us to create more realistic examples."
                            >
                                <QuiTextField
                                    validate={modelNameValidator}
                                    config={{
                                        format: formatAndParseModelName,
                                        parse: formatAndParseModelName,
                                    }}
                                    name="name"
                                    label="Name"
                                    helperText="Use up to 128 characters. This field is required."
                                />

                                <IconField
                                    validate={requiredString}
                                    id="icon"
                                    name="icon"
                                    label="Icon"
                                    helperText="Select an icon to easily track your model"
                                />

                                <QuiTextAreaField
                                    name="description"
                                    label="Description"
                                    helperText="Describe the type of information."
                                    placeholder="Corporate or municipal projects, initiatives, etc."
                                />
                            </FormSection>

                            <FormSection
                                title="Entities"
                                description="Define the entity first and then you can add template examples that will allow us to create more realistic examples."
                            >
                                <EntitiesField name="entities" setFieldValue={formRenderProps.form.mutators.setFieldValue} />
                            </FormSection>

                            <FormSection
                                title="Template Examples"
                                description="These template examples help the system understand the context around where this data type will be used.
                                Please add at least 2 to the system to get started."
                            >
                                <TemplateExamplesField
                                    name="templateExamples"
                                    setFieldValue={formRenderProps.form.mutators.setFieldValue}
                                    generatedTemplateExamples={formRenderProps.form.getState().values.generatedTemplateExamples}
                                    generatingTemplateExamples={formRenderProps.form.getState().values.generatingTemplateExamples}
                                    model={formRenderProps.form.getState().values as ModelModel}
                                />
                            </FormSection>

                            <QuiBox borderTop="white-600" padding="lg" display="flex" justifyContent="end" gap="md">
                                <QuiButtonLink to="/models" size="lg">
                                    Cancel
                                </QuiButtonLink>
                                <QuiButton
                                    disabled={formRenderProps.invalid}
                                    onClick={() => formRenderProps.form.change('trainOnSave', false)}
                                    variant="brand-purple"
                                    type="submit"
                                    size="lg"
                                >
                                    Save
                                </QuiButton>
                                <QuiButton
                                    iconLeft={'zap'}
                                    disabled={formRenderProps.invalid || !canModelBeTrained(formRenderProps.values)}
                                    onClick={() => formRenderProps.form.change('trainOnSave', true)}
                                    variant="brand-purple"
                                    type="submit"
                                    size="lg"
                                >
                                    {saveAndTrainLabel}
                                </QuiButton>
                            </QuiBox>
                        </QuiBox>
                    </PageContainer>
                </form>
            )}
        </Form>
    );
}

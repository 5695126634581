import { useNavigate } from 'react-router-dom';
import { createDataset, updateDataset, useDataset } from '../../stores/datasets';
import { QuiBox, QuiButton, QuiForm, QuiIcon, QuiSubmitButton, QuiText, QuiTextField } from '@tonicai/ui-quinine';
import { useCallback, useState } from 'react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { requiredString } from '../../validation';
import { DatasetFormState } from '../../types';
import { FileAdder } from '../../components/FileAdder/FileAdder';
import styles from './NewDataset.module.scss';
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import { FormError } from '../../components/FormError/FormError';

export function NewDataset() {
    const [newDatasetId, setNewDatasetId] = useState<string | null>(null);
    const dataset = useDataset(newDatasetId);
    const navigate = useNavigate();

    const navigateToDataset = useCallback(
        (datasetId: string) => {
            navigate(`/datasets/${datasetId}`);
        },
        [navigate]
    );

    return (
        <PageContainer>
            {newDatasetId === null && (
                <div className={styles.container}>
                    <QuiBox display="flex" gap="md">
                        <QuiBox display="flex" alignItems="center">
                            <QuiIcon size="2xl" icon="folder-plus" />
                        </QuiBox>
                        <QuiBox>
                            <QuiText size="text-md" weight="medium" as="h2">
                                New Dataset
                            </QuiText>
                            <QuiBox text="text-xs" color="black-600">
                                A set of files you’re going to redact.
                            </QuiBox>
                        </QuiBox>
                    </QuiBox>

                    <QuiForm<{ name: string }>
                        onSubmit={({ name }: { name: string }) => {
                            return createDataset(name)
                                .then(async (id) => {
                                    setNewDatasetId(id);
                                    return undefined;
                                })
                                .catch((e) => {
                                    if (axios.isAxiosError(e) && e.response?.status === 409) {
                                        return {
                                            [FORM_ERROR]: "There's already a dataset with that name. Please choose another name.",
                                        };
                                    }

                                    return {
                                        [FORM_ERROR]: 'Something unexpected went wrong. Please try again.',
                                    };
                                });
                        }}
                    >
                        <QuiBox display="flex" gap="md" flexDirection="column">
                            <QuiTextField
                                placeholder="Enter the name for the dataset..."
                                validate={requiredString}
                                name="name"
                                label="Dataset Name"
                            />

                            <FormError />

                            <div>
                                <QuiSubmitButton variant="brand-purple">Create Dataset</QuiSubmitButton>
                            </div>
                        </QuiBox>
                    </QuiForm>
                </div>
            )}

            {newDatasetId !== null && dataset !== null && (
                <div className={styles.container}>
                    <QuiBox display="flex" padding="md" gap="md">
                        <QuiBox display="flex" alignItems="center">
                            <QuiIcon size="2xl" icon="folder-plus" />
                        </QuiBox>
                        <QuiBox>
                            <QuiText size="text-md" weight="medium" as="h2">
                                Upload files to redact
                            </QuiText>
                            <QuiBox text="text-xs" color="black-600">
                                Add the files you want to redact.
                            </QuiBox>
                        </QuiBox>
                    </QuiBox>

                    <QuiForm<DatasetFormState>
                        onSubmit={(values: DatasetFormState) => {
                            updateDataset(values)
                                .then(() => {
                                    navigateToDataset(newDatasetId);
                                    return undefined;
                                })
                                .catch((e) => {
                                    console.error(e);
                                    return 'something went wrong';
                                });
                        }}
                        initialValues={dataset}
                    >
                        <QuiBox display="flex" gap="md" flexDirection="column">
                            <FileAdder mode="dropzone" name="fileIds" datasetId={newDatasetId} />
                            <div>
                                <QuiButton onClick={() => navigateToDataset(newDatasetId)} variant="brand-purple">
                                    Save Dataset
                                </QuiButton>
                            </div>
                        </QuiBox>
                    </QuiForm>
                </div>
            )}
        </PageContainer>
    );
}

import { atom, useAtomValue } from 'jotai';
import { client } from '../services/HTTPClient';
import { SolarEnvironmentVariable, SolarEnvironmentVariables } from '../utils/environment';
import { globalStore } from './globalStore';

export const environmentVariablesAtom = atom<SolarEnvironmentVariables | null>(null);

export async function getEnvironmentVariables() {
    try {
        const { data } = await client.get<SolarEnvironmentVariables>('/api/environment');
        globalStore.set(environmentVariablesAtom, data);
    } catch (e) {
        console.error(e);
        globalStore.set(environmentVariablesAtom, null);
    }
}

const azureDocIntelligenceEnabledAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.SolarAzureDocIntelligenceEnabled] === true;
});

export function useIsAzureDocIntelligenceEnabled() {
    return useAtomValue(azureDocIntelligenceEnabledAtom);
}

export function useIsSharepointUploadEnabled() {
    return useAtomValue(environmentVariablesAtom)?.[SolarEnvironmentVariable.SolarSharepointUploadEnabled] === true;
}

const isHostedProdAtom = atom((get) => {
    return get(environmentVariablesAtom)?.[SolarEnvironmentVariable.IsHostedProd] === true;
});

export function useIsHostedProd() {
    return useAtomValue(isHostedProdAtom);
}

import { QuiBox, QuiButton, QuiPopover, QuiText } from '@tonicai/ui-quinine';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { deleteDataset } from '../../stores/datasets';
import { Dataset } from '../../types/index';

type DeleteDatasetButtonProps = {
    dataset: Dataset;
    datasetId: string;
};

export function DeleteDatasetButton({ datasetId, dataset }: DeleteDatasetButtonProps) {
    const navigate = useNavigate();
    const [showDeletePopover, setShowDeletePopover] = useState(false);

    return (
        <QuiPopover
            content={
                <QuiBox flexDirection="column" display="flex" gap="md" padding="sm">
                    <QuiText>
                        Are you sure you want to delete <QuiText inline>{dataset.name}</QuiText>?
                    </QuiText>
                    <QuiBox gap="sm" display="flex" justifyContent="end">
                        <QuiButton
                            size="sm"
                            variant="default"
                            onClick={() => {
                                setShowDeletePopover(false);
                            }}
                        >
                            Cancel
                        </QuiButton>
                        <QuiButton
                            size="sm"
                            variant="destructive"
                            onClick={() => {
                                deleteDataset(datasetId).finally(() => {
                                    navigate('/');
                                });
                            }}
                        >
                            Delete
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            }
            isOpen={showDeletePopover}
            onClose={() => setShowDeletePopover(false)}
        >
            <QuiButton
                iconRight="trash"
                size="sm"
                onClick={() => {
                    setShowDeletePopover(!showDeletePopover);
                }}
            >
                Delete
            </QuiButton>
        </QuiPopover>
    );
}

import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { QuiButton, QuiIconToken, QuiText, QuiTextField, QuiTextAreaField, useQuiToasts, QuiBox } from '@tonicai/ui-quinine';
import { useIsMounted } from '../../hooks/useIsMounted';
import { createModel } from '../../stores/models';
import { formatAndParseModelName, modelNameValidator } from '../../validation';
import { IconField } from '../../components/IconField/IconField';
import styles from './NewModel.module.scss';
import { isAxiosError } from 'axios';

type NewModelFormValue = {
    name: string;
    description: string;
    icon: QuiIconToken;
    enabled: boolean;
};

export function NewModel() {
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const addToast = useQuiToasts();

    const onSubmit = useCallback(
        async (values: NewModelFormValue) => {
            createModel(values)
                .then((newModelId) => {
                    if (isMounted()) {
                        navigate(`/models/${newModelId}`);
                    }
                })
                .catch((error: unknown) => {
                    if (isAxiosError(error) && error.response?.status === 409) {
                        const title = 'Model name already in use.  Model names are case insensitive.';
                        addToast({ title, variant: 'destructive' });
                        return { [FORM_ERROR]: title };
                    } else if (error) {
                        const title = 'Unable to create new model.';
                        addToast({ title, variant: 'destructive' });
                        return { [FORM_ERROR]: title };
                    }
                });
        },
        [navigate, isMounted, addToast]
    );

    return (
        <div className={styles.container}>
            <Form<NewModelFormValue> onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <QuiBox display="flex" flexDirection="column" gap="md">
                            <QuiText size="text-xl" weight="medium">
                                Create A New Model
                            </QuiText>
                            <QuiText size="text-xs">
                                You can identify and redact custom entities by adding some examples and templates to process.
                            </QuiText>

                            <QuiTextField
                                validate={modelNameValidator}
                                config={{ format: formatAndParseModelName, parse: formatAndParseModelName }}
                                name="name"
                                label="Name"
                                helperText="Use up to 128 characters. This field is required."
                            />

                            <IconField id="icon" name="icon" label="Icon" helperText="Select an icon to easily track your model" />

                            <QuiTextAreaField
                                name="description"
                                label="Description"
                                helperText="Describe the type of information."
                                placeholder="Corporate or municipal projects, initiatives, etc."
                            />

                            <div>
                                <QuiButton variant="brand-purple" type="submit">
                                    Create Model
                                </QuiButton>
                            </div>
                        </QuiBox>
                    </form>
                )}
            </Form>
        </div>
    );
}

import { atom, PrimitiveAtom } from 'jotai';
import { FetchStatus } from '../types';

/**
 * These functions create atoms that are computed from other atoms, so that we
 * don't re-create the same derived data values over and over again.
 */

// Parse the value of an atom with a FetchStatus value into a boolean value that
// lets you know whether to show a loading spinner or not. For cases where you
// need to fetch data before showing an error or not.
export function createLoadedAtomFromFetchStatusAtom(fetchStatusAtom: PrimitiveAtom<FetchStatus>) {
    return atom((get) => {
        const status = get(fetchStatusAtom);
        return status === 'refreshing' || status === 'success' || status === 'error';
    });
}

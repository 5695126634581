import { generateTemplateExamples } from '../../stores/models';
import { ModelModel } from '../../types';
import { QuiBox, QuiButton, QuiField, QuiTextAreaField, useQuiToasts } from '@tonicai/ui-quinine';
import { useFieldArray } from 'react-final-form-arrays';
import { TemplateExampleField } from './TemplateExampleField';
import { useEffect, useMemo, useRef } from 'react';

type TemplateExamplesFieldProps = {
    name: string;
    setFieldValue: (field: string, value: string[] | boolean) => void;
    generatedTemplateExamples?: string[];
    generatingTemplateExamples?: boolean;
    model: ModelModel;
};

export function TemplateExamplesField({
    name,
    setFieldValue,
    generatedTemplateExamples,
    generatingTemplateExamples,
    model,
}: TemplateExamplesFieldProps) {
    const { fields } = useFieldArray<string>(name);
    const addToast = useQuiToasts();

    const generateTemplates = async () => {
        try {
            setFieldValue('generatingTemplateExamples', true);
            const templateExampleResponse = await generateTemplateExamples(model);
            setFieldValue('generatedTemplateExamples', templateExampleResponse.data);
        } catch {
            addToast({ title: 'Error retrieving templates', variant: 'destructive' });
        } finally {
            setFieldValue('generatingTemplateExamples', false);
        }
    };

    const focusLastTextareaRef = useRef(false);
    const numFields = useMemo(() => fields?.length ?? 0, [fields]);

    useEffect(() => {
        if (focusLastTextareaRef.current && inputRefs.current?.[numFields - 1]) {
            inputRefs.current[numFields - 1].focus();
            focusLastTextareaRef.current = false;
        }
    }, [numFields]);

    const inputRefs = useRef<HTMLTextAreaElement[]>([]);

    return (
        <QuiField label="Template Examples" helperText="Add your examples below:">
            <QuiBox display="flex" flexDirection="column" gap="md">
                <QuiBox display="flex" flexDirection="column" gap="md">
                    {fields.map((field, index) => (
                        <TemplateExampleField
                            ref={(el) => {
                                if (el) {
                                    inputRefs.current[index] = el;
                                }
                            }}
                            index={index}
                            fieldArrayName={name}
                            name={field}
                            key={field}
                        />
                    ))}
                </QuiBox>
                <QuiBox display="flex" justifyContent="center">
                    <QuiButton
                        type="button"
                        onClick={() => {
                            fields.push('');
                            focusLastTextareaRef.current = true;
                        }}
                        iconLeft="plus"
                    >
                        Add Another Template
                    </QuiButton>
                </QuiBox>
            </QuiBox>

            <QuiTextAreaField label="Extra Instructions" helperText="Add additional instructions to refine results." name={'extraInstruction'} />

            <QuiBox
                padding="lg"
                border="white-600"
                borderRadius="md"
                bg="white-200"
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap="md"
            >
                <QuiButton
                    size="sm"
                    iconLeft="search"
                    spinner={generatingTemplateExamples}
                    disabled={generatingTemplateExamples}
                    type="button"
                    onClick={generateTemplates}
                >
                    Preview Generated Templates
                </QuiButton>
                <QuiBox color="black-600" text="text-xs">
                    {(generatedTemplateExamples &&
                        generatedTemplateExamples?.length > 0 &&
                        generatedTemplateExamples.map((t) => <div key={t}>{t}</div>)) || (
                        <div>
                            See what kind of templates the LLM will generate based on the templates you've provided. This will let you know if you
                            should adjust your templates or provide extra instructions.
                        </div>
                    )}
                </QuiBox>
            </QuiBox>
        </QuiField>
    );
}

import axios from 'axios';
import { globalStore } from '../stores/globalStore';
import { authResponseAtom } from '../stores/auth';

export const client = axios.create();

// Add access token to request (if it exists)
client.interceptors.request.use((config) => {
    const authResponse = globalStore.get(authResponseAtom);

    if (authResponse === null) return config;

    const newConfig = { ...config };

    newConfig.headers.set('Authorization', `Bearer ${authResponse.jwt}`);

    return newConfig;
});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            globalStore.set(authResponseAtom, null);
        }
        return Promise.reject(error);
    }
);

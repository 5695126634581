import { atom, createStore } from 'jotai';
import { nanoid } from 'nanoid';
import { client } from '../../services/HTTPClient';
import { isCancel } from 'axios';

type RedactedTextChunk = {
    id: string;
    text: string;
    label?: string;
    score?: number;
};

type PreviewData = {
    id: string;
    chunks: RedactedTextChunk[];
};

type DeIdentifyResult = {
    start: number;
    end: number;
    label: string;
    text: string;
    score: number;
};

type DeIdentifyResponse = {
    originalText: string;
    redactedText: string;
    usage: number;
    deIdentifyResults: DeIdentifyResult[];
};

// In milliseconds. Bumped this up from 500 so that slow typers don't get rate-limited as often
const REQUEST_DELAY = 800;

/**
 * ATOMS
 */

export const abortControllerAtom = atom<AbortController>(new AbortController());

export const blockTextContentAtom = atom<string[]>([]);

export const blockTextOffsetAtom = atom((get) => {
    let offset = 0;
    return (get(blockTextContentAtom) ?? []).map((content, index) => {
        const currentBlockOffset = offset + index;
        offset += content.length;
        return currentBlockOffset;
    });
});

export const deidResponseAtom = atom<DeIdentifyResponse | null>(null);

export const timeoutIdAtom = atom<null | number>(null);

export const loadingResponseAtom = atom<boolean>(false);

/**
 * Internal functions
 */

function getRedactionTextChunks(text: string, deidentifyResponse: DeIdentifyResponse, offset: number): RedactedTextChunk[] {
    const textChunks: RedactedTextChunk[] = [];

    let currentChunkString = '';
    let inRedaction: boolean | null = false;
    let previousRedaction: DeIdentifyResult | undefined = undefined;

    for (let i = 0; i < text.length; i++) {
        const actualI = i + offset;
        const currentRedaction = deidentifyResponse.deIdentifyResults.find((d) => {
            return actualI >= d.start && actualI < d.end;
        });

        const currentIndexInRedaction = currentRedaction !== undefined;

        if (currentIndexInRedaction !== inRedaction) {
            if (currentChunkString.length > 0) {
                textChunks.push({
                    id: nanoid(),
                    text: currentChunkString,
                    label: previousRedaction?.label,
                    score: previousRedaction?.score,
                });
                currentChunkString = '';
            }
            previousRedaction = currentRedaction;
            inRedaction = currentIndexInRedaction;
        }

        currentChunkString += text.charAt(i);

        if (i === text.length - 1) {
            textChunks.push({
                id: nanoid(),
                text: currentChunkString,
                label: previousRedaction?.label,
                score: previousRedaction?.score,
            });
        }
    }

    return textChunks;
}

/**
 * API
 */

export function clearDeidResponse(store: ReturnType<typeof createStore>) {
    store.set(deidResponseAtom, null);
    store.set(abortControllerAtom, (abortController) => {
        abortController.abort();
        return new AbortController();
    });
}

export const previewDataAtom = atom<PreviewData[] | null>((get) => {
    const deidResponse = get(deidResponseAtom);
    const blockTextContent = get(blockTextContentAtom);
    const blockTextOffsets = get(blockTextOffsetAtom);

    if (!deidResponse) return null;

    return blockTextOffsets.map((_, index) => {
        const content = blockTextContent[index];

        return {
            id: nanoid(),
            chunks: getRedactionTextChunks(content, deidResponse, blockTextOffsets?.[index] ?? 0),
        };
    });
});

export function fetchDeidentifyResponse(store: ReturnType<typeof createStore>) {
    store.set(loadingResponseAtom, true);
    client
        .post<DeIdentifyResponse>(
            '/api/redact',
            {
                text: store.get(blockTextContentAtom).join(' '),
            },
            {
                signal: store.get(abortControllerAtom).signal,
            }
        )
        .then(({ data }) => {
            store.set(deidResponseAtom, data);
            store.set(loadingResponseAtom, false);
        })
        .catch((e) => {
            store.set(loadingResponseAtom, false);
            if (isCancel(e)) {
                return;
            }
        });
}

export function updateBlockTextContent(store: ReturnType<typeof createStore>, blockTextContent: string[]) {
    const currentValue = store.get(blockTextContentAtom).join(' ');
    const newValue = blockTextContent.join(' ');

    if (currentValue === newValue) return;

    store.set(blockTextContentAtom, blockTextContent);

    store.set(abortControllerAtom, (abortController) => {
        abortController.abort();
        return new AbortController();
    });

    store.set(timeoutIdAtom, (currentTimeoutId) => {
        if (currentTimeoutId !== null) {
            window.clearTimeout(currentTimeoutId);
        }

        return window.setTimeout(() => {
            fetchDeidentifyResponse(store);
        }, REQUEST_DELAY);
    });
}

import { useEffect, useState } from 'react';
import { client } from '../services/HTTPClient';
import { isAxiosError, isCancel } from 'axios';
import { FetchStatus } from '../types';

function getBlob(pdfData: string) {
    const base64 = pdfData.startsWith('data:application/pdf;base64,') ? pdfData.split('data:application/pdf;base64,')[1] : pdfData;
    const bytes = atob(base64);
    const byteNumbers = new Array(bytes.length);
    for (let i = 0; i < bytes.length; i++) {
        byteNumbers[i] = bytes.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
}

export function useFileBlob(datasetId: string, fileId: string | undefined) {
    const [fetchStatus, setFetchStatus] = useState<FetchStatus>('init');
    const [pdfBlob, setPdfBlob] = useState<string | undefined>(undefined);

    useEffect(() => {
        // Wipe current state
        setPdfBlob(undefined);

        if (!fileId) {
            setFetchStatus('init');
            return;
        }

        setFetchStatus('loading');

        const abortController = new AbortController();

        client
            .get<string>(`/api/preview/${datasetId}/files/${fileId}/original`, { signal: abortController.signal })
            .then((response) => {
                setPdfBlob(getBlob(response.data));
                setFetchStatus('success');
            })
            .catch((e) => {
                if (isAxiosError(e) && isCancel(e)) {
                    // Do nothiing, this is called after we run `abortController.abort()`,
                    // so the component is unmounted and we don't want to set state on an
                    // unmounted component.
                } else {
                    setFetchStatus('error');
                }
            });

        return () => {
            abortController.abort();
        };
    }, [datasetId, fileId]);

    return {
        fetchStatus,
        pdfBlob,
    };
}

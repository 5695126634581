import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import mutators from 'final-form-arrays';
import { datasetsLoadedAtom, fetchDataset, updateDataset, useDataset } from '../../stores/datasets';
import { fetchModels } from '../../stores/models';
import { GeneratorSetup } from './GeneratorSetup';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { DatasetInfo } from './DatasetInfo';
import { DatasetMeta } from './DatasetMeta';
import { Dataset, DatasetFormState } from '../../types/index';
import { useAtomValue } from 'jotai';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import styles from './Dataset.module.scss';
import { Mutator } from 'final-form';
import dayjs from 'dayjs';

type DatasetLayoutProps = Readonly<{
    datasetId: string;
    dataset: Dataset;
}>;

function DatasetLayout({ dataset, datasetId }: DatasetLayoutProps) {
    const initialValues = useMemo<DatasetFormState>(() => {
        return {
            id: dataset.id,
            fileIds: [...dataset.files]
                .sort((a, b) => {
                    const diff = dayjs(a.uploadedTimestamp).diff(dayjs(b.uploadedTimestamp));
                    if (diff === 0) {
                        return a.fileName > b.fileName ? 1 : 0;
                    }
                    return diff;
                })
                .map((f) => f.fileId),
            name: dataset.name,
            generatorSetup: dataset.generatorSetup,
            labelBlockLists: dataset.labelBlockLists,
            enabledModels: dataset.enabledModels,
            datasetGeneratorMetadata: dataset.datasetGeneratorMetadata,
        };
    }, [dataset]);

    const handleSubmit = useCallback(async (values: DatasetFormState) => {
        await updateDataset(values);
    }, []);

    useEffect(() => {
        fetchDataset(datasetId);
    }, [datasetId]);

    useEffect(() => {
        fetchModels();
    }, []);

    return (
        <Form<DatasetFormState>
            mutators={{ ...mutators } as Record<string, Mutator<DatasetFormState>>}
            keepDirtyOnReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <PageContainer>
                        <div className={styles.container}>
                            <div className={styles.info}>
                                <DatasetInfo dataset={dataset} />
                            </div>
                            <div className={styles.main}>
                                <GeneratorSetup dataset={dataset} />
                            </div>
                            <div className={styles.meta}>
                                <DatasetMeta datasetName={dataset.name} />
                            </div>
                        </div>
                    </PageContainer>
                </form>
            )}
        </Form>
    );
}

// This component is just to ensure that the `datasetId` in the URL exists and
// the corresponding dataset also exists. This makes using hooks much easier,
// since we don't have to handle case where data is missing.
export function DatasetGuard() {
    const { datasetId = null } = useParams();
    const datasetsLoaded = useAtomValue(datasetsLoadedAtom);
    const dataset = useDataset(datasetId);

    if (!datasetsLoaded) {
        return (
            <QuiBox display="flex" padding="lg" justifyContent="center">
                <QuiSpinner />
            </QuiBox>
        );
    }

    if (!dataset || !datasetId) {
        return <Navigate to="/" />;
    }

    return <DatasetLayout dataset={dataset} datasetId={datasetId} />;
}

import { atom, useAtomValue } from 'jotai';
import { FetchStatus, ModelModel, ModelEntityModel, ModelRequestModel, ModelEntityRequestModel, ModelFormState, PiiTypeInfo } from '../types';
import { QuiIconToken } from '@tonicai/ui-quinine';
import { globalStore } from './globalStore';
import axios from 'axios';
import { client } from '../services/HTTPClient';
import { getLoadingFetchStatus } from '../utils';
import { createLoadedAtomFromFetchStatusAtom } from './atom-creators';

/**
 * Atoms
 */

export const modelsAtom = atom<ModelModel[]>([]);
export const modelsFetchStatusAtom = atom<FetchStatus>('init');
export const modelsLoadedAtom = createLoadedAtomFromFetchStatusAtom(modelsFetchStatusAtom);
export const modelsPiiTypeInfoAtom = atom<Record<string, PiiTypeInfo>>({});

/**
 * Actions
 */

let modelsGetAbortController = new AbortController();
export async function fetchModels() {
    globalStore.set(modelsFetchStatusAtom, getLoadingFetchStatus(globalStore.get(modelsFetchStatusAtom)));

    modelsGetAbortController.abort();
    modelsGetAbortController = new AbortController();

    await client
        .get<ModelModel[]>('/api/models', { signal: modelsGetAbortController.signal })
        .then(({ data }) => {
            globalStore.set(modelsAtom, data);
            globalStore.set(modelsFetchStatusAtom, 'success');
            globalStore.set(
                modelsPiiTypeInfoAtom,
                data.reduce(
                    (acc, model) => {
                        if (!model.entities?.length) return acc;
                        model.entities?.forEach((entity) => {
                            acc[entity.label] = {
                                label: entity.label,
                                icon: model.icon as QuiIconToken,
                                description: entity.description ?? '',
                            };
                        });
                        return acc;
                    },
                    {} as Record<string, PiiTypeInfo>
                )
            );
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                globalStore.set(modelsFetchStatusAtom, 'init');
            } else {
                globalStore.set(modelsFetchStatusAtom, 'error');
                throw e;
            }
        });
}

export async function fetchModel(modelId: string) {
    client.get<ModelModel>(`/api/models/${modelId}`).then(({ data }) => {
        globalStore.set(modelsAtom, [...globalStore.get(modelsAtom).filter(({ id }) => id !== data.id), data]);
    });
}

export async function createModel(newModel: ModelRequestModel) {
    return client
        .post<ModelModel>('/api/models', newModel)
        .then(async ({ data }) => {
            await fetchModels();
            return data.id;
        })
        .then((id) => id);
}

export async function updateModel(modelId: string, model: ModelRequestModel) {
    return client.put<ModelModel>(`/api/models/${modelId}`, model).then(fetchModels);
}

export async function trainModel(modelId: string) {
    return client.post<ModelModel>(`/api/models/${modelId}/train`);
}
export async function createModelEntity(modelId: string, newModelEntity: ModelEntityRequestModel) {
    return client
        .post<ModelEntityModel>(`/api/models/${modelId}/entities`, newModelEntity)
        .then(async ({ data }) => {
            await fetchModels();
            return data.id;
        })
        .then((id) => id);
}

export async function updateModelEntity(modelId: string, modelEntityId: string, newModelEntity: ModelEntityRequestModel) {
    return client
        .put<ModelEntityModel>(`/api/models/${modelId}/entities/${modelEntityId}`, newModelEntity)
        .then(async ({ data }) => {
            await fetchModels();
            return data.id;
        })
        .then((id) => id);
}

export async function deleteModel(modelId: string) {
    return client
        .delete(`/api/models/${modelId}`)
        .then(async ({ data }) => {
            await fetchModels();
            return data.id;
        })
        .then((id) => id);
}

export async function deleteModelEntity(modelId: string, modelEntityId: string) {
    return client
        .delete(`/api/models/${modelId}/entities/${modelEntityId}`)
        .then(async ({ data }) => {
            await fetchModels();
            return data.id;
        })
        .then((id) => id);
}

export async function generateEntityExamples(entity: ModelEntityRequestModel | ModelEntityModel) {
    return client.post<string[]>('/api/models/generate_entity_examples', entity);
}

export async function generateTemplateExamples(model: ModelModel) {
    return client.post<string[]>('/api/models/generate_template_examples', model);
}

export async function submitEditModelForm(initialFormState: ModelFormState, modelId: string, values: ModelFormState, trainOnSave: boolean) {
    // Figure out which entities this model had save to the database before
    // we made any changes. This might be empty.
    const originalModelEntityIds: string[] = [];
    initialFormState?.entities?.forEach((ent) => {
        if ('id' in ent) {
            originalModelEntityIds.push(ent.id);
        }
    });

    // Figure out which saved entities are still part of the model
    const formEntityIds: string[] = [];
    values?.entities?.forEach((ent) => {
        if ('id' in ent) {
            formEntityIds.push(ent.id);
        }
    });

    // Figure out which entities were deleted from the model
    const entityIdsToDelete = originalModelEntityIds.filter((c) => !formEntityIds.includes(c));

    // Extract the model form data to form the ModelRequestModel data
    const modelData: ModelRequestModel = {
        name: values.name,
        description: values.description,
        icon: values.icon,
        extraInstruction: values.extraInstruction,
        templateExamples: values.templateExamples,
    };

    // Update the model, delete the deleted entities, and either update or create the new entities
    await Promise.all([
        updateModel(modelId, modelData),
        ...entityIdsToDelete.map((entityId) => {
            return deleteModelEntity(modelId, entityId);
        }),
        ...(values?.entities ?? []).map((entity) => {
            if ('id' in entity) {
                return updateModelEntity(modelId, entity.id, entity);
            } else {
                return createModelEntity(modelId, entity);
            }
        }),
    ]);

    if (trainOnSave) {
        await trainModel(modelId);
    }
}

// Hooks

export function useModel(modelId: string | null) {
    const models = useAtomValue(modelsAtom);
    const model = models.find((m) => typeof m.id === 'string' && m.id === modelId);
    return model ?? null;
}

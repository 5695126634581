import { QuiBadge, QuiBox, QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems } from '@tonicai/ui-quinine';
import { Dataset } from '../../types';
import dayjs from 'dayjs';
import { useCurrentUser } from '../../stores/auth';
import { useDialog } from '../../hooks/useDialog';
import { UploadFilesToDatasetModal } from '../UploadFilesToDatasetModal/UploadFilesToDatasetModal';
import { DeleteDatasetModal } from '../DeleteDatasetModal/DeleteDatasetModal';
import { Link, useNavigate } from 'react-router-dom';
import styles from './DatasetCard.module.scss';

type DatasetCardProps = {
    dataset: Dataset;
};

export function DatasetCard({ dataset }: DatasetCardProps) {
    const user = useCurrentUser();
    const deleteDatasetDialog = useDialog();
    const uploadFilesDialog = useDialog();
    const navigate = useNavigate();

    return (
        <>
            <QuiBox
                display="flex"
                padding="md"
                borderRadius="md"
                justifyContent="space-between"
                border="white-600"
                bg="white"
                flexDirection="column"
                gap="sm"
            >
                <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                    <QuiBox display="flex" gap="sm" alignItems="end">
                        <QuiBadge iconLeft="database" variant="brand-purple" />
                        <QuiBadge size="xs" iconLeft="file">
                            {dataset.files.length === 1 ? '1 File' : `${dataset.files.length} Files`}
                        </QuiBadge>
                    </QuiBox>
                    <QuiMenu>
                        <QuiMenuButton iconLeft="more-horizontal" />
                        <QuiMenuItems>
                            <QuiMenuItem iconLeft="upload" onClick={uploadFilesDialog.openDialog}>
                                Upload Files
                            </QuiMenuItem>
                            <QuiMenuItem iconLeft="trash" onClick={deleteDatasetDialog.openDialog}>
                                Delete
                            </QuiMenuItem>
                        </QuiMenuItems>
                    </QuiMenu>
                </QuiBox>
                <QuiBox>
                    <Link className={styles.datasetCardLink} to={`/datasets/${dataset.id}`}>
                        <QuiBox>{dataset.name}</QuiBox>
                        <QuiBox text="text-xs" color="black-600">{`Edited ${dayjs(dataset.lastUpdated).fromNow()} by ${
                            user?.displayName ?? user?.userName
                        }`}</QuiBox>
                    </Link>
                </QuiBox>
            </QuiBox>

            <DeleteDatasetModal dataset={dataset} onClose={deleteDatasetDialog.closeDialog} isOpen={deleteDatasetDialog.isOpen} />

            <UploadFilesToDatasetModal
                dataset={dataset}
                onClose={() => {
                    uploadFilesDialog.closeDialog();
                    navigate(`/datasets/${dataset.id}}`);
                }}
                isOpen={uploadFilesDialog.isOpen}
            />
        </>
    );
}

import { QuiBox } from '@tonicai/ui-quinine';
import { CodeBlock } from '../../components/CodeBlock/CodeBlock';

export function PythonSnippets() {
    const code1 = `from tonic_textual.api import TonicTextual
textual = TonicTextual("${window.location.protocol}//${window.location.host}", "<api key>")

redacted_text = textual.redact("""The IP 192.168.123.132 has breached our
firewall. The machine is registered to a man named Luke McFee, a US Citizen
in Japan. This is a clear violation of The Computer Fraud and Abuse Act.""")

#This returns an object including the redact text and metadata.
print(redacted_text.redacted_text)`;

    return (
        <QuiBox padding={'md'} display={'flex'} flexDirection="column" gap={'md'}>
            <QuiBox display={'flex'} flexDirection="column" gap="2lg">
                <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                    <QuiBox text="text-xs" weight="medium">
                        Step One
                    </QuiBox>
                    <QuiBox text="text-xs" color="black-600">
                        Install the Textual API
                    </QuiBox>
                    <CodeBlock title="Bash" code={`pip install tonic-textual`} language="bash" />
                </QuiBox>
                <QuiBox display={'flex'} flexDirection="column" gap={'xs'}>
                    <QuiBox text="text-xs" weight="medium">
                        Step Two
                    </QuiBox>
                    <QuiBox text="text-xs" color="black-600">
                        Redact a few sentences
                    </QuiBox>
                    <CodeBlock title="Python" code={code1} language="python" />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

import { Link } from 'react-router-dom';
import { ModelModel } from '../../types';
import { QuiBadge, QuiBox, QuiText, isIconToken } from '@tonicai/ui-quinine';
import { canModelBeTrained } from '../../utils';
import { ModelMenu } from './ModelMenu';
import styles from './Models.module.scss';
import { ModelTrainingStatusBadge } from './ModelTrainingStatusBadge';

type ModelBlockProps = {
    model: ModelModel;
};

export function ModelBlock({ model }: ModelBlockProps) {
    const { id, icon, name, description, modelTrainingStatus } = model;

    const canBeTrained = canModelBeTrained(model);

    return (
        <QuiBox
            bg="white"
            padding="md"
            justifyContent="space-between"
            display="flex"
            gap="md"
            flexDirection="column"
            flexGrow="1"
            border="white-600"
            borderRadius="md"
        >
            <QuiBox display="flex" justifyContent="space-between">
                <QuiBox className={'qui-align-bottom'} display="flex" gap={'sm'}>
                    <QuiBadge variant="brand-purple" iconLeft={isIconToken(icon) ? icon : 'send'} />
                    <ModelTrainingStatusBadge modelTrainingStatus={modelTrainingStatus} />
                </QuiBox>
                <ModelMenu modelId={id} modelTrainingStatus={modelTrainingStatus} canBeTrained={canBeTrained} />
            </QuiBox>
            <QuiBox>
                <Link className={styles.modelLink} to={`/models/${id}`}>
                    <QuiText size="text-sm" weight="medium">
                        {name ?? 'Name'}
                    </QuiText>
                    <QuiBox color="black-600" text="text-xs">
                        {description ?? 'Description'}
                    </QuiBox>
                </Link>
            </QuiBox>
        </QuiBox>
    );
}

import { UploadFilesToExternalSourceModal } from '../../pages/Dataset/UploadFilesToExternalSourceModal';
import { client } from '../../services/HTTPClient';
import { Dataset, DatasetFile } from '../../types';
import { QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems, QuiModalDialog, useQuiModal } from '@tonicai/ui-quinine';
import { deleteDatasetFile, getDownloadFileDataUrl, fetchDataset } from '../../stores/datasets';
import { PdfFileFormDialog } from '../FilePreviewDialog/PdfFileFormDialog';
import styles from '../Templates/TemplateDialog.module.scss';
import { FileStatus } from './types';
import filePreviewStyles from '../FilePreviewDialog/FilePreviewDialog.module.scss';
import { FilePreviewDialog } from '../FilePreviewDialog/FilePreviewDialog';
import { retryJob } from '../../stores/jobs';
import { PdfFilePreviewDialog } from './PdfFilePreviewDialog';
import { instrumentation } from '../../instrumentation/instrumentation';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';
import { useCallback } from 'react';

type FileAdderFileItemMenuProps = Readonly<{
    file: DatasetFile;
    dataset: Dataset;
    fileStatus: FileStatus;
}>;

export function FileAdderFileItemMenu({ file, dataset, fileStatus }: FileAdderFileItemMenuProps) {
    const deleteDialog = useQuiModal();
    const previewDialog = useQuiModal();
    const pdfRedactionDialog = useQuiModal();
    const externalUploadDialog = useQuiModal();

    const url = getDownloadFileDataUrl(file.fileId, file.datasetId);

    const handleFileDownload = useCallback(() => {
        if (file.fileSource === 'Local') {
            window.open(url);
        } else {
            externalUploadDialog.open();
        }
    }, [externalUploadDialog, url, file.fileSource]);

    return (
        <>
            {/* Menu */}
            <QuiMenu enableFlip>
                <QuiMenuButton size="sm" iconLeft="more-horizontal" />
                <QuiMenuItems>
                    {/* Download */}
                    {fileStatus === FileStatus.Completed && (
                        <QuiMenuItem iconLeft={file.fileSource == 'Local' ? 'download' : 'upload-cloud'} onClick={handleFileDownload}>
                            {file.fileSource == 'Local' ? 'Download File' : `Upload to ${file.fileSource}`}
                        </QuiMenuItem>
                    )}

                    {/* Preview (non-PDF file) */}
                    {fileStatus === FileStatus.Completed && (
                        <QuiMenuItem onClick={previewDialog.open} iconLeft="search">
                            Preview
                        </QuiMenuItem>
                    )}

                    {/* Retry scanning job */}
                    {(fileStatus === FileStatus.Failed || fileStatus === FileStatus.Cancelled) && (
                        <QuiMenuItem
                            iconLeft="refresh-cw"
                            onClick={() => {
                                retryJob(file.fileId).then(() => {
                                    fetchDataset(dataset.id);
                                });
                            }}
                        >
                            Retry
                        </QuiMenuItem>
                    )}
                    {/* PDF Redaction */}
                    {file.fileType === 'Pdf' && fileStatus === FileStatus.Completed && (
                        <QuiMenuItem iconLeft="edit" onClick={pdfRedactionDialog.open}>
                            Edit Redactions
                        </QuiMenuItem>
                    )}
                    {/* Delete File */}
                    <QuiMenuItem onClick={deleteDialog.open} iconLeft="trash-2">
                        Delete File
                    </QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>

            {/* Dialogs */}

            {file.fileType === 'Pdf' && previewDialog.isOpen && (
                <PdfFilePreviewDialog dataset={dataset} file={file} isOpen onClose={previewDialog.close} />
            )}

            {file.fileType !== 'Pdf' && previewDialog.isOpen && (
                <QuiModalDialog closeButtonPosition={'outside'} className={filePreviewStyles.dialog} isOpen onClose={previewDialog.close}>
                    <FilePreviewDialog file={file} dataset={dataset} />
                </QuiModalDialog>
            )}

            {file.fileType == 'Pdf' && pdfRedactionDialog.isOpen && (
                <QuiModalDialog closeButtonPosition={'outside'} isOpen onClose={pdfRedactionDialog.close} className={styles.templateDialog}>
                    <PdfFileFormDialog fileId={file.fileId} datasetId={file.datasetId} />
                </QuiModalDialog>
            )}
            {externalUploadDialog.isOpen && (
                <UploadFilesToExternalSourceModal
                    onClick={async () => client.get(url)}
                    fileSource={file.fileSource}
                    specificFile={file}
                    isOpen
                    onClose={externalUploadDialog.close}
                />
            )}

            {deleteDialog.isOpen && (
                <DeleteDialog
                    title="Delete File Confirmation"
                    isOpen
                    closeDialog={deleteDialog.close}
                    deleteMethod={() =>
                        deleteDatasetFile(file.datasetId, file.fileId).finally(() => {
                            instrumentation.deleteFile(file.fileId);
                            deleteDialog.close();
                        })
                    }
                    itemType={'file'}
                    itemName={file.fileName}
                />
            )}
        </>
    );
}

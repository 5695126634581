import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { QuiBadge, QuiBox, QuiButtonLink, QuiSpinner, QuiText } from '@tonicai/ui-quinine';
import { ModelBlock } from './ModelBlock';
import { fetchModels, modelsAtom, modelsFetchStatusAtom, modelsLoadedAtom } from '../../stores/models';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import styles from './Models.module.scss';

export function Models() {
    const models = useAtomValue(modelsAtom);
    const modelsLoaded = useAtomValue(modelsLoadedAtom);
    const modelsFetchStatus = useAtomValue(modelsFetchStatusAtom);

    useEffect(() => {
        fetchModels();
    }, []);

    return (
        <PageContainer>
            <div className={styles.layout}>
                <div className={styles.sidebar}>
                    <QuiBox border="white-600" padding="md" borderRadius="md" alignItems="start" display="flex" gap="lg" flexDirection="column">
                        <QuiBox display="flex" gap="sm" alignItems="center" justifyContent="center">
                            <QuiBadge variant="purple" iconLeft="grid" />
                            <QuiText as="h1" size="text-xl" weight="bold">
                                Models
                            </QuiText>
                        </QuiBox>
                        <QuiText as="p" size="text-sm">
                            These are the data types that Tonic Textual can identify. You can modify existing or create new custom data types to
                            identify.
                        </QuiText>
                        <QuiButtonLink to="/models/new" iconLeft="plus">
                            Create New Model
                        </QuiButtonLink>
                    </QuiBox>
                </div>
                <main className={styles.main}>
                    {!modelsLoaded && <QuiSpinner />}
                    {modelsLoaded && modelsFetchStatus === 'error' && <div>Something went wrong!</div>}
                    {modelsLoaded && modelsFetchStatus === 'success' && (
                        <div className={styles.modelBlocks}>
                            {models.map((m) => (
                                <ModelBlock key={m.id} model={m} />
                            ))}
                        </div>
                    )}
                </main>
            </div>
        </PageContainer>
    );
}

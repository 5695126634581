import { PdfRedaction, PdfRedactionSource, PdfRedactionType } from '../../types';
import { isManualPdfRedactionType } from '../../utils';
import { PDF_REDACTION_TYPE_BADGE_VARIANT_MAP, PDF_REDACTION_TYPE_ICON_MAP } from './PdfRedactionsControls';
import { useField } from 'react-final-form';
import { QuiBadge, QuiBox, QuiButton } from '@tonicai/ui-quinine';

type PdfRedactionRowProps = Readonly<{
    name: string;
    remove: () => void;
    setEditingRedactionIndex: () => void;
    isActive: boolean;
    pageIndex: number;
}>;

export function PdfRedactionRow({ name, remove, setEditingRedactionIndex, isActive, pageIndex }: PdfRedactionRowProps) {
    const sourceField = useField<PdfRedactionSource | undefined>(`${name}.source`);
    const typeField = useField<PdfRedactionType | undefined>(`${name}.type`);
    const areasField = useField<PdfRedaction['areas']>(`${name}.areas`);

    const source = sourceField.input.value;
    const type = typeField.input.value;
    const areas = areasField.input.value;
    const pageNumber = areas[0].pageNumber;

    // We only want to render manual redactions, eventually this logic should be moved to the parent
    // component below. It's just easier to to it here now.
    if (source !== PdfRedactionSource.AttachedToFile && source !== PdfRedactionSource.AttachedToTemplate) {
        return null;
    }
    if (!isManualPdfRedactionType(type)) {
        return null;
    }
    if (pageNumber !== pageIndex + 1) {
        return null;
    }

    return (
        <QuiBox
            border={isActive ? 'blue-500' : 'white-600'}
            bg={isActive ? 'blue-100' : 'white'}
            display="flex"
            padding="xs"
            borderRadius="md"
            justifyContent="space-between"
            alignItems="center"
        >
            <QuiBadge variant={PDF_REDACTION_TYPE_BADGE_VARIANT_MAP[type]} iconLeft={PDF_REDACTION_TYPE_ICON_MAP[type]} />

            <QuiBox display="flex" gap="xs">
                <QuiButton size="sm" onClick={setEditingRedactionIndex} variant="outline" iconLeft="crosshair" type="button" />
                <QuiButton size="sm" onClick={remove} variant="outline" iconLeft="trash-2" type="button" />
            </QuiBox>
        </QuiBox>
    );
}

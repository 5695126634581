import { QuiIcon, QuiIconEnum } from '@tonicai/ui-quinine';
import { FileStatus } from './types';

export function FileIcon({ status }: { status: FileStatus }) {
    if (status === FileStatus.Queued) {
        return <QuiIcon size="sm" icon={QuiIconEnum.Clock} />;
    }

    if (status === FileStatus.Running) {
        return <QuiIcon size="sm" icon={QuiIconEnum.RefreshCw} />;
    }

    if (status === FileStatus.Failed || status === FileStatus.Cancelled) {
        return <QuiIcon size="sm" icon={QuiIconEnum.AlertTriangle} style={{ color: 'red' }} />;
    }

    return <QuiIcon size="sm" icon={QuiIconEnum.File} />;
}

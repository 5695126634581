import { client } from '../services/HTTPClient';
import { PdfRedaction } from '../types';
import axios from 'axios';

const getBaseEndpointUrl = (datasetId: string, jobId: string) => `/api/dataset/${datasetId}/pdfredaction/deidentifyjob/${jobId}`;

let fetchPdfFileWordsAbortController = new AbortController();

export async function fetchJobPdfRedactions(datasetId: string, jobId: string) {
    fetchPdfFileWordsAbortController.abort();
    fetchPdfFileWordsAbortController = new AbortController();

    return await client
        .get<PdfRedaction[]>(getBaseEndpointUrl(datasetId, jobId), { signal: fetchPdfFileWordsAbortController.signal })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            if (axios.isCancel(e)) {
                //do nothing
                return [];
            } else {
                throw e;
            }
        });
}

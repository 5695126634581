import { useEffect, useRef } from 'react';
import { usageAtom } from '../../stores/datasets';
import { QuiBox, QuiIcon, QuiMenu, QuiMenuItem, QuiMenuItemBox, QuiMenuItemDivider, QuiMenuItems, QuiNavMenuButton } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';

const CHAT_MESSAGE = "Hi. I'd like raise my monthly quota for Tonic Textual.";

export function MonthlyUsage() {
    const usage = useAtomValue(usageAtom);

    const requestUpgradeRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (requestUpgradeRef.current) {
            requestUpgradeRef.current.blur();
        }
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (Object.hasOwn(window, 'Intercom') && typeof window?.Intercom === 'function') {
            window.Intercom('showNewMessage', CHAT_MESSAGE);
        }
    };

    const getCountWithUnits = (count: number | null) => {
        if (count == null) return <span>&nbsp;</span>;
        if (count <= 9999) return count;
        if (count >= 10000 && count <= 999999) return (count / 1000).toFixed(1) + 'K';
        return (count / 1000000).toFixed(1) + 'M';
    };

    return (
        <QuiMenu>
            <QuiNavMenuButton iconLeft={'gift'}>{getCountWithUnits(usage)} words of 100K Used</QuiNavMenuButton>
            <QuiMenuItems>
                <QuiMenuItemBox>
                    <QuiBox display={'flex'} flexDirection={'row'} padding={'md'} gap={'md'} alignItems={'center'}>
                        <QuiIcon icon={'pie-chart'} />
                        <QuiBox display={'flex'} flexDirection={'column'}>
                            <span>Monthly Free Credits</span>
                            <span>{getCountWithUnits(usage)} words of 100K Used</span>
                        </QuiBox>
                    </QuiBox>
                </QuiMenuItemBox>
                <QuiMenuItemDivider />
                <QuiMenuItem ref={requestUpgradeRef} tabIndex={0} iconLeft={'send'} onClick={handleClick}>
                    Request an Upgrade
                </QuiMenuItem>
            </QuiMenuItems>
        </QuiMenu>
    );
}

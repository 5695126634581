import { ReactNode } from 'react';
import styles from './FormSection.module.scss';
import { QuiBox } from '@tonicai/ui-quinine';

type FormSectionProps = {
    title: ReactNode;
    description: ReactNode;
    children: ReactNode;
};

export function FormSection({ title, children, description }: FormSectionProps) {
    return (
        <div className={styles.formSection}>
            <div>
                <QuiBox display="flex" gap="md" alignItems="center">
                    <QuiBox display="flex" flexDirection="column">
                        <QuiBox text="text-lg" weight="medium">
                            {title}
                        </QuiBox>
                        <QuiBox text="text-sm" color="black-600">
                            {description}
                        </QuiBox>
                    </QuiBox>
                </QuiBox>
            </div>

            <div>
                <QuiBox display="flex" flexDirection="column" gap="md">
                    {children}
                </QuiBox>
            </div>
        </div>
    );
}

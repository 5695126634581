import { PageContainer } from '../../components/PageContainer/PageContainer';
import { useIsHostedProd } from '../../stores/settings';
import { ApiKeys } from './ApiKeys';
import { FileUploader } from './FileUploader';
import { HelpDocs } from './HelpDocs';
import { MonthlyCredits } from './MonthlyCredits';
import { Welcome } from './Welcome';
import { PythonSnippets } from './PythonSnippets';
import { datasetsFetchStatusAtom, useDatasets } from '../../stores/datasets';
import { GettingStartedModal } from './GettingStartedModal';
import { useUserAPIKeys } from '../../stores/userAPIKeys';
import { DatasetCard } from '../../components/DatasetCard/DatasetCard';
import styles from './Home.module.scss';
import { useState } from 'react';
import { ApiKeyModal } from '../../components/ApiKeyModal/ApiKeyModal';
import { useDialog } from '../../hooks/useDialog';
import { UploadToNewDatasetModal } from './UploadToNewDatasetModal';
import { useAtomValue } from 'jotai';
import { QuiBox, QuiIcon, QuiSpinner } from '@tonicai/ui-quinine';
import { OnPremCallout } from '../../components/GoOnPrem/OnPremCallout';

export function Home() {
    const datasets = useDatasets();
    const datasetsStatus = useAtomValue(datasetsFetchStatusAtom);
    const apiKeys = useUserAPIKeys();
    const apiKeyModal = useDialog();
    const uploadModal = useDialog();
    const isHostedProd = useIsHostedProd();

    const [hideGettingStartedModal, setHideGettingStartedModal] = useState(false);
    const shouldShowGettingStartedModal =
        datasetsStatus === 'success' && datasets.length === 0 && apiKeys.userAPIKeys.length === 0 && apiKeys.fetchStatus === 'success';
    const showGettingStartedModal = shouldShowGettingStartedModal && hideGettingStartedModal === false;

    return (
        <PageContainer>
            <div className={styles.container}>
                <div>
                    <QuiBox display="flex" flexDirection="column" gap="2xl">
                        <Welcome />
                        {isHostedProd && <OnPremCallout />}
                    </QuiBox>
                </div>
                <div>
                    <QuiBox flexDirection="column" display="flex" gap="md">
                        <QuiBox display="flex" flexDirection="row" gap="md">
                            <MonthlyCredits />
                            <HelpDocs />
                        </QuiBox>

                        <div className={styles.datasetCards}>
                            <FileUploader />
                            {datasetsStatus === 'error' && (
                                <div>
                                    <QuiBox borderRadius="md" border="danger" bg="danger-bg" color="danger-fg" display="flex" gap="sm" padding="sm">
                                        <QuiIcon icon="alert-octagon" color="red-500" />
                                        <QuiBox>Error loading datasets</QuiBox>
                                    </QuiBox>
                                </div>
                            )}
                            {datasetsStatus === 'loading' && (
                                <QuiBox display="flex" gap="sm" padding="md" alignItems="center" justifyContent="center">
                                    <QuiSpinner size="md" />
                                    <QuiBox color="text-secondary">Loading Datasets</QuiBox>
                                </QuiBox>
                            )}
                            {datasets.length > 0 && datasetsStatus === 'success' && datasets.map((d) => <DatasetCard dataset={d} key={d.id} />)}
                        </div>
                    </QuiBox>
                </div>
                <div>
                    <ApiKeys openApiKeyModal={apiKeyModal.openDialog} />
                    <PythonSnippets />
                </div>
            </div>

            <ApiKeyModal isOpen={apiKeyModal.isOpen} onClose={apiKeyModal.closeDialog} />

            <GettingStartedModal
                openUploadModal={uploadModal.openDialog}
                openApiKeyModal={apiKeyModal.openDialog}
                onClose={() => {
                    setHideGettingStartedModal(true);
                }}
                isOpen={showGettingStartedModal}
            />

            <UploadToNewDatasetModal isOpen={uploadModal.isOpen} onClose={uploadModal.closeDialog} />
        </PageContainer>
    );
}

import { atom, useAtomValue } from 'jotai';
import { Dataset, DatasetFormState, FetchStatus, FileSource } from '../types';
import { client } from '../services/HTTPClient';
import { authResponseAtom } from './auth';
import { globalStore } from './globalStore';
import { getLoadingFetchStatus } from '../utils';
import axios from 'axios';
import { instrumentation } from '../instrumentation/instrumentation';
import { createLoadedAtomFromFetchStatusAtom } from './atom-creators';

// constants

export const DATASETS_ENDPOINT = '/api/dataset';

// atoms

export const datasetsAtom = atom<Dataset[]>([]);
export const datasetsFetchStatusAtom = atom<FetchStatus>('init');

export const usageAtom = atom<null | number>(null);

// computed atoms

export const datasetsLoadedAtom = createLoadedAtomFromFetchStatusAtom(datasetsFetchStatusAtom);

// actions

let datasetsGetAbortController = new AbortController();
export async function fetchDatasets() {
    globalStore.set(datasetsFetchStatusAtom, getLoadingFetchStatus(globalStore.get(datasetsFetchStatusAtom)));

    datasetsGetAbortController.abort();
    datasetsGetAbortController = new AbortController();

    await client
        .get<Dataset[]>(DATASETS_ENDPOINT, { signal: datasetsGetAbortController.signal })
        .then(({ data }) => {
            globalStore.set(datasetsAtom, data);
            globalStore.set(datasetsFetchStatusAtom, 'success');
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                globalStore.set(datasetsFetchStatusAtom, 'error');
                throw e;
            }
        })
        .then(() => {
            fetchUsage();
        });
}

export async function fetchDataset(datasetId: string, abortSignal?: AbortSignal) {
    return await client.get<Dataset>(`${DATASETS_ENDPOINT}/${datasetId}`, { signal: abortSignal }).then(({ data }) => {
        globalStore.set(datasetsAtom, (currentDatasets) => [...currentDatasets.filter((d) => d.id !== datasetId), data]);

        return data;
    });
}

export async function createDataset(name: string, fileSource: FileSource = 'Local') {
    return client
        .post<Dataset>(DATASETS_ENDPOINT, { name, fileSource })
        .then(async ({ data }) => {
            instrumentation.createDataset(data.id);
            await fetchDatasets();
            return data.id;
        })
        .then((id) => id);
}

export async function addSampleFile(datasetId: string) {
    return client
        .post<Dataset>(`${DATASETS_ENDPOINT}/add_sample`, { datasetId })
        .then(async ({ data }) => {
            instrumentation.addSampleFile(data.id);
            await fetchDatasets();
            return data.id;
        })
        .then((id) => id);
}

export async function createSampleDataset(name: string) {
    return createDataset(name).then(addSampleFile);
}

export async function updateDataset(dataset: DatasetFormState) {
    // We're not just passing dataset into the body of the request because we have some
    // form state from file uploads that we don't want to update.
    const formData: DatasetFormState = {
        name: dataset.name,
        id: dataset.id,
        generatorSetup: dataset.generatorSetup,
        labelBlockLists: dataset.labelBlockLists,
        enabledModels: dataset.enabledModels,
        datasetGeneratorMetadata: dataset.datasetGeneratorMetadata,
    };

    return client.put(DATASETS_ENDPOINT, formData).then(() => {
        instrumentation.updateDataset(dataset.id);
        return fetchDataset(dataset.id);
    });
}

export async function deleteDataset(id: string) {
    await client
        .delete(`${DATASETS_ENDPOINT}/?datasetId=${id}`)
        .then(fetchDatasets)
        .then(() => {
            instrumentation.deleteDataset(id);
        });
}

export async function uploadFiles(files: File[], datasetId: string, abortSignal?: AbortSignal) {
    return await Promise.all(
        files.map((file) => {
            const metadata = {
                fileName: file.name,
                csvConfig: {},
                datasetId,
            };

            const metadataBlob = new Blob([JSON.stringify(metadata)], {
                type: 'application/json',
            });

            // order matters here, file must be the last item
            const payload = new FormData();
            payload.append('document', metadataBlob);
            payload.append('file', file, file.name);

            return client.post<Dataset>(`${DATASETS_ENDPOINT}/${datasetId}/files/upload`, payload, { signal: abortSignal });
        })
    )
        .then(() => {
            return fetchDataset(datasetId, abortSignal);
        })
        .catch((e) => {
            if (!axios.isCancel(e)) {
                throw e;
            }
        });
}

export function getDownloadFileDataUrl(fileId: string, datasetId: string) {
    const authResponse = globalStore.get(authResponseAtom);

    let url = `${DATASETS_ENDPOINT}/${datasetId}/files/${fileId}/download`;
    if (authResponse) {
        url += `?access_token=${authResponse.jwt}`;
    }

    return url;
}

export function getDownloadAllDatasetFilesUrl(datasetId?: string) {
    const authResponse = globalStore.get(authResponseAtom);

    let url = `${DATASETS_ENDPOINT}/${datasetId}/files/download_all`;
    if (authResponse) {
        url += `?access_token=${authResponse.jwt}`;
    }
    return url;
}

export async function deleteDatasetFile(datasetId: string, fileId: string) {
    await client.delete(`${DATASETS_ENDPOINT}/${datasetId}/files/${fileId}`).then(() => {
        fetchDatasets();
    });
}

// Hooks

export function useDatasets() {
    return useAtomValue(datasetsAtom);
}

export function useDataset(id: string | null | undefined) {
    const datasets = useAtomValue(datasetsAtom);

    if (typeof id !== 'string') return null;

    return datasets.find((d) => d.id === id) ?? null;
}

export function useDatasetFile(datasetId: string, fileId: string) {
    const dataset = useDataset(datasetId);
    return dataset?.files.find((f) => f.fileId === fileId) ?? null;
}

export async function fetchUsage() {
    try {
        const { data } = await client.get<number>('/api/users/usage');
        globalStore.set(usageAtom, data);
    } catch (e) {
        console.error(e);
    }
}

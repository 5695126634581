import { Dataset, DatasetFile } from '../../types';
import { FileDetails } from './FileDetails';
import { FileIcon } from './FileIcon';
import { getFileNameFromUrl, getFileStatus } from '../../utils';
import { QuiBox } from '@tonicai/ui-quinine';
import { FileAdderFileItemMenu } from './FileAdderFileItemMenu';

type FileAdderFileRowProps = Readonly<{
    file: DatasetFile;
    dataset: Dataset;
}>;

export function FileAdderFileItem({ file, dataset }: FileAdderFileRowProps) {
    const fileStatus = getFileStatus(file.processingStatus);

    return (
        <QuiBox
            bg="white"
            borderRadius="md"
            border="white-600"
            padding={'sm'}
            display={'flex'}
            flexDirection={'row'}
            gap={'xs'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <QuiBox display="flex" flexGrow="1" alignItems="center" gap="sm" overflow="hidden">
                <div>
                    <FileIcon status={fileStatus} />
                </div>
                <QuiBox display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
                    <QuiBox text="mono-text-sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        {file.fileSource == 'Local' ? file.fileName : `${getFileNameFromUrl(file.fileName)} (${file.fileSource})`}
                    </QuiBox>
                    <QuiBox color="black-600" text="text-xs" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        <FileDetails processingError={file.processingError} status={fileStatus} numRows={file.numRows} wordCount={file.wordCount} />
                    </QuiBox>
                </QuiBox>
            </QuiBox>
            <QuiBox display="flex" gap="sm" flexShrink="0" alignItems="center">
                <FileAdderFileItemMenu dataset={dataset} file={file} fileStatus={fileStatus} />
            </QuiBox>
        </QuiBox>
    );
}

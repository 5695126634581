import { useIsHostedProd } from '../../stores/settings';
import {
    QuiIconEnum,
    QuiMenu,
    QuiMenuItem,
    QuiMenuItemBox,
    QuiMenuItems,
    QuiNavButton,
    QuiNavRight,
    QuiNav,
    QuiNavAvatarMenuButton,
    TonicTextualIcon,
    QuiBox,
} from '@tonicai/ui-quinine';
import { currentUserAtom, signOut } from '../../stores/auth';
import { useAtomValue } from 'jotai';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { MonthlyUsage } from './MonthlyUsage';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';

export function Navigation() {
    const isHostedProd = useIsHostedProd();
    const currentUser = useAtomValue(currentUserAtom);
    const showSystemSettings = !isHostedProd || currentUser?.userName?.endsWith('@tonic.ai');

    return (
        <QuiNav fluid variant="purple">
            <TonicTextualIcon height="1rem" />
            <QuiNavButton iconLeft="home" to={`/`}>
                Home
            </QuiNavButton>
            <QuiNavButton iconLeft="database" to={`/datasets`}>
                Datasets
            </QuiNavButton>
            <QuiNavButton iconLeft="grid" to={`/models`}>
                Models
            </QuiNavButton>
            <QuiNavButton iconLeft="target" to={`/playground`}>
                Playground
            </QuiNavButton>

            <QuiNavRight>
                <QuiNavButton
                    className={'qui-inset-none-sm'}
                    to={TEXTUAL_DOCUMENTATION_URL}
                    external={true}
                    iconRight={QuiIconEnum.ExternalLink}
                    style={{ width: 'fit-content' }}
                >
                    View Documentation
                </QuiNavButton>
                <QuiNavButton iconLeft="key" to="/user-api-keys">
                    User API Keys
                </QuiNavButton>
                {isHostedProd && <MonthlyUsage />}
                <QuiMenu placement="bottom-end">
                    <QuiNavAvatarMenuButton imageUrl={currentUser?.photoURL ?? undefined} />
                    <QuiMenuItems>
                        <QuiMenuItemBox>
                            <QuiBox padding="sm" display="flex" alignItems="center" gap="sm">
                                <CurrentUserAvatar />
                                <div>{currentUser?.displayName}</div>
                            </QuiBox>
                        </QuiMenuItemBox>
                        {showSystemSettings && (
                            <QuiMenuItem iconLeft="settings" to="/system-settings">
                                Sytem Settings
                            </QuiMenuItem>
                        )}
                        <QuiMenuItem iconLeft="log-out" onClick={signOut}>
                            Sign Out
                        </QuiMenuItem>
                    </QuiMenuItems>
                </QuiMenu>
            </QuiNavRight>
        </QuiNav>
    );
}

import { useCurrentUser } from '../../stores/auth';
import styles from './CurrentUserAvatar.module.scss';
import classNames from 'classnames';

type CurrentUserAvatarProps = {
    size?: 'sm';
};

export function CurrentUserAvatar({ size }: CurrentUserAvatarProps) {
    const currentUser = useCurrentUser();
    const className = classNames(styles.currentUserAvatar, {
        [styles.small]: size === 'sm',
    });

    const style: React.CSSProperties = {};

    if (currentUser?.photoURL) {
        style.backgroundImage = `url(${currentUser.photoURL})`;
    }

    return <span className={className} style={style} />;
}

import { QuiBox, QuiLink } from '@tonicai/ui-quinine';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';

export function HelpDocs() {
    return (
        <QuiBox borderBottom="white-600" borderRadius="md" flexGrow="1" bg="white" display={'flex'} flexDirection={'column'} padding={'lg'}>
            <QuiBox>🛟 Help Docs</QuiBox>
            <QuiBox>
                Learn more about Tonic Textual&nbsp;
                <QuiLink variant="currentcolor" external={true} to={TEXTUAL_DOCUMENTATION_URL}>
                    View Textual Help
                </QuiLink>
            </QuiBox>
        </QuiBox>
    );
}

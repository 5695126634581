import { QuiBadge, QuiBox, QuiButton, QuiLink, QuiTooltip } from '@tonicai/ui-quinine';
import { CreateDatasetModal } from './CreateDatasetModal';
import { useState } from 'react';
import { createSampleDataset, datasetsAtom } from '../../stores/datasets';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';

export function Welcome() {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const datasets = useAtomValue(datasetsAtom);
    const navigate = useNavigate();
    const alreadyCreatedSampleDataset = datasets.find((d) => d.name === 'sample_dataset') !== undefined;

    return (
        <QuiBox display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'} gap={'lg'}>
            <QuiBadge variant="brand-purple" iconLeft="home" />

            <QuiBox text="display-sm">👋 Welcome!</QuiBox>
            <QuiBox text="text-md" color="black-600">
                <p>Textual detects sensitive values such as names and locations. It then redacts those values replacing them with generic values.</p>
                <QuiTooltip content={'Tonic Textual supports plain text, .csv. PDF, and .docx files'}>
                    <QuiLink
                        variant="currentcolor"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault();
                        }}
                    >
                        Supported file types
                    </QuiLink>
                </QuiTooltip>
            </QuiBox>
            <QuiBox display="flex" flexDirection="column" gap="md">
                <QuiButton size="lg" variant="brand-purple" onClick={() => setIsCreateDialogOpen(true)}>
                    Create a Dataset
                </QuiButton>

                <QuiButton
                    iconRight={'arrow-right'}
                    onClick={() => {
                        createSampleDataset('sample_dataset').then((id) => {
                            navigate(`/datasets/${id}`);
                        });
                    }}
                    disabled={alreadyCreatedSampleDataset}
                    type="button"
                >
                    Try Demo Dataset
                </QuiButton>
            </QuiBox>
            <CreateDatasetModal isOpen={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)} />
        </QuiBox>
    );
}

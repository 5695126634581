import { SupportedLanguage } from './types';
import { SyntaxHighlighter } from './SyntaxHighlighter';
import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

type CodeBlockProps = {
    code: string;
    language: SupportedLanguage;
    title: string;
};

export function CodeBlock({ code, language, title }: CodeBlockProps) {
    const copyToClipboard = useCopyToClipboard();

    return (
        <QuiBox bg="white" border="white-600" borderRadius="md">
            <QuiBox borderBottom="white-600" padding="sm" text="mono-text-xs" display={'flex'} justifyContent={'space-between'}>
                {title}
                <QuiButton iconLeft={'copy'} size={'xs'} variant={'minimal'} onClick={() => copyToClipboard(code)}>
                    Copy Code
                </QuiButton>
            </QuiBox>
            <SyntaxHighlighter code={code} language={language} />
        </QuiBox>
    );
}

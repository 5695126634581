import { QuiBox, QuiCheckboxField } from '@tonicai/ui-quinine';
import { useEffect } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { IMetadataProps, PiiTypeEnum } from '../../types';

export function LocationGeneratorMetadataField({ name, piiType }: IMetadataProps) {
    const realisticSyntheticValues = useField(`${name}.realisticSyntheticValues`, { subscription: { value: true } });
    const replaceZipCodesWithZeros = useField(`${name}.replaceTruncatedZerosInZipCode`, { subscription: { value: true } });

    const form = useForm();
    const formState = useFormState({ subscription: { submitting: true } });

    useEffect(() => {
        form.submit();
    }, [realisticSyntheticValues.input.value, replaceZipCodesWithZeros.input.value, form]);

    const showZipOption = piiType == PiiTypeEnum.LOCATION_ZIP || piiType == PiiTypeEnum.LOCATION_ADDRESS || piiType == PiiTypeEnum.LOCATION;

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox borderRadius="md" border="white-600" text="text-xs">
                <QuiBox display="flex" flexDirection="column" borderRadius="md" padding={'md'} gap={'md'}>
                    <QuiCheckboxField
                        name={`${name}.realisticSyntheticValues`}
                        label={'Replace with realistic values'}
                        helperText={
                            'When enabled, locations are replaced with realistic synthetic counterparts.  Otherwise locations are randomly scrambled.'
                        }
                        disabled={formState.submitting}
                    />

                    {showZipOption && (
                        <QuiCheckboxField
                            name={`${name}.replaceTruncatedZerosInZipCode`}
                            label={'Replace zeroes for zip codes'}
                            helperText={'When enabled, the last two digits of a truncated zip are replaced with digits to create a valid zip code.'}
                            disabled={formState.submitting}
                        />
                    )}
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

import { useCallback, useMemo, useState } from 'react';

export function usePagination(initialTotalPages: number) {
    const [pageNumber, setPageNumber] = useState(0);

    const [totalPages, setTotalPages] = useState(initialTotalPages);

    const hasPreviousPage = useMemo(() => {
        return pageNumber > 0;
    }, [pageNumber]);

    const hasNextPage = useMemo(() => {
        return pageNumber < totalPages - 1;
    }, [pageNumber, totalPages]);

    const nextPage = useCallback(() => {
        if (!hasNextPage) return;
        setPageNumber((currentPageNumber) => currentPageNumber++);
    }, [hasNextPage]);

    const previousPage = useCallback(() => {
        if (!hasPreviousPage) return;
        setPageNumber((currentPageNumber) => currentPageNumber--);
    }, [hasPreviousPage]);

    return {
        pageNumber,
        hasPreviousPage,
        hasNextPage,
        nextPage,
        previousPage,
        totalPages,
        setTotalPages,
    };
}

import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiPopover, QuiSegmentedControlButton, QuiSegmentedControlField } from '@tonicai/ui-quinine';
import { useField } from 'react-final-form';
import { modelsPiiTypeInfoAtom } from '../../stores/models';
import { Dataset, IMetadataProps, PiiTypeEnum } from '../../types';
import { useState } from 'react';
import { BlockListField } from './BlockListField';
import { DateTimeGeneratorMetadataField } from './DateTimeGeneratorMetadataField';
import { LocationGeneratorMetadataField } from './LocationGeneratorMetadataField';
import { NameGeneratorMetadataField } from './NameGeneratorMetadataField';
import { RedactionTypeExamples } from './RedactionTypeExamples';
import { getPiiTypeCount, getPiiTypeExamples, getPiiTypeInfo } from './utils';
import { useAtomValue } from 'jotai';

interface IProps {
    piiType: string;
    dataset: Dataset;
}

const optionsMap: Map<string, (props: IMetadataProps) => JSX.Element> = new Map([
    [PiiTypeEnum.DATE_TIME, (props: IMetadataProps) => <DateTimeGeneratorMetadataField {...props} />],
    [PiiTypeEnum.LOCATION, (props: IMetadataProps) => <LocationGeneratorMetadataField {...props} />],
    [PiiTypeEnum.LOCATION_ADDRESS, (props: IMetadataProps) => <LocationGeneratorMetadataField {...props} />],
    [PiiTypeEnum.LOCATION_ZIP, (props: IMetadataProps) => <LocationGeneratorMetadataField {...props} />],
    [PiiTypeEnum.LOCATION_STATE, (props: IMetadataProps) => <LocationGeneratorMetadataField {...props} />],
    [PiiTypeEnum.LOCATION_CITY, (props: IMetadataProps) => <LocationGeneratorMetadataField {...props} />],
    [PiiTypeEnum.PERSON, (props: IMetadataProps) => <NameGeneratorMetadataField {...props} />],
    [PiiTypeEnum.NAME_GIVEN, (props: IMetadataProps) => <NameGeneratorMetadataField {...props} />],
    [PiiTypeEnum.NAME_FAMILY, (props: IMetadataProps) => <NameGeneratorMetadataField {...props} />],
]);

export function RedactionTypeOptions({ piiType, dataset }: IProps) {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [isPreviewPopoverOpen, setIsPreviewPopoverOpen] = useState<boolean>(false);
    const modelsPiiTypeInfo = useAtomValue(modelsPiiTypeInfoAtom);

    const factory = optionsMap.get(piiType) ?? (() => null);

    const {
        input: { value: generatorSetupValue },
    } = useField(`generatorSetup.${piiType}`);

    return (
        <QuiBox key={piiType} gap="sm" flexDirection="column" display="flex" bg="white" border="white-600" borderRadius="md" padding="md">
            <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <QuiIcon icon={getPiiTypeInfo(piiType, modelsPiiTypeInfo)?.icon ?? 'help-circle'} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className={'pii-type-label'}>{getPiiTypeInfo(piiType, modelsPiiTypeInfo)?.label}</span>
                        <span className={'pii-type-description'}>{getPiiTypeInfo(piiType, modelsPiiTypeInfo)?.description}</span>
                    </div>
                </div>

                <QuiBox display="flex" gap="sm" alignItems="center">
                    <BlockListField name={`labelBlockLists.${piiType}`} />
                    <QuiBox display="flex" gap="sm" alignItems="center">
                        <QuiPopover
                            isOpen={isPreviewPopoverOpen}
                            onClose={() => setIsPreviewPopoverOpen(false)}
                            hideArrow={true}
                            placement={'bottom-start'}
                            content={
                                <RedactionTypeExamples
                                    examples={getPiiTypeExamples(dataset, piiType)}
                                    piiType={piiType}
                                    totalExamples={getPiiTypeCount(dataset, piiType)}
                                />
                            }
                        >
                            <QuiButton
                                iconRight={isPreviewPopoverOpen ? QuiIconEnum.X : QuiIconEnum.Eye}
                                size={'sm'}
                                variant={isPreviewPopoverOpen ? 'outline-blue' : 'outline'}
                                onClick={() => (isPreviewPopoverOpen ? setIsPreviewPopoverOpen(false) : setIsPreviewPopoverOpen(true))}
                            >
                                {getPiiTypeCount(dataset, piiType)}
                            </QuiButton>
                        </QuiPopover>
                        {optionsMap.has(piiType) && generatorSetupValue === 'Synthesis' && (
                            <QuiButton
                                onClick={() => setShowOptions(!showOptions)}
                                variant={showOptions ? 'primary' : 'outline'}
                                size={'sm'}
                                type={'button'}
                                iconRight={showOptions ? 'chevron-up' : 'chevron-down'}
                            >
                                Options
                            </QuiButton>
                        )}
                        <QuiSegmentedControlField size="sm" name={`generatorSetup.${piiType}`}>
                            <QuiSegmentedControlButton value="Synthesis">Synthesis</QuiSegmentedControlButton>
                            <QuiSegmentedControlButton value="Redaction">Redaction</QuiSegmentedControlButton>
                            <QuiSegmentedControlButton value="Off">Off</QuiSegmentedControlButton>
                        </QuiSegmentedControlField>
                    </QuiBox>
                </QuiBox>
            </QuiBox>
            {showOptions && generatorSetupValue == 'Synthesis' && factory({ name: `datasetGeneratorMetadata.${piiType}`, piiType })}
        </QuiBox>
    );
}

import { QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems } from '@tonicai/ui-quinine';

const menuItems: Record<string, string> = {
    'Fraud Detection': `<p>It has come to our attention that the IP address 192.168.123.132 has breached our firewall. It appears that the machine connected to this address is located in Northern Ireland and is registered to a man named Luke McFee. While the attack came from outside the country, Mr. McFee is a US citizen, passport number 526830364. During the attack he accessed information related to 5901 of our customers, 21% overall. This is a clear violation of The Computer Fraud and Abuse Act.</p>`,
    'Customer Call': `<p>Hi, I'm calling about a charge on my credit card that I didn't make. It was made on the 14th at HomeDepot for 156 dollars. My name is Chris Kraus, my account number is 4562963275264138 and my social security number is 425222635. Thank you for calling Chase. I see the charge here, it is flagged for our fraud team to review. Is this a good phone number to reach you at? No, but you can call me at (450) 536-5323. Got it.</p>`,
    'Travel Agent': `<p>I am writing to request travel arrangements to the upcoming AI Summit conference in London to represent Tonic. I need to be traveling June 13th as the conference is from the 14th to the 15th. More information on the conference can be found at https://london.theaisummit.com/. I'd like to spend a couple more days there visiting my cousin for her birthday so would like to return on June 20th. Our budget for the event is limited so ideally the ticket shouldn't exceed $600. My passport number is 582968204. Please call me at (283) 472-5823 ext 67 if you have any questions. </p>`,
};

type SampleMenuProps = Readonly<{
    setEditorContent: (content: string) => void;
}>;

export function SampleMenu({ setEditorContent }: SampleMenuProps) {
    return (
        <QuiMenu placement="bottom-start">
            <QuiMenuButton size="sm" iconRight="chevron-down">
                Try a Sample
            </QuiMenuButton>
            <QuiMenuItems>
                {Object.entries(menuItems).map(([label, content]) => (
                    <QuiMenuItem
                        key={label}
                        onClick={() => {
                            setEditorContent(content);
                        }}
                    >
                        {label}
                    </QuiMenuItem>
                ))}
            </QuiMenuItems>
        </QuiMenu>
    );
}

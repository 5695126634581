import { modelsPiiTypeInfoAtom } from '../../stores/models';
import { PiiTextExample } from '../../types';
import { QuiBox, QuiText } from '@tonicai/ui-quinine';
import { getPiiTypeInfo } from './utils';
import { useAtomValue } from 'jotai';
import { RedactionTypeExample } from './RedactionTypeExample';

type RedactionTypeExamplesProps = Readonly<{
    piiType: string;
    examples: PiiTextExample[];
    totalExamples: number;
}>;
export function RedactionTypeExamples({ examples, piiType, totalExamples }: RedactionTypeExamplesProps) {
    const modelsPiiTypeInfo = useAtomValue(modelsPiiTypeInfoAtom);

    const headerText = examples.length
        ? `Preview ${examples.length} of ${totalExamples} ${getPiiTypeInfo(piiType, modelsPiiTypeInfo)?.description.toLowerCase()}`
        : `No previews of the ${getPiiTypeInfo(piiType, modelsPiiTypeInfo)?.description.toLowerCase()} found`;

    return (
        <QuiBox padding={'sm'} gap={'xs'} display={'flex'} flexDirection={'column'}>
            <QuiBox color={'brand-black-600'} className={examples.length ? 'qui-border-bottom-white-500' : ''} padding={'xs'}>
                <QuiText size={'text-xs'}> {headerText}</QuiText>
            </QuiBox>
            {examples.map((example, i) => (
                <RedactionTypeExample piiTextExample={example} key={`${piiType}-example-${i}`} />
            ))}
        </QuiBox>
    );
}

import { QuiBox, QuiCheckboxField, QuiNumberField, QuiSelectField } from '@tonicai/ui-quinine';
import { IMetadataProps, ModelTransformation } from '../../types';
import { useForm, useFormState, useField } from 'react-final-form';
import { useEffect } from 'react';
import { AdditionalDateFormatField } from './AdditionalDateFormatField';

function parseNumber(inputValue: string) {
    const validCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const justNumbers =
        inputValue
            .split('')
            .filter((char) => validCharacters.includes(char))
            .join('') ?? '0';

    const parsed = parseInt(justNumbers, 10);
    if (isNaN(parsed)) {
        return 0;
    } else {
        return parsed;
    }
}

function formatNumber(inputValue: number) {
    return inputValue.toString();
}

const numberConfig = {
    parse: parseNumber,
    format: formatNumber,
};

export function DateTimeGeneratorMetadataField({ name }: IMetadataProps) {
    const form = useForm();

    const scrambleUnrecognizedDates = useField(`${name}.scrambleUnrecognizedDates`, { subscription: { value: true } });

    const formState = useFormState({ subscription: { submitting: true } });

    useEffect(() => {
        form.submit();
    }, [scrambleUnrecognizedDates.input.value, form]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox borderRadius="md" border="white-600" text="text-xs">
                <QuiBox display="flex" flexDirection="column" borderRadius="md" padding={'md'} gap={'md'}>
                    <QuiSelectField
                        onBlur={() => {
                            form.submit();
                        }}
                        isClearable={false}
                        name={`${name}.dateTimeTransformation`}
                        label="Transformation"
                        placeholder=""
                        options={[
                            {
                                value: ModelTransformation.TimestampShift,
                                label: 'Timestamp Shift',
                            },
                        ]}
                    />
                    <QuiNumberField
                        config={numberConfig}
                        onBlur={() => {
                            form.submit();
                        }}
                        label="# of Days To shift +/-"
                        name={`${name}.metadata.timestampShiftInDays`}
                    />
                    <QuiCheckboxField
                        name={`${name}.scrambleUnrecognizedDates`}
                        label={'Scramble Unrecognized Dates'}
                        helperText={
                            'When enabled, dates are randomly scrambled if they are not parseable.  Otherwise, they will just be passed through'
                        }
                        disabled={formState.submitting}
                    />
                    <AdditionalDateFormatField name={`${name}.additionalDateFormats`} />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

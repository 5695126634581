import { QuiBox, QuiFlexV, TonicTextualHorizontal } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import styles from './Auth.module.scss';

type AuthProps = Readonly<{
    children: ReactNode;
}>;

export function Auth({ children }: AuthProps) {
    return (
        <div className={styles.auth}>
            <QuiBox display="flex" alignItems="center" justifyContent="center" padding="lg" gap="sm" text="display-xs">
                <TonicTextualHorizontal className={styles.logo} />
            </QuiBox>
            <QuiBox bg="white" padding="md" borderRadius="lg" border="white-600">
                <QuiFlexV gap="md" padding="md">
                    {children}
                </QuiFlexV>
            </QuiBox>
        </div>
    );
}

import { useFileBlob } from '../../hooks/useFileBlob';
import { useFilePreview } from '../../hooks/useFilePreview';
import { Dataset, DatasetFile } from '../../types';
import { TextFilePreviewTable } from './TextFilePreviewTable';
import { QuiBox, QuiButton, QuiModalContent } from '@tonicai/ui-quinine';
import styles from './FilePreviewDialog.module.scss';

type FilePreviewDialogProps = Readonly<{
    file: DatasetFile;
    dataset: Dataset;
}>;

export function FilePreviewDialog({ file, dataset }: FilePreviewDialogProps) {
    const { originalDataStatus, originalHtml, deidentifiedHtml, nextPage, previousPage, fileName, hasNextPage, hasPreviousPage, fileType } =
        useFilePreview(file.fileId, dataset);

    const { pdfBlob } = useFileBlob(dataset.id, file.fileId);

    return (
        <QuiModalContent className={'fs-exclude'}>
            {originalDataStatus === 'success' && (
                <>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            {originalHtml && (
                                <TextFilePreviewTable fileName={fileName} filePreviewHtml={pdfBlob ?? originalHtml} fileType={fileType} />
                            )}
                        </div>
                        <div className={styles.column}>
                            {deidentifiedHtml && (
                                <TextFilePreviewTable fileName={fileName} filePreviewHtml={pdfBlob ?? deidentifiedHtml} fileType={fileType} />
                            )}
                        </div>
                    </div>
                    {(hasNextPage || hasPreviousPage) && (
                        <QuiBox padding="md" display="flex" justifyContent="center" gap={'md'}>
                            {hasPreviousPage && (
                                <QuiButton onClick={previousPage} iconLeft={'arrow-up'}>
                                    Previous
                                </QuiButton>
                            )}
                            {hasNextPage && (
                                <QuiButton onClick={nextPage} iconLeft={'arrow-down'}>
                                    Next
                                </QuiButton>
                            )}
                        </QuiBox>
                    )}
                </>
            )}
        </QuiModalContent>
    );
}

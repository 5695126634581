import { QuiText } from '@tonicai/ui-quinine';
import { PageContainer } from '../../components/PageContainer/PageContainer';

export function NotFound() {
    return (
        <PageContainer>
            <QuiText size="text-xl" weight="bold">
                Page Not Found
            </QuiText>
        </PageContainer>
    );
}

import { QuiBox, QuiButton, QuiLink, QuiModalContent, QuiModalDialog, QuiModalFooter } from '@tonicai/ui-quinine';
import { UploadFiles } from '../../components/UploadFiles/UploadFiles';
import { createDataset, createSampleDataset, uploadFiles } from '../../stores/datasets';
import { useNewDatasetName } from '../../hooks/useNewDatasetName';
import styles from './UploadToNewDatasetModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';

type UploadToNewDatasetModalProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
}>;

export function UploadToNewDatasetModal({ isOpen, onClose }: UploadToNewDatasetModalProps) {
    const newDatasetName = useNewDatasetName();
    const navigate = useNavigate();

    return (
        <QuiModalDialog className={styles.modal} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" flexDirection="column" gap="md">
                    <QuiBox text="text-md" weight="medium">
                        Upload files to redact
                    </QuiBox>
                    <QuiBox text="text-sm" color="black-600">
                        To create a new dataset, upload unstructured text files. You can also use our sample dataset to get started.
                    </QuiBox>
                    <UploadFiles
                        onFilesSelected={async (files) => {
                            createDataset(newDatasetName).then((id) => {
                                return uploadFiles(files, id).then(() => {
                                    navigate(`/datasets/${id}`);
                                });
                            });
                        }}
                    />
                </QuiBox>
            </QuiModalContent>
            <QuiModalFooter className={styles.footer}>
                <QuiBox className={styles.modal} display="flex" flexDirection="column" gap="md">
                    <QuiBox>
                        <QuiBox text="text-sm">Here's a tip...</QuiBox>
                        <QuiBox text="text-sm">
                            Some files work better than others. Unstructured free-text files, such as patient notes or customer call logs, work best.
                        </QuiBox>
                        <QuiLink variant="currentcolor" to={TEXTUAL_DOCUMENTATION_URL} external iconRight="external-link">
                            View Documentation
                        </QuiLink>
                    </QuiBox>
                    <QuiBox>
                        <QuiButton
                            onClick={() => {
                                createSampleDataset(newDatasetName).then((id) => {
                                    navigate(`/datasets/${id}`);
                                });
                            }}
                            type="button"
                            iconRight="arrow-right"
                        >
                            Try a sample
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            </QuiModalFooter>
        </QuiModalDialog>
    );
}

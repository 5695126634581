import { Outlet } from 'react-router-dom';
import { Navigation } from '../Navigation/Navigation';
import { fetchDatasets, fetchUsage } from '../../stores/datasets';
import { useEffect, useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { Message } from '../Message/Message';

export function AppLayout() {
    const [loadedInitialData, setLoadedInitialData] = useState(false);
    const [error, setError] = useState(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        Promise.all([fetchUsage(), fetchDatasets()])
            .then(() => {
                if (isMounted()) {
                    setLoadedInitialData(true);
                    setError(false);
                }
            })
            .catch(() => {
                setError(true);
            });
    }, [isMounted]);

    return (
        <>
            <Navigation />
            <main>
                {loadedInitialData && error && (
                    <QuiBox justifyContent="center" padding="lg" display="flex">
                        <Message variant="error">
                            <QuiBox text="text-sm">Something unexpected happened. Please try refreshing the page.</QuiBox>
                        </Message>
                    </QuiBox>
                )}
                {!error &&
                    (loadedInitialData ? (
                        <Outlet />
                    ) : (
                        <QuiBox justifyContent="center" padding="lg" display="flex">
                            <QuiSpinner />
                        </QuiBox>
                    ))}
            </main>
        </>
    );
}

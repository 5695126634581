import { QuiBadge, QuiBox, QuiText } from '@tonicai/ui-quinine';
import { Editor } from './Editor';

export function Playground() {
    return (
        <QuiBox display="flex" justifyContent="center">
            <QuiBox maxWidth="wide" flexGrow="1" padding="md" display="flex" flexDirection="column" gap="lg">
                <QuiBox display="flex" flexDirection="column" gap="lg">
                    <QuiBox display="flex" gap="sm" alignItems="center">
                        <QuiBadge variant="brand-purple" iconLeft="target" />
                        <QuiBox text="display-md" weight="bold">
                            Playground
                        </QuiBox>
                    </QuiBox>
                    <QuiBox color="text-secondary" maxWidth="tablet" display="flex" gap="lg" flexDirection="column">
                        <QuiText as="p">
                            Test-drive our AI-powered redaction capabilities by typing text into the box below. On the right, you'll see the results
                            of what we detected.
                        </QuiText>
                    </QuiBox>
                </QuiBox>
                <Editor />
            </QuiBox>
        </QuiBox>
    );
}

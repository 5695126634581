import { QuiText, useQuiToasts } from '@tonicai/ui-quinine';
import { useNavigate } from 'react-router-dom';
import { UploadFiles } from '../../components/UploadFiles/UploadFiles';
import { instrumentation } from '../../instrumentation/instrumentation';
import { createDataset, uploadFiles } from '../../stores/datasets';
import { useCallback } from 'react';
import { UNSUPPORTED_FILE_EXTENSIONS } from '../../utils';
import { isAxiosError } from 'axios';
import { useNewDatasetName } from '../../hooks/useNewDatasetName';

export function FileUploader() {
    const addToast = useQuiToasts();
    const navigate = useNavigate();
    const newDatasetName = useNewDatasetName();

    const removeNonTextFiles = useCallback(
        (files: File[]) => {
            const { textFiles, nonTextFiles } = files.reduce(
                (acc, file) => {
                    const fileExtension = file.name.split('.').pop()?.toLowerCase();

                    if (UNSUPPORTED_FILE_EXTENSIONS.has(fileExtension ?? '')) {
                        acc.nonTextFiles.push(file);
                    } else {
                        acc.textFiles.push(file);
                    }
                    return acc;
                },
                { textFiles: [] as File[], nonTextFiles: [] as File[] }
            );

            if (nonTextFiles.length > 0) {
                instrumentation.uploadNonTextFiles(nonTextFiles.map((f) => f.name));

                const toastTitleMessage =
                    nonTextFiles.length === 1
                        ? `"${nonTextFiles[0].name}" is not a text file`
                        : 'The following files are not text files: ' +
                          nonTextFiles
                              .map(function (f) {
                                  return `"${f.name}"`;
                              })
                              .join(', ');

                addToast({
                    title: `${toastTitleMessage}. Tonic Textual only supports text files, text delimited files, and Microsoft Word documents`,
                    variant: 'destructive',
                    timeout: 10000,
                });
            }

            return textFiles;
        },
        [addToast]
    );

    const onFilesSelected = useCallback(
        async (files: File[]) => {
            const textFiles = removeNonTextFiles(files);

            const datasetId = await createDataset(newDatasetName);
            await uploadFiles(textFiles, datasetId)
                .catch((error: unknown) => {
                    if (isAxiosError(error) && error.response?.status === 400) {
                        addToast({
                            variant: 'destructive',
                            title: error.response.data,
                            timeout: 10000,
                        });
                    } else if (isAxiosError(error) && error.response?.status === 409) {
                        addToast({
                            variant: 'destructive',
                            title: 'There is already a file with that name in a dataset',
                            timeout: 10000,
                        });
                    }

                    return [];
                })
                .then(() => {
                    navigate(`/datasets/${datasetId}`);
                });
        },
        [addToast, removeNonTextFiles, navigate, newDatasetName]
    );

    return (
        <div>
            <UploadFiles className={'upload-files-btn'} text={'Upload Files to Create a Dataset'} onFilesSelected={onFilesSelected} />
            <QuiText as="span" size="text-xs" color="text-secondary">
                Supports plain text, .csv. PDF, and .docx files
            </QuiText>
        </div>
    );
}

import { useState } from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useIsMounted } from '../../hooks/useIsMounted';
import { createUserAPIKey } from '../../stores/userAPIKeys';
import { QuiBox, QuiButton, QuiSubmitButton, QuiText, QuiTextField, required } from '@tonicai/ui-quinine';
import { Form } from 'react-final-form';
import { UserApiKey } from '../../types';
import { FORM_ERROR } from 'final-form';
import { Message } from '../../components/Message/Message';
import { instrumentation } from '../../instrumentation/instrumentation';

export function UserAPIKeyForm() {
    const [newKey, setNewKey] = useState<UserApiKey | null>(null);
    const isMounted = useIsMounted();
    const copyToClipboard = useCopyToClipboard();

    if (newKey) {
        return (
            <QuiBox flexDirection="column" display="flex" gap="md">
                <Message variant="success">
                    <QuiText size="text-xs">
                        API Key{' '}
                        <QuiText size="text-xs" as="span" weight="bold">
                            {newKey.name}
                        </QuiText>{' '}
                        created successfully!
                    </QuiText>
                    <QuiBox text="text-xs">This is the only time you'll be able to see this key, so keep a copy of it somewhere safe.</QuiBox>
                </Message>

                <QuiBox display="flex" alignItems="center" gap="md">
                    <QuiBox flexGrow="1" border="white-600" borderRadius="md" color="black-600" bg="white-300" padding="sm">
                        <QuiText as="code" monospace size="text-xs">
                            {newKey.key}
                        </QuiText>
                    </QuiBox>
                    <QuiButton
                        onClick={() => {
                            copyToClipboard(newKey.key);
                        }}
                        type="button"
                        iconLeft="clipboard"
                    />
                </QuiBox>
            </QuiBox>
        );
    }

    return (
        <Form<{ name: string }>
            initialValues={{ name: '' }}
            onSubmit={async (values) => {
                return await createUserAPIKey(values.name)
                    .then((key) => {
                        instrumentation.createAPIKey(key.id);
                        if (isMounted()) {
                            setNewKey(key);
                        }
                        return undefined;
                    })
                    .catch(() => {
                        return {
                            [FORM_ERROR]: 'Could not create API key.',
                        };
                    });
            }}
        >
            {({ handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                    <QuiBox display="flex" gap="md" flexDirection="column">
                        <QuiBox display="flex" gap="sm" flexDirection="column">
                            <QuiTextField validate={required} autoComplete="off" label="Name" name="name" />
                            <QuiSubmitButton variant={'brand-purple'}>Create API Key</QuiSubmitButton>
                        </QuiBox>

                        {submitError && <Message variant="error">{submitError ?? 'Something went wrong'}</Message>}
                    </QuiBox>
                </form>
            )}
        </Form>
    );
}

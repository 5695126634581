export function isUndefinedNotNull(v: unknown) {
    return v !== null && v === undefined;
}

export function hasStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'string';
}

export function hasOptionalStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'string' || isUndefinedNotNull(r[key]) : true;
}

export function hasNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'number';
}

export function hasOptionalNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'number' || isUndefinedNotNull(r[key]) : true;
}

export function hasBooleanValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'boolean';
}

export function hasOptionalBooleanValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'boolean' || isUndefinedNotNull(r[key]) : true;
}

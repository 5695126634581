import { FileType } from '../../types';
import styles from './TextFilePreviewTable.module.scss';

// These styles are from the generated HTML that is show for text files below, that's why they
// don't use SCSS Modules
import './TextFilePreviewTable.scss';

type TextFilePreviewTableProps = Readonly<{
    filePreviewHtml: string;
    fileName: string;
    fileType: FileType;
}>;

export function TextFilePreviewTable({ filePreviewHtml, fileName, fileType }: TextFilePreviewTableProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>{fileName}</div>
                <div className={styles.content}>
                    {fileType === 'DocX' ? (
                        <iframe
                            referrerPolicy={'no-referrer'}
                            sandbox={''}
                            title={fileName}
                            style={{ width: '100%', height: '100%', pointerEvents: 'none', border: 'none' }}
                            srcDoc={filePreviewHtml}
                        />
                    ) : (
                        <div className={'preview-table'} dangerouslySetInnerHTML={{ __html: filePreviewHtml }} />
                    )}
                </div>
            </div>
        </div>
    );
}

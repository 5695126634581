import { useFieldArray } from 'react-final-form-arrays';
import { QuiBox, QuiButton, QuiTextField } from '@tonicai/ui-quinine';
import { requiredString } from '../../validation';
import { useEffect, useMemo, useRef } from 'react';

type EntityExamplesFieldProps = {
    name: string;
    label: string;
};

export function EntityExamplesField({ name, label }: EntityExamplesFieldProps) {
    const { fields } = useFieldArray<string>(name);

    const focusLastInputRef = useRef(false);
    const numFields = useMemo(() => fields?.length ?? 0, [fields]);

    useEffect(() => {
        if (focusLastInputRef.current && inputRefs.current?.[numFields - 1]) {
            inputRefs.current[numFields - 1].focus();
            focusLastInputRef.current = false;
        }
    }, [numFields]);

    const inputRefs = useRef<HTMLInputElement[]>([]);

    return (
        <QuiBox>
            <QuiBox>
                <QuiBox weight="medium" text="text-sm">
                    {label}
                </QuiBox>

                <QuiBox color="black-600" text="text-xs">
                    Create example values for your entity. Once you've made a few, you can use the "Generate Examples" button to create more using AI.
                </QuiBox>
            </QuiBox>
            <QuiBox gap="md" display="flex" flexDirection="column">
                <QuiBox display="flex" flexDirection="column" gap="sm" borderRadius="md">
                    {fields.map((fieldName, index) => (
                        <QuiBox display="flex" gap="sm" key={fieldName}>
                            <QuiTextField
                                placeholder="Provide an example of your entity"
                                validate={requiredString}
                                name={fieldName}
                                style={{ flexGrow: 1 }}
                                autoComplete="off"
                                ref={(el) => {
                                    if (el) {
                                        inputRefs.current[index] = el;
                                    }
                                }}
                            />
                            <QuiButton
                                variant="minimal"
                                tabIndex={-1}
                                iconLeft="x"
                                type="button"
                                onClick={() => {
                                    if (fields?.length === 1) {
                                        fields.update(index, '');
                                    } else {
                                        fields.remove(index);
                                    }
                                }}
                            />
                        </QuiBox>
                    ))}
                </QuiBox>
                <QuiBox>
                    <QuiButton
                        size="sm"
                        type="button"
                        iconLeft="plus"
                        onClick={() => {
                            fields.push('');
                            focusLastInputRef.current = true;
                        }}
                    >
                        Add Another Example
                    </QuiButton>
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

import { FORM_ERROR } from 'final-form';
import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/FileAdder/FileAdder.module.scss';
import { FormSection } from '../../components/FormSection/FormSection';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { UploadLocalFilesButton } from '../../components/UploadFilesButton/UploadLocalFilesButton';
import {
    createExternalCredential,
    deleteExternalCredential,
    fetchExternalCredentials,
    updateExternalCredential,
} from '../../stores/externalCredentials';
import { ExternalCredentialType } from '../../types';
import { QuiBox, QuiButton, QuiFlexH, QuiPasswordField, QuiSpinner, QuiText, QuiTextField, useQuiToasts } from '@tonicai/ui-quinine';
import { SENSITIVE_FIELD_FILLER } from '../../utils';

type ExternalCredentialsFormState = {
    Loading: boolean;
    Saving?: boolean;
    HasGoogleServiceAccount?: boolean;
    GoogleServiceAccountFileName?: string;
    GoogleServiceAccountFileContents?: string;
    HasOpenApiKey?: boolean;
    OpenAiApiKey?: string;
};

export function SystemSettings() {
    const navigate = useNavigate();
    const [state, onStateChange] = useState<ExternalCredentialsFormState>({ Loading: true });
    const addToast = useQuiToasts();

    useEffect(() => {
        fetchExternalCredentials().then((externalCredentials) => {
            const newState: ExternalCredentialsFormState = { Loading: false };
            const hasOpenAiApiKey = externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.OpenAIKey).length > 0;
            newState.HasOpenApiKey = hasOpenAiApiKey;
            if (hasOpenAiApiKey) {
                const openAiCredential = externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.OpenAIKey)[0];
                newState.OpenAiApiKey = openAiCredential.value;
            }
            const hasGoogleServiceAccount =
                externalCredentials.filter((e) => e.credentialType === ExternalCredentialType.GoogleServiceAccount).length > 0;
            newState.HasGoogleServiceAccount = hasGoogleServiceAccount;
            if (hasGoogleServiceAccount) {
                const googleServiceAccountCredential = externalCredentials.filter(
                    (e) => e.credentialType === ExternalCredentialType.GoogleServiceAccount
                )[0];
                newState.GoogleServiceAccountFileName = googleServiceAccountCredential.name;
                newState.GoogleServiceAccountFileContents = googleServiceAccountCredential.value;
            }
            onStateChange((currentState) => ({ ...currentState, ...newState }));
        });
    }, []);

    const onSubmit = useCallback(
        async (values: ExternalCredentialsFormState) => {
            try {
                onStateChange((currentState) => ({ ...currentState, Saving: true }));
                if (values.OpenAiApiKey && values.OpenAiApiKey !== SENSITIVE_FIELD_FILLER) {
                    if (values.HasOpenApiKey) {
                        await updateExternalCredential(ExternalCredentialType.OpenAIKey, 'OpenApiKey', values.OpenAiApiKey);
                    } else {
                        await createExternalCredential(ExternalCredentialType.OpenAIKey, 'OpenApiKey', values.OpenAiApiKey);
                    }
                } else if (values.HasOpenApiKey && !values.OpenAiApiKey) {
                    await deleteExternalCredential(ExternalCredentialType.OpenAIKey);
                }
                if (
                    values.GoogleServiceAccountFileName &&
                    values.GoogleServiceAccountFileContents &&
                    values.GoogleServiceAccountFileContents !== SENSITIVE_FIELD_FILLER
                ) {
                    if (values.HasGoogleServiceAccount) {
                        await updateExternalCredential(
                            ExternalCredentialType.GoogleServiceAccount,
                            values.GoogleServiceAccountFileName,
                            values.GoogleServiceAccountFileContents
                        );
                    } else {
                        await createExternalCredential(
                            ExternalCredentialType.GoogleServiceAccount,
                            values.GoogleServiceAccountFileName,
                            values.GoogleServiceAccountFileContents
                        );
                    }
                } else if (values.HasGoogleServiceAccount && !values.GoogleServiceAccountFileName) {
                    await deleteExternalCredential(ExternalCredentialType.GoogleServiceAccount);
                }
                navigate('/datasets');
            } catch (e) {
                const title = 'Something unexpected went wrong. Please contact support.';
                addToast({ title, variant: 'destructive' });
                return {
                    [FORM_ERROR]: title,
                };
            } finally {
                onStateChange((currentState) => ({ ...currentState, Saving: false }));
            }
        },
        [navigate, addToast]
    );
    return (
        <PageContainer>
            <QuiBox display="flex" gap="xl" flexDirection="column">
                <QuiBox>
                    <QuiText size="display-xs" weight="medium">
                        System Settings
                    </QuiText>
                </QuiBox>
                <QuiBox>
                    <Form<ExternalCredentialsFormState> onSubmit={onSubmit} initialValues={state}>
                        {({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSection
                                    title="External LLM Credentials"
                                    description="Provide Tonic Textual with the necessary credentials to connect to an LLM during custom model training. Only one is
                                needed."
                                >
                                    {values.Loading ? (
                                        <QuiSpinner />
                                    ) : (
                                        <QuiBox display="flex" flexDirection="column" gap="md">
                                            <QuiPasswordField
                                                name="OpenAiApiKey"
                                                label="OpenAI API Key"
                                                helperText="The API key provided to you by OpenAI"
                                                hideShowPasswordToggle={true}
                                            />
                                            <QuiFlexH align="bottom" gap="sm">
                                                <QuiBox display="flex" flexGrow="1">
                                                    <QuiTextField
                                                        name="GoogleServiceAccountFileName"
                                                        disabled={true}
                                                        label="Google Service Account Json File"
                                                        style={{ width: '100%' }}
                                                    />
                                                </QuiBox>
                                                <QuiButton
                                                    type="button"
                                                    iconLeft="x"
                                                    onClick={() => {
                                                        onStateChange((currentState) => ({
                                                            ...currentState,
                                                            GoogleServiceAccountFileName: undefined,
                                                            GoogleServiceAccountFileContents: undefined,
                                                        }));
                                                    }}
                                                />
                                                <UploadLocalFilesButton
                                                    variant="brand-purple"
                                                    selectionMode="single"
                                                    acceptedFileTypes="application/json"
                                                    disabled={values.Saving || values.Loading}
                                                    className={styles.uploadFilesButton}
                                                    onFilesSelected={(files) => {
                                                        const file = files[0];
                                                        file.text().then((content) => {
                                                            onStateChange((currentState) => ({
                                                                ...currentState,
                                                                GoogleServiceAccountFileName: file.name,
                                                                GoogleServiceAccountFileContents: content,
                                                            }));
                                                        });
                                                    }}
                                                >
                                                    Upload File
                                                </UploadLocalFilesButton>
                                            </QuiFlexH>
                                            <div>
                                                <QuiButton variant="brand-purple" type="submit" spinner={values.Saving}>
                                                    Save
                                                </QuiButton>
                                            </div>
                                        </QuiBox>
                                    )}
                                </FormSection>
                            </form>
                        )}
                    </Form>
                </QuiBox>
            </QuiBox>
        </PageContainer>
    );
}

import { useFieldArray } from 'react-final-form-arrays';
import { generateEntityExamples } from '../../stores/models';
import { QuiBox, QuiButton, useQuiToasts } from '@tonicai/ui-quinine';
import { ModelEntityModel, ModelEntityRequestModel } from '../../types';
import { EntityFields } from './EntityFields';
import { useEffect, useMemo, useRef } from 'react';

const blankField: ModelEntityRequestModel & { generatedExamples: string[]; generatingExamples: boolean } = {
    label: '',
    description: '',
    examples: [''],
    extraInstruction: '',
    generatedExamples: [],
    generatingExamples: false,
};

type EntitiesFieldProps = {
    name: string;
    setFieldValue: (field: string, value: string[] | boolean) => void;
};

export function EntitiesField({ name, setFieldValue }: EntitiesFieldProps) {
    const { fields } = useFieldArray<(ModelEntityRequestModel | ModelEntityModel) & { generatedExamples: string[]; generatingExamples: boolean }>(
        name
    );

    const focusLastInputRef = useRef(false);
    const numFields = useMemo(() => fields?.length ?? 0, [fields]);

    useEffect(() => {
        if (focusLastInputRef.current && inputRefs.current?.[numFields - 1]) {
            inputRefs.current[numFields - 1].focus();
            focusLastInputRef.current = false;
        }
    }, [numFields]);

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const addToast = useQuiToasts();

    return (
        <QuiBox display="flex" gap="md" flexDirection="column">
            {fields.map((fieldName, index) => (
                <EntityFields
                    ref={(el) => {
                        if (el) {
                            inputRefs.current[index] = el;
                        }
                    }}
                    numFields={fields.length ?? 1}
                    removeField={() => {
                        if (fields?.length === 1) {
                            fields.update(index, blankField);
                        } else {
                            fields.remove(index);
                        }
                    }}
                    generateExamples={async () => {
                        setFieldValue(`entities[${index}].generatingExamples`, true);
                        try {
                            const exampleResponse = await generateEntityExamples(fields.value[index]);
                            setFieldValue(`entities[${index}].generatedExamples`, exampleResponse.data);
                        } catch {
                            addToast({ title: 'Error retrieving examples', variant: 'destructive' });
                        } finally {
                            setFieldValue(`entities[${index}].generatingExamples`, false);
                        }
                    }}
                    generatingExamples={fields.value[index].generatingExamples}
                    generatedExamples={fields.value[index].generatedExamples}
                    fieldName={fieldName}
                    key={fieldName}
                />
            ))}
            <QuiBox display="flex" justifyContent="center">
                <QuiButton
                    type="button"
                    iconLeft="plus"
                    onClick={() => {
                        fields.push(blankField);
                        focusLastInputRef.current = true;
                    }}
                >
                    Add Another Entity
                </QuiButton>
            </QuiBox>
        </QuiBox>
    );
}

import { useCallback, useState } from 'react';
import {
    QuiBox,
    QuiForm,
    QuiModalContent,
    QuiModalDialog,
    QuiSpinner,
    QuiSubmitButton,
    QuiButton,
    QuiTextField,
    useQuiToasts,
} from '@tonicai/ui-quinine';
import { useDialog } from '../../hooks/useDialog';
import { usePdfFileData } from '../../hooks/usePdfFileData';
import { createPdfManualRedactionTemplate, deletePdfRedactionTemplate, updatePdfManualRedactionTemplate } from '../../stores/pdfRedactionTemplates';
import { DeleteDialog } from '../DeleteDialog/DeleteDialog';
import { PdfFilePagesPreview } from '../PdfPage/PdfFilePagesPreview';
import { PdfPageField } from '../PdfPage/PdfPageField';
import { PdfRedactionsControls } from '../PdfPage/PdfRedactionsControls';
import { TemplateFileSelect } from './TemplateFileSelect';
import { useFileBlob } from '../../hooks/useFileBlob';
import { useTemplate } from '../../hooks/useTemplate';
import { ManualPdfRedactionType, PdfRedaction, PdfRedactionSource, PdfRedactionType } from '../../types';
import { Mutator } from 'final-form';
import mutators from 'final-form-arrays';
import { NewAreaTypeSegmentedControl } from './NewAreaTypeSegmentedControl';
import { useBodyOverflowHidden } from '../../hooks/useBodyOverflowHidden';
import styles from './TemplateDialog.module.scss';
import { requiredString } from '../../validation';
import { useScale } from './useScale';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';

const formMutators: Record<string, Mutator<TemplateFormState>> = {
    ...mutators,
};

type TemplateFormState = {
    name: string;
    redactions: PdfRedaction[];
};

type TemplateDialogProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    datasetId: string;
    templateId: string | undefined;
    setTemplateId: (templateId: string | undefined) => void;
}>;

function TemplateDialogContent({ datasetId, templateId, setTemplateId, onClose }: Readonly<Omit<TemplateDialogProps, 'isOpen'>>) {
    const [fileId, setFileId] = useState<string | undefined>(undefined);
    const { pdfBlob, fetchStatus } = useFileBlob(datasetId, fileId);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pdfRedactionType, setPdfRedactionType] = useState<ManualPdfRedactionType>(PdfRedactionType.RemoveRedaction);
    const [editingRedactionIndex, setEditingRedactionIndex] = useState<number | undefined>(undefined);
    const { template } = useTemplate(datasetId, templateId);
    const addToast = useQuiToasts();
    const scale = useScale();
    const deleteTemplateDialog = useDialog();

    const { automaticJobRedactions, pdfWords } = usePdfFileData(datasetId, fileId);

    const onSubmit = useCallback(
        (values: TemplateFormState) => {
            if (!datasetId) return;

            // Update existing template
            if (templateId) {
                return updatePdfManualRedactionTemplate(datasetId, templateId, values.name, values.redactions)
                    .catch(() => {
                        addToast({
                            title: 'Could not update template',
                            variant: 'destructive',
                        });
                    })
                    .then(() => {
                        return undefined;
                    });
            }

            // Create new template
            return createPdfManualRedactionTemplate(datasetId, values.name, values.redactions)
                .then(({ data }) => {
                    setTemplateId(data.id);
                    return undefined;
                })
                .catch(() => {
                    addToast({
                        title: 'Could not create template',
                        variant: 'destructive',
                    });
                });
        },
        [templateId, datasetId, addToast, setTemplateId]
    );

    return (
        <QuiForm<TemplateFormState> className={styles.templateDialogForm} initialValues={template} mutators={formMutators} onSubmit={onSubmit}>
            <div className={styles.layout}>
                <div className={styles.leftColumn}>
                    <QuiBox display="flex" flexDirection="column" gap="lg">
                        <QuiBox weight="bold" text="display-xs">
                            {templateId ? 'Edit Template' : 'Create A New Template'}
                        </QuiBox>
                        {templateId && (
                            <QuiBox>
                                <QuiButton iconLeft={'trash'} onClick={deleteTemplateDialog.openDialog}>
                                    {'Delete'}
                                </QuiButton>
                                <DeleteDialog
                                    title="Delete Template Confirmation"
                                    isOpen={deleteTemplateDialog.isOpen}
                                    closeDialog={deleteTemplateDialog.closeDialog}
                                    deleteMethod={() => {
                                        return deletePdfRedactionTemplate(datasetId, templateId).then(() => {
                                            deleteTemplateDialog.closeDialog();
                                            onClose();
                                        });
                                    }}
                                    itemType={'template'}
                                    itemName={template?.name ?? ''}
                                />
                            </QuiBox>
                        )}
                        <QuiTextField validate={requiredString} name="name" label="Name" />
                        <PdfRedactionsControls
                            editingRedactionIndex={editingRedactionIndex}
                            setEditingRedactionIndex={setEditingRedactionIndex}
                            fieldName={'redactions'}
                            pageIndex={pageIndex}
                        />
                    </QuiBox>
                </div>
                <div className={styles.mainColumn}>
                    <div className={styles.mainColumnHeader}>
                        <div>
                            <QuiBox display="inline-flex" gap="md">
                                <NewAreaTypeSegmentedControl disabled={!fileId} onSelect={setPdfRedactionType} pdfRedactionType={pdfRedactionType} />
                            </QuiBox>
                        </div>
                        <div>
                            <QuiBox display="inline-flex">
                                <TemplateFileSelect value={fileId} onChange={setFileId} datasetId={datasetId} />
                            </QuiBox>
                        </div>
                        <div>
                            {templateId ? <QuiSubmitButton>Update Template</QuiSubmitButton> : <QuiSubmitButton>Save New Template</QuiSubmitButton>}
                        </div>
                    </div>
                    <div className={styles.mainColumnContent}>
                        <div className={styles.pagePreview}>
                            {!fileId && (
                                <QuiBox justifyContent="center" display="flex" alignItems="center" padding="md">
                                    <QuiBox maxWidth="tablet">Select A File to begin creating your template</QuiBox>
                                </QuiBox>
                            )}

                            {fileId && fetchStatus === 'loading' && (
                                <QuiBox justifyContent="center" display="flex" alignItems="center" padding="md">
                                    <QuiSpinner />
                                </QuiBox>
                            )}

                            <PdfPageField
                                pdfScale={scale.scale}
                                pdfRedactionSource={PdfRedactionSource.AttachedToTemplate}
                                pageIndex={pageIndex}
                                pdfRedactionType={pdfRedactionType}
                                pdfBlob={pdfBlob}
                                existingRedactions={automaticJobRedactions}
                                pdfWords={pdfWords}
                                fieldName="redactions"
                                selectedFormRedactionIndex={editingRedactionIndex}
                            />
                        </div>
                        {fileId && <ZoomButtons className={styles.zoomButtons} {...scale} />}
                    </div>
                </div>
                <div className={styles.rightColumn}>
                    {pdfBlob && <PdfFilePagesPreview pageIndex={pageIndex} setPageIndex={setPageIndex} pdfBlob={pdfBlob} />}
                </div>
            </div>
        </QuiForm>
    );
}

export function TemplateDialog({ isOpen, ...props }: TemplateDialogProps) {
    // Make the body not-scrollable when the modal is open
    useBodyOverflowHidden(isOpen);

    return (
        <QuiModalDialog closeButtonPosition="outside" className={styles.templateDialog} isOpen={isOpen} onClose={props.onClose}>
            <QuiModalContent className={styles.templateDialogContent}>{isOpen && <TemplateDialogContent {...props} />}</QuiModalContent>
        </QuiModalDialog>
    );
}

import { QuiBox, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { Dataset } from '../../types';
import { UploadFiles } from '../../components/UploadFiles/UploadFiles';
import { uploadFiles } from '../../stores/datasets';
import styles from './UploadFilesToDatasetModal.module.scss';
import { useNavigate } from 'react-router-dom';

type UploadFilesToDatasetModalProps = Readonly<{
    dataset: Dataset;
    isOpen: boolean;
    onClose: () => void;
}>;

export function UploadFilesToDatasetModal({ dataset, isOpen, onClose }: UploadFilesToDatasetModalProps) {
    const navigate = useNavigate();
    return (
        <QuiModalDialog title={dataset.name} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent className={styles.modal}>
                <QuiBox display="flex" flexDirection="column" gap="md" padding="md">
                    <QuiBox text="text-md" weight="medium">
                        Upload files to dataset
                    </QuiBox>
                    <QuiBox text="text-sm" color="black-600">
                        To create a new dataset, upload unstructured text files. You can also use our sample dataset to get started.
                    </QuiBox>
                    <UploadFiles
                        onFilesSelected={async (files) => {
                            await uploadFiles(files, dataset.id).then(() => {
                                navigate(`/datasets/${dataset.id}`);
                            });
                        }}
                    />
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}

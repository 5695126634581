const hubspotEnabled =
    window.location.hostname === 'solar.tonic.ai' ||
    window.location.hostname === 'textual.tonic.ai' ||
    sessionStorage.getItem('solar.hosted-tracking-test') !== null;

const _hsq = (window._hsq = window._hsq ?? []);
const scriptId = 'hs-script-loader';

let hubspotInitialized = false;

let identifiedUser: string | false = false;

function trackPageView(path: string) {
    if (!hubspotEnabled) return;

    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
}

function identifyUser(email: string, name?: string) {
    if (!hubspotEnabled || !hubspotInitialized || !identifiedUser !== false) return;

    const data: { email: string; name?: string } = { email };
    if (name) {
        data.name = name;
    }

    identifiedUser = email;

    _hsq.push(['identify', data]);
}

function init(initialPath: string) {
    if (!hubspotEnabled) return;

    trackPageView(initialPath);

    // Add Hubspot tracking script on hosted only
    if (
        hubspotInitialized === false &&
        document.getElementById(scriptId) === null // redundant but just in case
    ) {
        const hubspotScriptTag = document.createElement('script');
        hubspotScriptTag.id = scriptId;
        hubspotScriptTag.async = true;
        hubspotScriptTag.defer = true;
        hubspotScriptTag.src = '//js.hs-scripts.com/4809962.js';
        document.body.appendChild(hubspotScriptTag);
        hubspotInitialized = true;
    }
}

export const hubspot = {
    init,
    trackPageView,
    identifyUser,
};

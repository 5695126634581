import { QuiModalDialog, QuiForm, QuiModalContent, QuiBox } from '@tonicai/ui-quinine';
import { createDataset } from '../../stores/datasets';
import { FileSource } from '../../types';
import { FORM_ERROR } from 'final-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CreateDataseFormContent } from './CreateDataseFormContent';

export type CreateDatasetModalProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
}>;

type CreateDatasetFormValues = Readonly<{
    name: string;
    fileSource: FileSource;
}>;

export function CreateDatasetModal({ isOpen, onClose }: CreateDatasetModalProps) {
    const navigate = useNavigate();

    return (
        <QuiModalDialog title="Create a new dataset" className={'modal'} disableDismissOnEscapeKeyDown={false} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiBox display="flex" gap="md" padding="lg" flexDirection="column">
                    <QuiBox>A dataset is a set of files to redact.</QuiBox>
                    <QuiForm<CreateDatasetFormValues>
                        initialValues={{ name: '', fileSource: 'Local' }}
                        onSubmit={({ name, fileSource }: CreateDatasetFormValues) => {
                            return createDataset(name, fileSource)
                                .then((id) => navigate(`/datasets/${id}`))
                                .catch((e) => {
                                    if (axios.isAxiosError(e) && e.response?.status === 409) {
                                        return {
                                            [FORM_ERROR]: "There's already a dataset with that name. Please choose another name.",
                                        };
                                    }

                                    return {
                                        [FORM_ERROR]: 'Something unexpected went wrong. Please try again.',
                                    };
                                });
                        }}
                    >
                        <CreateDataseFormContent />
                    </QuiForm>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}

import { Dataset, PiiTextExample, PiiTypeEnum, PiiTypeInfo } from '../../types';
import { QuiIconEnum } from '@tonicai/ui-quinine';

export function getPiiTypeCount(dataset: Dataset, key: string) {
    return dataset.files
        .map((f) => f.piiTypeCounts[key])
        .filter((n) => n)
        .reduce((a, b) => a + b, 0);
}

//should match _PiiTypeExampleLimit in DeidentifyFileApplication.cs
const PII_TYPE_EXAMPLE_LIMIT = 10;

export function getPiiTypeExamples(dataset: Dataset, key: string): PiiTextExample[] {
    return dataset.files
        .map((f) => f.piiTypeExamples[key])
        .filter((n) => n)
        .reduce((acc, examples) => {
            const remainingExamples = PII_TYPE_EXAMPLE_LIMIT - acc.length;

            if (remainingExamples > 0) {
                return acc.concat(examples.slice(0, remainingExamples));
            } else {
                return acc;
            }
        }, []);
}
export function getTotalRedactionsWithUnits(dataset: Dataset) {
    const total = dataset.files
        .map((f) => f.redactedWordCount)
        .filter((n) => n)
        .reduce((a, b) => a + b, 0);
    if (total <= 9999) return total;
    if (total >= 10000 && total <= 999999) return (total / 1000).toFixed(1) + 'K';
    return (total / 1000000).toFixed(1) + 'M';
}

export function getPercentRedacted(dataset: Dataset) {
    const totalWords = dataset.files
        .map((f) => f.wordCount ?? 0)
        .filter((n) => n != null)
        .reduce((a, b) => {
            const aNum = typeof a !== 'number' ? 0 : a;
            const bNum = typeof b !== 'number' ? 0 : b;
            return aNum + bNum;
        }, 0);
    const redactedWords = dataset.files
        .map((f) => f.redactedWordCount)
        .filter((n) => n)
        .reduce((a, b) => a + b, 0);
    if (totalWords === 0) return 0;
    const p = (100 * redactedWords) / totalWords;
    return p.toFixed(0);
}

export const PiiTypeToLabel: Record<string, PiiTypeInfo> = {
    [PiiTypeEnum.NUMERIC_VALUE]: { label: 'Numeric Value', icon: QuiIconEnum.Hash, description: 'A numeric value' },
    [PiiTypeEnum.LANGUAGE]: { label: 'Language', icon: QuiIconEnum.Speaker, description: 'A spoken language' },
    [PiiTypeEnum.MONEY]: { label: 'Money', icon: QuiIconEnum.DollarSign, description: 'A number representing a currency amount' },
    [PiiTypeEnum.PRODUCT]: { label: 'Product', icon: QuiIconEnum.Gift, description: 'A manufactured product' },
    [PiiTypeEnum.EVENT]: { label: 'Event', icon: QuiIconEnum.Aperture, description: 'A historical event' },
    [PiiTypeEnum.WORK_OF_ART]: { label: 'Work of Art', icon: QuiIconEnum.PenTool, description: 'A work of art' },
    [PiiTypeEnum.LAW]: { label: 'Law', icon: QuiIconEnum.Shield, description: 'A governmental law' },
    [PiiTypeEnum.US_PASSPORT]: { label: 'US Passport', icon: QuiIconEnum.Book, description: 'US Passport number' },
    [PiiTypeEnum.MEDICAL_LICENSE]: { label: 'US Medical License', icon: QuiIconEnum.Heart, description: 'US Medical License number' },
    [PiiTypeEnum.DATE_TIME]: { label: 'Date/Time', icon: QuiIconEnum.Calendar, description: 'Absolute or relative dates or periods' },
    [PiiTypeEnum.US_BANK_NUMBER]: { label: 'US Bank Number', icon: QuiIconEnum.DollarSign, description: 'Bank account number' },
    [PiiTypeEnum.NRP]: { label: 'NRP', icon: QuiIconEnum.BookOpen, description: 'Nationalities or religious or political groups' },
    [PiiTypeEnum.US_SSN]: { label: 'Social Security Number', icon: QuiIconEnum.Info, description: 'Social Security Number' },
    [PiiTypeEnum.IP_ADDRESS]: { label: 'IP Address', icon: QuiIconEnum.HardDrive, description: 'IP Address' },
    [PiiTypeEnum.ORGANIZATION]: { label: 'Organization', icon: QuiIconEnum.Users, description: 'Organization and company names' },
    [PiiTypeEnum.PHONE_NUMBER]: { label: 'Phone Number', icon: QuiIconEnum.Phone, description: 'Phone number' },
    [PiiTypeEnum.US_ITIN]: { label: 'Individual Tax Identification Number', icon: QuiIconEnum.Info, description: 'US Tax ID number' },
    [PiiTypeEnum.LOCATION]: { label: 'Location', icon: QuiIconEnum.Map, description: 'Locations, addresses, etc.' },
    [PiiTypeEnum.LOCATION_ADDRESS]: { label: 'Location Address', icon: QuiIconEnum.Map, description: 'A street address or street name' },
    [PiiTypeEnum.LOCATION_CITY]: { label: 'Location City', icon: QuiIconEnum.Map, description: 'A city name' },
    [PiiTypeEnum.LOCATION_STATE]: { label: 'Location State', icon: QuiIconEnum.Map, description: 'A state name' },
    [PiiTypeEnum.LOCATION_ZIP]: { label: 'Location Zip', icon: QuiIconEnum.Map, description: 'A zip code' },
    [PiiTypeEnum.CREDIT_CARD]: { label: 'Credit Card', icon: QuiIconEnum.CreditCard, description: 'Credit card numbers' },
    [PiiTypeEnum.US_DRIVER_LICENSE]: { label: 'US Driver License Number', icon: QuiIconEnum.Info, description: "Driver's license numbers" },
    [PiiTypeEnum.EMAIL_ADDRESS]: { label: 'Email Address', icon: QuiIconEnum.AtSign, description: 'Email address' },
    [PiiTypeEnum.IBAN_CODE]: { label: 'IBAN', icon: QuiIconEnum.DollarSign, description: 'IBAN account number' },
    [PiiTypeEnum.URL]: { label: 'Url', icon: QuiIconEnum.Globe, description: 'Website URLs' },
    [PiiTypeEnum.PERSON]: { label: 'Person', icon: QuiIconEnum.User, description: "People's names" },
    [PiiTypeEnum.NAME_GIVEN]: { label: 'Given Name', icon: QuiIconEnum.User, description: "A person's given name" },
    [PiiTypeEnum.NAME_FAMILY]: { label: 'Family Name', icon: QuiIconEnum.User, description: "A person's family name" },
    [PiiTypeEnum.USERNAME]: { label: 'Username', icon: QuiIconEnum.User, description: 'User account name' },
    [PiiTypeEnum.PASSWORD]: { label: 'Password', icon: QuiIconEnum.Lock, description: 'User passwords' },
    [PiiTypeEnum.PROJECT_NAME]: {
        label: 'Project Name',
        icon: QuiIconEnum.Briefcase,
        description: 'Corporate or municipal projects, initiatives, etc.',
    },
    [PiiTypeEnum.PERSON_AGE]: { label: 'Age', icon: QuiIconEnum.Clock, description: 'A persons age, expressed as a number or word' },
    [PiiTypeEnum.DOB]: {
        label: 'Date of Birth',
        icon: QuiIconEnum.Gift,
        description: "A person's date of birth, possibly including the year",
    },
    [PiiTypeEnum.CC_EXP]: {
        label: 'Credit Card Expiration',
        icon: QuiIconEnum.CreditCard,
        description: 'The expiration date of a credit card, in various formats',
    },
    [PiiTypeEnum.CVV]: { label: 'Credit Card CVV', icon: QuiIconEnum.CreditCard, description: 'Credit card verification code' },
};

export const getPiiTypeInfo = (label: string, modelsPiiTypeInfo: Record<string, PiiTypeInfo>) => {
    if (Object.values(PiiTypeEnum).includes(label as PiiTypeEnum)) {
        return PiiTypeToLabel[label];
    }

    return modelsPiiTypeInfo[label] as PiiTypeInfo | undefined;
};

import { useFieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import { QuiBox, QuiButton, QuiTextField, QuiTextInput, QuiLink, QuiIcon } from '@tonicai/ui-quinine';
import { useState } from 'react';

type AdditionalDateFormatFieldProps = Readonly<{
    name: string;
}>;

const NODATIME_DOCUMENTATION_URL = 'https://nodatime.org/1.4.x/userguide/localdatetime-patterns';

export function AdditionalDateFormatField({ name }: AdditionalDateFormatFieldProps) {
    const { fields } = useFieldArray<string>(name);
    const form = useForm();

    const [newValue, setNewValue] = useState<string>('');

    return (
        <QuiBox borderRadius="md" border="white-600" padding={'md'} gap={'md'} display={'flex'} flexDirection={'column'}>
            <QuiBox display={'flex'} justifyContent={'space-between'}>
                <QuiBox>
                    <QuiBox className={'qui-semibold qui-text-sm'}>{'Additional Date Formats'}</QuiBox>
                    <QuiBox className={'qui-text-xs qui-color-black-600'} display={'flex'} gap={'sm'} alignItems={'center'}>
                        {'Add custom NodaTime date formats.'}
                        <QuiLink variant="currentcolor" external to={NODATIME_DOCUMENTATION_URL}>
                            <QuiIcon icon={'external-link'} />
                        </QuiLink>
                    </QuiBox>
                </QuiBox>
            </QuiBox>
            <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                {fields.map((fieldName, index) => (
                    <QuiBox display={'flex'} gap={'sm'} key={fieldName}>
                        <QuiTextField name={fieldName} onBlur={() => form.submit()} style={{ width: '100%' }} placeholder={'Add date format...'} />
                        <QuiButton
                            type={'button'}
                            onClick={() => {
                                fields.remove(index);
                                form.submit();
                            }}
                            iconLeft={'trash'}
                        />
                    </QuiBox>
                ))}
                <QuiBox display={'flex'} gap={'sm'}>
                    <QuiTextInput
                        style={{ width: '100%' }}
                        value={newValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)}
                        placeholder={'Add date format...'}
                    />
                    <QuiButton
                        type={'button'}
                        onClick={() => {
                            fields.push(newValue);
                            form.submit();
                            setNewValue('');
                        }}
                        iconLeft={'plus'}
                        disabled={newValue === ''}
                    />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}

import { useField } from 'react-final-form';
import { TemplateSelect } from '../Templates/TemplateSelect';

type FileTemplateFieldProps = {
    name: string;
    datasetId: string;
    setFileTemplateId: (templateId: string | undefined) => void;
};

export function FileTemplateField({ name, datasetId, setFileTemplateId }: FileTemplateFieldProps) {
    const { input } = useField<string>(name);

    return (
        <div>
            <TemplateSelect
                value={input.value}
                onChange={(value) => {
                    setFileTemplateId(value);
                    input.onChange(value);
                }}
                datasetId={datasetId}
            />
        </div>
    );
}

import { PrismLight as PrismSyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from './SyntaxHighlighter.module.scss';

// Import the languages you want to use
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import sql from 'react-syntax-highlighter/dist/esm/languages/prism/sql';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import { SupportedLanguage } from './types';

// Register the languages you want to use.
// When adding new languages, make sure to update the `SupportedLanguage`
// type definition `types.ts` file in this directory to include it. We don't
// define the type definition here because this file is lazily loaded.
PrismSyntaxHighlighter.registerLanguage('javascript', javascript);
PrismSyntaxHighlighter.registerLanguage('json', json);
PrismSyntaxHighlighter.registerLanguage('sql', sql);
PrismSyntaxHighlighter.registerLanguage('python', python);
PrismSyntaxHighlighter.registerLanguage('bash', bash);

type SyntaxHighlighterProps = {
    code: string;
    language: SupportedLanguage;
};

export function SyntaxHighlighter({ code, language }: SyntaxHighlighterProps) {
    return (
        <div className={styles.wrapper}>
            <PrismSyntaxHighlighter style={prism} language={language}>
                {code}
            </PrismSyntaxHighlighter>
        </div>
    );
}

import { useEffect, useState } from 'react';
import { fetchUserApiKeys, revokeApiKey, useUserAPIKeys } from '../../stores/userAPIKeys';
import { QuiBox, QuiButton, QuiPopover, QuiSpinner, QuiText } from '@tonicai/ui-quinine';
import { useDialog } from '../../hooks/useDialog';
import { ApiKeyModal } from '../../components/ApiKeyModal/ApiKeyModal';
import { formatDateTime } from '../../utils';
import { useIsMounted } from '../../hooks/useIsMounted';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { instrumentation } from '../../instrumentation/instrumentation';

export function UserAPIKeys() {
    const [editingId, setEditingId] = useState<string | null>(null);
    const isMounted = useIsMounted();

    const createAPIKeyDialog = useDialog();
    const revokeAPIKeyDialog = useDialog();

    const { fetchStatus, userAPIKeys } = useUserAPIKeys();

    useEffect(() => {
        fetchUserApiKeys();
    }, []);

    return (
        <PageContainer>
            <QuiBox gap="lg" flexDirection="column" display="flex">
                <QuiBox display="flex" justifyContent="space-between" gap="sm">
                    <QuiBox display="flex" alignItems="center" justifyContent="space-between" gap="sm">
                        <QuiText size="display-xs" weight="bold">
                            User API Keys
                        </QuiText>
                        {fetchStatus === 'refreshing' || fetchStatus === 'loading' ? <QuiSpinner size="sm" /> : null}
                    </QuiBox>

                    <QuiButton onClick={createAPIKeyDialog.openDialog}>Create API Key</QuiButton>
                </QuiBox>

                <ApiKeyModal isOpen={createAPIKeyDialog.isOpen} onClose={createAPIKeyDialog.closeDialog} />

                <QuiBox display="flex" flexDirection="column" gap="sm">
                    {userAPIKeys.map((key) => (
                        <QuiBox display="flex" border="white-600" borderRadius="md" padding="sm" bg="white" gap="md" key={key.id}>
                            <QuiBox flexGrow="1" flexDirection="column" display="flex">
                                <QuiBox text="text-sm" weight="bold">
                                    {key.name}
                                </QuiBox>
                                <QuiBox text="mono-text-xs" color="black-600">
                                    {key.key}
                                </QuiBox>
                                <QuiBox text="text-xs" color="black-600">
                                    {`Created At: ${key.createdDate ? formatDateTime(new Date(key.createdDate)) : '--'}`}
                                </QuiBox>
                                <QuiBox text="text-xs" color="black-600">
                                    {`Last Used: ${key.lastUsed ? formatDateTime(new Date(key.lastUsed)) : '--'}`}
                                </QuiBox>
                            </QuiBox>

                            <QuiPopover
                                content={
                                    <QuiBox display="flex" flexDirection="column" gap="sm" padding="md">
                                        <QuiText size="text-sm">Are you sure you want to revoke this key?</QuiText>
                                        <QuiBox display="flex" gap="sm" justifyContent="end">
                                            <QuiButton
                                                size="sm"
                                                onClick={() => {
                                                    setEditingId(null);
                                                    revokeAPIKeyDialog.closeDialog();
                                                }}
                                            >
                                                Cancel
                                            </QuiButton>
                                            <QuiButton
                                                size="sm"
                                                variant="destructive"
                                                onClick={() => {
                                                    revokeApiKey(key.id).then(() => {
                                                        instrumentation.revokeAPIKey(key.id);

                                                        if (isMounted()) {
                                                            setEditingId(null);
                                                            revokeAPIKeyDialog.closeDialog();
                                                        }
                                                    });
                                                }}
                                            >
                                                Revoke
                                            </QuiButton>
                                        </QuiBox>
                                    </QuiBox>
                                }
                                isOpen={revokeAPIKeyDialog.isOpen && editingId === key.id}
                                onClose={() => {
                                    revokeAPIKeyDialog.setIsOpen(false);
                                }}
                            >
                                <QuiButton
                                    onClick={() => {
                                        setEditingId(key.id);
                                        revokeAPIKeyDialog.openDialog();
                                    }}
                                    size="sm"
                                    variant="destructive"
                                >
                                    Revoke
                                </QuiButton>
                            </QuiPopover>
                        </QuiBox>
                    ))}
                </QuiBox>
            </QuiBox>
        </PageContainer>
    );
}

import { QuiBox, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { UserAPIKeyForm } from './UserAPIKeyForm';
import styles from './ApiKeyModal.module.scss';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export function ApiKeyModal({ isOpen, onClose }: IProps) {
    return (
        <QuiModalDialog
            className={styles.modal}
            icon="key"
            title="Create a new user API key"
            disableDismissOnEscapeKeyDown={false}
            isOpen={isOpen}
            onClose={onClose}
        >
            <QuiModalContent className={styles.modalContent}>
                <QuiBox display="flex" flexDirection="column" gap="md">
                    <QuiBox>{`Let's first get the name of the user who will be using this API key. You will be able to manage the key later.`}</QuiBox>

                    <UserAPIKeyForm />
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}

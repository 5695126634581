import { QuiBox, QuiButton, QuiIcon, QuiLink, QuiModalContent, QuiModalDialog, QuiModalHeader } from '@tonicai/ui-quinine';
import styles from './GettingStartedModal.module.scss';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';

type GettingStartedModalProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
    openApiKeyModal: () => void;
    openUploadModal: () => void;
}>;

export function GettingStartedModal({ isOpen, onClose, openApiKeyModal, openUploadModal }: GettingStartedModalProps) {
    return (
        <QuiModalDialog className={styles.modal} isOpen={isOpen} onClose={onClose}>
            <QuiModalHeader className={styles.header}>
                <QuiIcon className={styles.flagIcon} size="2xl" icon="flag" />
            </QuiModalHeader>
            <QuiModalContent className={styles.content}>
                <QuiBox display="flex" flexDirection="column" gap="lg">
                    <div>
                        <QuiBox text="text-md" weight="medium">
                            Getting Started
                        </QuiBox>
                        <QuiBox color="black-600" text="text-xs">
                            Textual detects sensitive values such as names and locations. It then redacts those values, replacing them with generic
                            values.
                        </QuiBox>
                    </div>
                    <QuiLink variant="currentcolor" external iconRight="external-link" to={TEXTUAL_DOCUMENTATION_URL}>
                        View Documentation
                    </QuiLink>
                    <QuiBox display="flex" gap="md">
                        <QuiButton
                            variant="brand-purple"
                            type="button"
                            onClick={() => {
                                openApiKeyModal();
                                onClose();
                            }}
                        >
                            Create API Key
                        </QuiButton>
                        <QuiButton
                            type="button"
                            onClick={() => {
                                openUploadModal();
                                onClose();
                            }}
                        >
                            Upload Files
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            </QuiModalContent>
        </QuiModalDialog>
    );
}

import { fetchModels } from '../../stores/models';
import { QuiBadge, QuiBox, QuiButton, QuiText, QuiTextInput } from '@tonicai/ui-quinine';
import { fetchDatasets, useDatasets } from '../../stores/datasets';
import { useEffect, useMemo, useState } from 'react';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import { CreateDatasetModal } from '../Home/CreateDatasetModal';
import styles from './Datasets.module.scss';
import { DatasetCard } from '../../components/DatasetCard/DatasetCard';

export function Datasets() {
    const datasets = useDatasets();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const filteredDatasets = useMemo(() => {
        if (searchQuery.trim().length === 0) return datasets;
        return datasets.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase().trim()));
    }, [datasets, searchQuery]);

    useEffect(() => {
        fetchDatasets();
        fetchModels();
    }, []);

    return (
        <PageContainer>
            <div className={styles.layout}>
                <div className={styles.sidebar}>
                    <QuiBox flexDirection="column" display="flex" gap="md">
                        <QuiBox display="flex" flexDirection="column" gap="md">
                            <QuiBadge variant="purple" iconLeft="database" />
                            <QuiText size="display-sm" weight="bold">
                                Datasets
                            </QuiText>
                            <QuiBox color="black-600" text="text-md">
                                Textual detects sensitive values such as names and locations. It then redacts those values, replacing them with
                                generic values.
                            </QuiBox>
                        </QuiBox>
                        <QuiBox>
                            <QuiButton
                                variant="brand-purple"
                                onClick={() => {
                                    setIsCreateDialogOpen(true);
                                }}
                            >
                                Create a Dataset
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                </div>
                <div className={styles.main}>
                    <QuiBox>
                        <QuiTextInput
                            placeholder="Search Datasets..."
                            value={searchQuery}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }}
                            iconLeft="search"
                        />
                        <QuiBox padding={'xs'} text="text-xs" color="black-600">
                            {searchQuery.trim().length > 0 ? (
                                `Found ${filteredDatasets.length} matching datasets`
                            ) : (
                                <>{filteredDatasets.length} datasets</>
                            )}
                        </QuiBox>
                    </QuiBox>

                    <div className={styles.datasets}>
                        {filteredDatasets.map((dataset) => {
                            return <DatasetCard key={dataset.id} dataset={dataset} />;
                        })}
                    </div>
                </div>
            </div>
            <CreateDatasetModal
                isOpen={isCreateDialogOpen}
                onClose={() => {
                    setIsCreateDialogOpen(false);
                }}
            />
        </PageContainer>
    );
}
